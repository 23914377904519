import {
    GET_TOPIC,
    CREATE_NEW_TOPIC,
    EDIT_TOPIC,
    DELETE_TOPIC
  } from "./topic.type";
  
  const initialState = {
    topic: [],
    dialog: false,
    dialogData: null,
    totalTopic: 0,
  };
  
  const topicReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TOPIC:
      
        return {
          ...state,
          topic: action.payload.data,
          totalTopic:action.payload.totalTopic
        };
      case CREATE_NEW_TOPIC:
        
        const data = [...state.topic];
        
        data.unshift(...action.payload);
        
        return {
          ...state,
          topic: data,
          totalTopic: data?.length
        };
      case EDIT_TOPIC:
        
        return {
          ...state,
          topic: state.topic.map((topic) => {
            if (topic._id === action.payload.id) return action.payload.data;
            else return topic;
          }),
        };
        case DELETE_TOPIC:
          return {
            ...state,
            topic: state.topic.map((topic) => {
              if (topic._id === action.payload.id) return action.payload.data;
              else return topic;
            }),
          };
      default:
        return state;
    }
  };
  
  export default topicReducer;
  