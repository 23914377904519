import { useEffect,useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PROFILE from "../../assets/images/male.jpg";
import { getProfile } from "../store/admin/admin.action";
import { baseURL } from "../util/config";
import { OPEN_DIALOGUE, OPEN_NOTIFICATION_DIALOGUE } from "../store/dialogue/dialogue.type";
import Notification from "./Notification";

const Navbar = (props) => {
  const { admin } = useSelector((state) => state.admin);
  const { dialogueNotification,dialogueNotificationType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch]);

  const handleNotification = (type) => {
    dispatch({
      type: OPEN_NOTIFICATION_DIALOGUE,
      payload:{
        type:type
      }
    });
  };
  return (
    <>
      <div className="mainNavbar webNav me-4">
        <div className="navBox py-3">
          <div
            className="navBar boxBetween px-4 "
            style={{ padding: "13px 0px" }}
          >
            <div className="navToggle">
              <i class="fa-solid fa-bars text-white cursor"></i>
            </div>
            <div className="navIcons d-flex justify-content-end">
              <div
                className="pe-4 cursor"
                style={{ backgroundColor: "inherit" }}
                onClick={() => handleNotification("Notification")}
              >
                <i className="fa-solid fa-bell text-white cursor"></i>
              </div>
              <div className="pe-4" style={{ backgroundColor: "inherit" }}>
                <Link to="/admin/settingPage">
                  <i className="fa-solid fa-gear text-white cursor"></i>
                </Link>
              </div>
              <div  className="cursor">
                <Link
                  to="/admin/infoAdmin"
                  style={{ backgroundColor: "inherit" }}
                >
                  <img
                    src={admin.image ? baseURL + admin.image : PROFILE}
                    alt=""
                    width={`30px`}
                    height={`30px`}
                    style={{ borderRadius: "50%", border: "1px solid white" }}
                    className="cursor"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {dialogueNotification && dialogueNotificationType === "Notification" && <Notification />}
      </div>
    </>
  );
};

export default connect(null, { getProfile })(Navbar);
