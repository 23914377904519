import { Link } from "react-router-dom";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import {
  getFlashCoin,
  deleteFlashCoin,
  isActiveFlashCion,
} from "../../store/flashCoin/FlashCoin.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import FlashDialog from "./FlashDialog";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Pagination from "../../extra/Pagination";

const FlashCoin = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getFlashCoin());
  }, [dispatch]);

  const { flashCoin } = useSelector((state) => state.flashCoin);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    setData(flashCoin);
  }, [flashCoin]);

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteFlashCoin(id);
        }
      })
      .catch((err) => console.log(err));
  };
  // Active Plan
  const handleClick = (id) => {
    props.isActiveFlashCion(id);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Coin Plan Table
  const planTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <div className="position-relative">
          <img
            src={row?.image}
            style={{ borderRadius: "25px" }}
            height={50}
            width={50}
            alt=""
          />
          {row.isOnline && (
            <span
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: "green",
                bottom: "-4px",
                right: "3px",
                border: "3px solid #ffff",
              }}
              className="d-block position-absolute"
            ></span>
          )}
        </div>
      ),
    },
    { Header: "Coin", body: "coin", sorting: { type: "client" } },
    { Header: "Discount", body: "discount", sorting: { type: "client" } },
    { Header: "Rupee", body: "rupee", sorting: { type: "client" } },
    // {
    //   Header: "Platform Type",
    //   body: "platFormType",
    //   Cell: ({ row }) => (
    //     <span>{row.original.platFormType == 0 ? "Android" : "Ios"}</span>
    //   ),
    // },
    { Header: "With Discount(₹)", body: "rupeeWithDiscount" },
    { Header: "Dollar($)", body: "dollar" },
    { Header: "Product Key", body: "productKey" },
    { Header: "Tag", body: "tag" },
    // { Header: "isActive", body: "isActive" },
    // {
    //   Header: "Created Date",
    //   body: "createdAt",
    //   Cell: ({ row }) => (
    //     <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
    //   ),
    // },
    {
      Header: "Active",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isActive}
          onClick={() => handleClick(row?._id)}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-primary fs-5`}
          btnColor={``}
          btnIcon={`fa-solid fa-pen-to-square`}
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "plan" },
            })
          }
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },

    // add more columns as needed
  ];
  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="planHeader primeHeader">
            <div className="row align-items-center">
              <div className="col-12">
                <Title name={`Flash Coin`} />
              </div>
              <div className="col-6"></div>
              <div className="col-6 ms-auto mt-3">
                <Button
                  newClass={`border text-white userBtn`}
                  btnColor={`bg-primary`}
                  btnName={`Add Flash Coin`}
                  btnIcon={`fas fa-plus fa-md`}
                  onClick={() => {
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: { type: "plan" },
                    });
                  }}
                  style={{
                    borderRadius: "5px",
                    marginLeft: "auto",
                    width: "165px",
                    float: "right",
                  }}
                />

                {dialogue && dialogueType === "plan" && <FlashDialog />}
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={planTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
              {/* <Table data={data} columns={planTable} searching={true} /> */}
            </div>
            <Pagination
              component="div"
              count={flashCoin?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={flashCoin?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getFlashCoin,
  deleteFlashCoin,
  isActiveFlashCion,
})(FlashCoin);
