import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
  createIncome,
  updateIncomePlan,
} from "../../store/income/income.action";
import Cancel from "../../../assets/images/cancel.png";

const IncomeDialogue = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { income } = useSelector((state) => state.income);
  const [mongoId, setMongoId] = useState(0);
  const [weeklyIncome, setWeeklyIncome] = useState(0);
  const [privateCallPrice, setPrivateCallPrice] = useState(0);
  const [matchCallPrice, setMatchCallPrice] = useState(0);
  const [level, setLevel] = useState(income?.length + 1);
  const [callBonus, setCallBonus] = useState(0);
  const [levelName, setLevelName] = useState("");
  const [error, setError] = useState({
    matchCallPrice: "",
    privateCallPrice: "",
    levelName: "",
    weeklyIncome: "",
    callBonus: "",
  });

  useEffect(() => {
    setLevel(dialogueData?.level || income?.length + 1 || 0);
    setMongoId(dialogueData?._id);
    setPrivateCallPrice(dialogueData?.privateCallPrice);
    setMatchCallPrice(dialogueData?.matchCallPrice);
    setCallBonus(dialogueData?.callBonus);
    setWeeklyIncome(dialogueData?.weeklyIncome);
    setLevelName(dialogueData?.levelName);
  }, [dialogueData]);


  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !weeklyIncome ||
      !callBonus < 0 ||
      !matchCallPrice ||
      !privateCallPrice ||
      !levelName
    ) {
      const error = {};

      if (!weeklyIncome) {
        error.weeklyIncome = "Weekly Income is required!";
      }
      if (!matchCallPrice) {
        error.matchCallPrice = "MatchCall Price is required!";
      }
      if (!privateCallPrice) {
        error.privateCallPrice = "Private Call Price is Required!";
      }
      if (!levelName) error.levelName = "Level Name is required!";
      if (!callBonus || !callBonus < 0) error.callBonus = "Call Bonus is required!";

      return setError({ ...error });
    }

    const data = {
      levelName,
      weeklyIncome: parseInt(weeklyIncome),
      privateCallPrice: parseInt(privateCallPrice),
      matchCallPrice: parseInt(matchCallPrice),
      callBonus: parseInt(callBonus),
      level: parseInt(level),
    };

    if (mongoId) {
      const updateData = {
        incomeLevelId: dialogueData?._id,
        levelName,
        weeklyIncome: parseInt(weeklyIncome),
        privateCallPrice: parseInt(privateCallPrice),
        matchCallPrice: parseInt(matchCallPrice),
        callBonus: parseInt(callBonus),
        level: parseInt(level),
      };
      dispatch(updateIncomePlan(updateData, mongoId));
    } else {
      dispatch(createIncome(data));
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Income</div>
            <div
              className="closeBtn closeDialogue text-center"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark" aria-hidden="true"></i>
              {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

            </div>
          </div>
          <div
            className="dialogueMain"
            style={{ overflow: "auto", maxHeight: "100vh" }}
          >
            <div className="row">
              <div className="col-6">
                <Input
                  label={`Level`}
                  id={`level`}
                  type={`text`}
                  name={`level`}
                  disabled
                  value={level}
                  onChange={(e) => {
                    setLevel(e.target.value);
                  }}
                  errorMessage={error.level && error.level}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Level Name`}
                  id={`levelName`}
                  type={`text`}
                  value={levelName}
                  placeholder={`Enter Level Name`}
                  onChange={(e) => {
                    setLevelName(e.target.value);
                  }}
                  errorMessage={error.levelName && error.levelName}
                />
              </div>

              <div className="col-6">
                <Input
                  label={`Weekly Income`}
                  id={`weekly income`}
                  type={`number`}
                  value={weeklyIncome}
                  errorMessage={error.weeklyIncome && error.weeklyIncome}
                  onChange={(e) => {
                    setWeeklyIncome(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        weeklyIncome: `Weekly Income Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        weeklyIncome: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Private Call Price`}
                  id={`privateCallPrice`}
                  type={`number`}
                  value={privateCallPrice}
                  errorMessage={
                    error.privateCallPrice && error.privateCallPrice
                  }
                  onChange={(e) => {
                    setPrivateCallPrice(e.target.value);
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Match Call Price`}
                  id={`matchCallPrice`}
                  type={`number`}
                  value={matchCallPrice}
                  errorMessage={error.matchCallPrice && error.matchCallPrice}
                  onChange={(e) => {
                    setMatchCallPrice(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        matchCallPrice: `Match Call Price is required!`,
                      });
                    } else {
                      return setError({
                        ...error,
                        matchCallPrice: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Call Bonus`}
                  id={`call Bonus`}
                  type={`number`}
                  value={callBonus}
                  errorMessage={error.callBonus && error.callBonus}
                  onChange={(e) => {
                    setCallBonus(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        callBonus: `Call Bonus is required!`,
                      });
                    } else {
                      return setError({
                        ...error,
                        callBonus: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="dialogueFooter">
              <div className="dialogueBtn">
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={() => {
                    dispatch({ type: CLOSE_DIALOGUE });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createIncome, updateIncomePlan })(
  IncomeDialogue
);
