import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Title from '../extra/Title';
import Host1 from "../../assets/images/male.jpg"; // Fallback image
import NoImage from "../../assets/images/noimage.png"; // Fallback image

const SnapShot = () => {
    const location = useLocation();
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // Track the selected image for preview

    const data = location?.state?.hostProfile; // Retrieving data from location state

    const handleImageClick = (image) => {
        if(image){

            setSelectedImage(image); // Set the clicked image
        }else {
            setSelectedImage(NoImage)
        }
        setIsPreviewOpen(true); // Open the preview modal
    };

    const handleClosePreview = () => {
        setIsPreviewOpen(false); // Close the preview modal
        setSelectedImage(null); // Clear the selected image
    };

    return (
        <div>
            <div className="userHeader primeHeader">
                <Title name={`SnapShots`} />
            </div>

            <div className="snapshot-gallery mt-5 d-flex flex-wrap">
                {data?.videoCallSnapshots?.map((image, index) => (
                    <div
                        key={index}
                        className="snapshot-item"
                        style={{
                            position: "relative",
                            width: "150px",
                            height: "150px",
                            margin: "10px",
                            overflow: "hidden",
                            borderRadius: "8px",
                            cursor: "pointer",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            transition: "transform 0.3s ease",
                        }}
                        onClick={() => handleImageClick(image)}
                    >
                        <img
                            src={image || NoImage} // Show the image or fallback
                            alt={`Snapshot ${index}`}
                            draggable="false"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                transition: "transform 0.3s ease",
                            }}
                            onError={(e) => {
                                e.target.src = NoImage; // Use fallback on error
                            }}
                        />
                    </div>
                ))}
            </div>

            {/* Preview Modal */}
            {isPreviewOpen && selectedImage && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000, // Ensure it's above everything
                    }}
                    onClick={handleClosePreview} // Close preview on backdrop click
                >
                    <img
                        src={selectedImage || NoImage}
                        alt="Preview"
                        style={{
                            maxWidth: "90%",
                            maxHeight: "90%",
                            objectFit: "contain",
                            border: "2px solid white",
                        }}
                    />
                </div>
            )}

            <style jsx>{`
                .snapshot-item:hover {
                    transform: scale(1.05); /* Zoom effect */
                }

                .snapshot-item:hover .snapshot-caption {
                    opacity: 1; /* Show caption on hover */
                }
            `}</style>
        </div>
    );
};

export default SnapShot;
