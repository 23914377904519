// add another way

import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

import {
  getTopic,
  deleteTopic,
  editTopic,
} from "../../store/topic/topic.action";

//routing
import { Link, useNavigate } from "react-router-dom";

//alert
import { alert, warning, permissionError } from "../../util/Alert";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ToggleSwitch from "../../extra/ToggleSwitch";
import dayjs from "dayjs";
import {
  OPEN_DIALOGUE,
  CLOSE_DIALOGUE,
} from "../../store/dialogue/dialogue.type";
import TopicDialogue from "./TopicDialogue";
import Multiselect from "multiselect-react-dropdown";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import Input from "../../extra/Input";
import { Chip, ListItem, Paper } from "@mui/material";
import TopicList from "../../extra/TopicList";

const Topic = (props) => {
  const { topic, totalTopic } = useSelector((state) => state.topic);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopic());
    // setInterestedChip("")
  }, [dispatch]);

  const [interestedText, setInterestedText] = useState();
  const [interestedChip, setInterestedChip] = useState([]);

  const [typeTwoText, setTypeTwoText] = useState();
  const [typeTwoChip, setTypeTwoChip] = useState([]);

  const [typeThreeText, setTypeThreeText] = useState();
  const [typeThreeChip, setTypeThreeChip] = useState([]);

  const [typeFourText, setTypeFourText] = useState();
  const [typeFourChip, setTypeFourChip] = useState([]);
  const [buttonDisable, setButtonDisable] = useState({
    interestedText: true,
    typeTwoText: true,
    typeThreeText: true,
    typeFourText: true,
  });

  const updateDisabledState = (fieldName, value) => {
    setButtonDisable({
      ...buttonDisable,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    updateDisabledState("interestedText", !interestedText);
  }, [interestedText]);

  useEffect(() => {
    updateDisabledState("typeTwoText", !typeTwoText);
  }, [typeTwoText]);

  useEffect(() => {
    updateDisabledState("typeThreeText", !typeThreeText);
  }, [typeThreeText]);

  useEffect(() => {
    updateDisabledState("typeFourText", !typeFourText);
  }, [typeFourText]);

  useEffect(() => {
    setInterestedChip(topic[0] && topic[0]?.topic);
    setTypeTwoChip(topic[1] && topic[1]?.topic);
    setTypeThreeChip(topic[2] && topic[2]?.topic);
    setTypeFourChip(topic[3] && topic[3]?.topic);
  }, [topic]);

  const getTopicId = topic?.map((item) => item?._id);

  const deleteInterestedChip = (indexData) => {
    const getId = topic[0]?._id;
    props.deleteTopic(getId, indexData);
  };

  const interestedChipAddData = (e) => {
    e.preventDefault();
    const topicData = { topic: [interestedText] };
    props.editTopic(getTopicId && getTopicId[0], topicData);
    setInterestedText("");
  };

  const deleteTypeTwoChip = (indexData) => {
    const getId = topic[1]?._id;
    props.deleteTopic(getId, indexData);
  };
  const typeTwoChipAddData = (e) => {
    e.preventDefault();
    const topicData = { topic: [typeTwoText] };
    props.editTopic(getTopicId && getTopicId[1], topicData);
    setTypeTwoText("");
  };

  const deleteTypeThreeChip = (indexData) => {
    const getId = topic[2]?._id;
    props.deleteTopic(getId, indexData);
  };
  const typeThreeChipAddData = (e) => {
    e.preventDefault();
    const topicData = { topic: [typeThreeText] };
    props.editTopic(getTopicId && getTopicId[2], topicData);
    setTypeThreeText("");
  };

  const deleteTypeFourChip = (indexData) => {
    const getId = topic[3]?._id;
    props.deleteTopic(getId, indexData);
  };

  const typeFourChipAddData = (e) => {
    e.preventDefault();
    const topicData = { topic: [typeFourText] };
    props.editTopic(getTopicId && getTopicId[3], topicData);
    setTypeFourText("");
  };

  return (
    <>
      <div className="mainUserTable">
        <div className="userTable primeHeader">
          <div className="userHeader ">
            <div className="row">
              <Title name={`Topic`} />
            </div>
          </div>
          {/* <div className="mt-4 row">
            <div className="col-md-6">
              <Button
                newClass={` border bgsuccess text-white bgsuccess text-white openDialogue`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`Add Topic`}
                btnColor={`btnthemePrime`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "TopicDialogue" },
                  });
                }}
                style={{ borderRadius: "7px" }}
              />
              {dialogue && dialogueType === "TopicDialogue" && (
                <TopicDialogue />
              )}
            </div>
          </div> */}

          <div className=" mt-4">
            <div className="row g-5 mt-4">
              <div className="col-xl-6 col-lg-12 col-md-12 col-12">
                <TopicList
                  label={"Interested topics"}
                  handleDeleteChip={deleteInterestedChip}
                  handleAddData={interestedChipAddData}
                  setAddChipData={setInterestedText}
                  addChipData={interestedText}
                  addChip={interestedChip}
                  disabled={buttonDisable.interestedText}
                />
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-12">
                <TopicList
                  label={"I want you're"}
                  handleDeleteChip={deleteTypeTwoChip}
                  handleAddData={typeTwoChipAddData}
                  setAddChipData={setTypeTwoText}
                  addChipData={typeTwoText}
                  addChip={typeTwoChip}
                  disabled={buttonDisable.typeTwoText}
                />
              </div>

              <div className="col-xl-6 col-lg-12 col-md-12 col-12">
                <TopicList
                  label={"Describe myself"}
                  handleDeleteChip={deleteTypeThreeChip}
                  handleAddData={typeThreeChipAddData}
                  setAddChipData={setTypeThreeText}
                  addChipData={typeThreeText}
                  addChip={typeThreeChip}
                  disabled={buttonDisable.typeThreeText}
                />
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-12">
                <TopicList
                  label={"More Information"}
                  handleDeleteChip={deleteTypeFourChip}
                  handleAddData={typeFourChipAddData}
                  setAddChipData={setTypeFourText}
                  addChipData={typeFourText}
                  addChip={typeFourChip}
                  disabled={buttonDisable.typeFourText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getTopic,
  deleteTopic,
  editTopic,
})(Topic);
