import axios from "axios";
import * as ActionType from "./FlashVip.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";
export const getFlasVip = (login) => (dispatch) => {
  apiInstanceFetch
    .get("admin/vipFlashCoin")
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_FLASH_VIP_PLAN,
          payload: res.response,
        });
        // setToast("success", "Coin Plan Get Successfully!");
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const createNewFlashVip = (formData) => (dispatch) => {
  
  axios
    .post(`admin/vipFlashCoin`, formData)
    .then((res) => {
      if (res.data.status) {
        
        dispatch({
          type: ActionType.CREATE_FLASH_VIP_PLAN,
          payload: res.data.vipFlashCoin,
        });
        setToast("success", "Flash Vip create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

// Update Coin PLan
export const updateFlashVip = (id,formData) => (dispatch) => {
  
  axios
    .patch(`admin/vipFlashCoin/${id}`, formData)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_FLASH_VIP_PLAN,
          payload: { data: res.data.vipFlashCoin, id },
        });
        setToast("success", "Flash Vip update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const deleteFlashVip = (id) => (dispatch) => {
    
  axios
    .delete(`admin/vipFlashCoin/${id}`)
      .then((res) => {
        
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_FLASH_VIP_PLAN,
          payload: id,
        });
        setToast("success", "Flash Vip Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const isActiveFlashVip = (id) => (dispatch) => {
  
  axios
    .put(`admin/vipFlashCoin/${id}`)
      .then((res) => {
        
      if (res.data.status) {
        dispatch({
          type: ActionType.ACTIVE_SWITCH,
          payload: { data: res.data.response, id },
        });

        setToast(
          "success",
          `${
            res.data.response.isActive === true
              ? "Flash Vip  Active SuccessFully"
              : "Flash Vip Disable SuccessFully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// Purchase History Get

// export const getPurchaseHistory = (startDate, endDate) => (dispatch) => {
//   axios
//     .get(`/coinPlan/purchaseHistory?startDate=${startDate}&endDate=${endDate}`)
//     .then((res) => {
//       dispatch({ type: ActionType.PURCHASE_HISTORY, payload: res.data });
//     })
//     .catch((error) => console.log("error", error));
// };
