import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./setting.type";
import jwt_decode from "jwt-decode";

const initialState = {
  setting: [],
  multiAppSetting: [],
  appWiseSetting: [],
  hostDetail : {}
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SETTING:
      return {
        ...state,
        setting: action.payload,
      };
    case ActionType.UPDATE_SETTING:
      return {
        ...state,
        setting: action.payload,
      };
    case ActionType.IS_APP_ACTIVE_SWITCH:
      return {
        ...state,
        setting: {
          ...state.setting,
          isAppActive: action.payload.isAppActive,
        },
        setting: {
          ...state.appWiseSetting,
          isAppActive: action.payload.isAppActive,
        },
      };
    case ActionType.All_SWITCH_TYPE:

      return {
        ...state,
        setting: action.payload,
        appWiseSetting: action.payload,
      };
    // case ActionType.QUICK_SWITCH:
    //   return {
    //     ...state,
    //     setting: {
    //       ...state.setting,
    //       quickLogin: action.payload.quickLogin,
    //     },
    //   };
    case ActionType.GET_APP_WISE_SETTING:
      return {
        ...state,
        appWiseSetting: action.payload.setting,
        hostDetail: action.payload.host,
      };
    case ActionType.EMPTY_APP_WISE_SETTING:
      return {
        ...state,
        appWiseSetting: [],
      };

    case ActionType.GET_MULTIAPP_SETTING:
      return {
        ...state,
        multiAppSetting: action.payload,
      };

    case ActionType.UPDATE_MULTIAPP_SETTING:
      return {
        ...state,
        multiAppSetting: state.multiAppSetting.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };

    case ActionType.ADD_NEWAPP_SETTING:
      const data = [...state.multiAppSetting];
      data.unshift(action.payload);
      return {
        ...state,
        multiAppSetting: data,
      };

    case ActionType.DELETE_MULTIAPP_SETTING:
      return {
        ...state,
        multiAppSetting: state.multiAppSetting.filter(
          (data) => data._id !== action.payload && data
        ),
      };

    case ActionType.ACTIVE_MULTIAPP_SWITCH:
      return {
        ...state,
        multiAppSetting: state.multiAppSetting.map((appActive) => {
          if (appActive._id === action.payload.id) return action.payload.data;
          else return appActive;
        }),
      };
    default:
      return state;
  }
};
