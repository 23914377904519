import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";
import {
  getAgencyCommision,
  deleteAgencyCommission,
} from "../../store/agency/agency.action";
import Table from "../../extra/Table";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import Pagination from "../../extra/Pagination";
import AgencyCommissionDialog from "./AgencyCommissionDialog";
import { warning } from "../../util/Alert";

const AgencyComission = (props) => {
  const { agencyCommission } = useSelector((state) => state.agency);
  const { dialogueData, dialogue, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const { state } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAgencyCommision(state?._id ? state?._id : dialogueData?._id));
  }, [dialogueData, state]);

  useEffect(() => {
    setData(agencyCommission && agencyCommission?.commission);
  }, [agencyCommission]);

  const goPrevious = () => {
    
    navigate(-1) 
    localStorage.removeItem("dialogueData");
  };

  const handleDelete = (commissionId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteAgencyCommission(commissionId,state?._id);
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Upper Coin",
      body: "upperCoin",
      sorting: { type: "client" },
    },
    {
      Header: "Percentage",
      Cell: ({ row }) => <span>{row?.percentage + " %"}</span>,
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn textPink fs-5`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          // onClick={() => handleEdit(row)}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: {
                data: { data: row, agencyId: state?._id },
                type: "AgencyCommission",
              },
            });
          }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];
  return (
    <>
      <div className="settlementTable  position-relative">
        <div className="settlementHeader primeHeader boxBetween">
          <Title name={`Agency Commission`} />
        </div>
        <div className="d-flex justify-content-between mx-3 mt-4">
          <h5>
            <span className="text-primary text-capitalize">
              {state ? state?.name : dialogueData?.name}
            </span>
            ' Agency
          </h5>
          <button
            className="btn btn-primary btn-icon px-4"
            onClick={goPrevious}
          >
            <i class="fa-solid fa-angles-left text-white fs-6"></i>
          </button>
        </div>
        <div className="primeHeader mt-4 row">
          <div className="col-md-6 p-0 d-flex">
            <Button
              newClass={` border bgsuccess text-white bgsuccess text-white openDialogue`}
              btnIcon={`fas fa-plus fa-md`}
              btnName={`Add Commission`}
              onClick={() => {
                dispatch({
                  type: OPEN_DIALOGUE,
                  payload: { type: "AgencyCommission", data: state?._id },
                });
              }}
              // navigate("/admin/addAgency");

              // onClick={handleChangeImage}
              style={{ borderRadius: "7px" }}
            />
            {dialogue && dialogueType == "AgencyCommission" && (
              <AgencyCommissionDialog />
            )}
          </div>

          <div
            className="d-flex justify-content-end col-md-6 m-0 p-0 col-sm-12"
            style={{ alignSelf: "center" }}
          ></div>
        </div>

        <div className="userMain">
          <div className="tableMain mt-3">
            <Table data={data} mapData={mapData} type={"client"} />
          </div>
        </div>

        <div className="userFooter primeFooter"></div>
      </div>
    </>
  );
};

export default connect(null, { deleteAgencyCommission })(AgencyComission);
