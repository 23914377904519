import { Link } from "react-router-dom";

const Title = (props) => {
  return (
    <>
    <div className={ !props.newClass ? `boxBetween pt-3`: `${props.newClass}`}>
      <div className="title">
        <h4 className="mb-0 fw-bold text-capitalize" >{props.name}</h4>
      </div>
      {/* <div className="titlePath">
        <span>
          <Link className="primeColor" to="/admin/dashboard">
            Home
          </Link>
        </span>
        <span> / {props.name} </span>
      </div> */}
      </div>
    </>
  );
};

export default Title;
