// GET AGENCY
export const GET_AGENCY = "GET_AGENCY";

// ADD AGENCY
export const ADD_AGENCY = "ADD_AGENCY";

// EDIT AGENCY
export const EDIT_AGENCY = "EDIT_AGENCY";

// DELETE AGENCY
export const DISABLE_AGENCY = "DISABLE_AGENCY";

// agency Disable

export const AGENCY_DISABLE = "AGENCY_DISABLE";

export const AGENCY_HISTORY = "AGENCY_HISTORY";

export const AGENCY_COMMISSION = "AGENCY_COMMISSION";
export const ADD_AGENCY_COMMISSION = "ADD_AGENCY_COMMISSION";
export const UPDATE_AGENCY_COMMISSION = "UPDATE_AGENCY_COMMISSION";
export const DELETE_AGENCY_COMMISSION = "DELETE_AGENCY_COMMISSION";
