import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./reward.type";
import jwt_decode from "jwt-decode";

const initialState = {
  reward: [],
  total: null,
  // totalCoin: null,
  totalDollar: null,
  history: [],
};

export const RewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_REWARD_LEVEL:
      return {
        ...state,
        reward: action.payload,
      };
    case ActionType.CREATE_REWARD_LEVEL:
      let data = [...state.reward];
      data.push(action.payload);
      return {
        ...state,
        reward: data,
      };
    case ActionType.EDIT_REWARD_LEVEL:
      return {
        ...state,
        reward: state.reward.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    default:
      return state;
  }
};
