import React, { useEffect, useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "../../../extra/Pagination";
import Table from "../../../extra/Table";
import moment from "moment";
import { getUserCallHistory } from "../../../store/user/user.action";
import { useNavigate } from "react-router-dom";

const UserCallHistory = (props) => {
  const { userCallHistory, totalCall, totalCoin } = useSelector(
    (state) => state.user
  );
  const { dialogue, dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };

  useEffect(() => {
    setData(userCallHistory);
  }, [userCallHistory]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getUserCallHistory(
        dialogueData?._id,
        startDate,
        endDate,
        currentPage,
        rowsPerPage
      )
    );
  }, [dialogueData, startDate, endDate, currentPage, rowsPerPage]);

  let date;
  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Details",
      Cell: ({ row }) => {
        var str = row?.data?.host?.name;
        if (str?.length > 15) str = str.substring(0, 15) + "...";

        return (
          <>
            {/* <span className="fw-semibold ">
              {row?.callType === "MissedCall" && (
                <i className="fa fa-phone m-1 text-danger"> </i>
              )}
              {row?.callType === "Outgoing" && (
                <i className="fa fa-phone m-1 text-success"></i>
              )}
              {row?.callType === "Incoming" && (
                <i className="fa fa-phone m-1 text-success"></i>
              )}
            </span> */}

            <span
              className="text-primary fw-bold fs-6"
              // style={{ cursor: "pointer" }}
              // onClick={() =>
              //   navigate("/admin/host/hostInfo", { state: row?.hostId })
              // }
            >
              @{str}
            </span>

            {/* <span className="me-2 fs-6 ">[{row?.callType}]</span> */}
          </>
        );
      },
    },
    {
      Header: "Type",
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold">
          {row?.data?.callType === "MissedCall" && (
            <span className="text-danger">MissedCall</span>
          )}
          {row?.data?.callType === "Outgoing" && (
            <span className="text-success"> Outgoing</span>
          )}
          {row?.data?.callType === "Incoming" && (
            <span className="  text-success">Incoming</span>
          )}
          {row?.data?.callType === "Random" && (
            <span className="  text-success">Random</span>
          )}
        </span>
      ),
    },

    {
      Header: "Duration",
      Cell: ({ row }) => (
        <span className="fw-bold fs-6">
          {row?.data?.callConnect
            ? row?.data?.callEndTime
              ? moment
                  .utc(
                    moment
                      .duration(row?.data?.duration, "seconds")
                      .as("milliseconds")
                  )
                  .format("HH:mm:ss")
              : " - "
            : "00:00:00"}
        </span>
      ),
    },
    {
      Header: "Coin",
      Cell: ({ row }) =>
        row?.data?.coin > 0 ? (
          <span className="text-danger fs-6 fw-bold">-{row?.data?.coin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row?.data?.coin}</span>
        ),
    },
    {
      Header: "Description",
      body: "description",
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold">{row?.data?.description}</span>
      ),
    },
    {
      Header: "End By",
      body: "callEndReason",
      Cell: ({ row }) => (
        <span>{row?.data?.callEndReason ? row?.data?.callEndReason : "-"}</span>
      ),
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        date = row?.data?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {date && date.length > 0 ? date[0] : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.data?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {date && date.length > 1 ? date[1] : "N/A"}
          </span>
        );
      },
    },

    // add more columns as needed
  ];

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setSize( parseInt(event), 20);
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <div className="row d-flex align-items-center border-bottom py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="tableMain mt-5">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={currentPage}
            type={"server"}
          />
          <Pagination
            component="div"
            count={userCallHistory?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={totalCall}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getUserCallHistory })(UserCallHistory);
