import React from "react";
import Button from "../../extra/Button";
import ToggleSwitch from "../../extra/ToggleSwitch";

const SettingBox = (props) => {
  const { submit, title, toggleSwitch, title1, toggleSwitch1 } = props;

  return (
    <>
      <div className="settingBox">
        <div className="settingBoxHeader boxBetween">
          <div className="mainTitle">{title}</div>
          {toggleSwitch && (
            <div className="titleBtn boxBetween">
              <div className="me-3 ms-5 ps-5 fw-bold">
                {toggleSwitch.switchName}
              </div>
              <div>
                <ToggleSwitch
                  value={toggleSwitch.switchValue}
                  onClick={toggleSwitch.handleClick}
                />
              </div>
            </div>
          )}
        </div>
        <div className="settingInnerBox">
          <div className="row">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default SettingBox;
