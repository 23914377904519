import axios from "axios";
import { setToast } from "../../util/toast";
import {
  CREATE_NEW_VIP_PLAN,
  DELETE_VIP_PLAN,
  EDIT_VIP_PLAN,
  GET_VIP_PLAN,
  GET_VIP_PLAN_HISTORY,
  IS_TOP_VIP_PLAN,
  RENEWAL_SWITCH,
} from "./types";
import { apiInstanceFetch } from "../../util/api";

export const getVIPPlan = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/vipPlan/getAllPlan?start=${start}&limit=${limit}`)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: GET_VIP_PLAN,
          payload: { data: res.vipplan, totalPlan: res.total },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const createNewVIPPlan = (data) => (dispatch) => {
  axios
    .post(`admin/vipPlan`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Plan created successfully!");

        dispatch({ type: CREATE_NEW_VIP_PLAN, payload: res.data.vipPlan });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const editVIPPlan = (vipPlanId, data) => (dispatch) => {
  axios
    .patch(`admin/vipPlan/${vipPlanId}`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Plan updated successfully!");

        dispatch({
          type: EDIT_VIP_PLAN,
          payload: { data: res.data.vipPlan, id: vipPlanId },
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const deleteVIPPlan = (vipPlanId) => (dispatch) => {
  axios
    .delete(`admin/vipPlan/${vipPlanId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_VIP_PLAN, payload: { data: res.data.data } });
        setToast("success", "Vip Plan Delete successfully");

      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const handleRenewalSwitch = (vipPlanId) => (dispatch) => {
  axios
    .put(`admin/vipPlan/${vipPlanId?._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: RENEWAL_SWITCH,
          payload: { data: res.data.vipPlan, vipPlanId: vipPlanId?._id },
        });
        setToast(
          "success",
          `${
            res.data.vipPlan.isActive === true
              ? "VipPlan  Active SuccessFully"
              : "VipPlan Disable SuccessFully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const isTopVipPlan = (vipPlanId) => (dispatch) => {
  axios
    .put(`admin/vipPlan/isTopToggle?planId=${vipPlanId?._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: IS_TOP_VIP_PLAN,
          payload: { data: res.data.data, vipPlanId: vipPlanId?._id },
        });
        setToast("success", "VipPlan Update SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const vipPlanHistory =
  (id, start, limit, sDate, eDate) => (dispatch) => {
    const url =
      id !== null
        ? `vipPlan/history?userId=${id}&start=${start}&limit=${limit}&startDate=${sDate}&endDate=${eDate}`
        : `vipPlan/history?start=${start}&limit=${limit}&startDate=${sDate}&endDate=${eDate}`;
    apiInstanceFetch
      .get(url)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_VIP_PLAN_HISTORY,
            payload: { history: res.history, total: res.total },
          });
        } else {
          setToast("error", res.message);
        }
      })
      .catch((error) => console.log(error));
  };
