import {
    CREATE_NEW_LEVEL,
    DELETE_LEVEL,
    UPDATE_NEW_LEVEL,
    GET_LEVEL,
    VIP_SWITCH,
} from "./level.type";

const initialState = {
    vipLevel: [],
    dialog: false,
    dialogData: null,
};

const levelReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEVEL:
            return {
                ...state,
                vipLevel: action.payload,
            };
        case CREATE_NEW_LEVEL:
            const data = [...state.vipLevel];
            data.unshift(action.payload);
            return {
                ...state,
                vipLevel: data,
            };
        case UPDATE_NEW_LEVEL:
            return {
                ...state,
                vipLevel: state.vipLevel.map((data) =>
                    data._id === action.payload.id ? action.payload.data : data
                ),
            };
        case DELETE_LEVEL:
            return {
                ...state,
                vipLevel: state.vipLevel.filter((vipLevel) => vipLevel._id !== action.payload),
            };
        case VIP_SWITCH:
            return {
                ...state,
                vipLevel: state.vipLevel.map((vipLevel) => {
                    if (vipLevel._id === action.payload._id)
                        return {
                            ...vipLevel,
                            isVIP: action.payload.isVIP,
                        };
                    else return vipLevel;
                }),
            };



        default:
            return state;
    }
};

export default levelReducer;
