import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "../../../extra/Pagination";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Analytics from "../../../extra/Analytics";
import moment from "moment";
import { getUserAllHistory } from "../../../store/user/user.action";

const UserReferralHistory = (props) => {
  const { userHistory, totalUser, totalCoin } = useSelector(
    (state) => state.user
  );

  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);

  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };

  useEffect(() => {
    setData(userHistory);
  }, [userHistory]);

  useEffect(() => {
    dispatch(
      getUserAllHistory(
        dialogueData._id,
        "referral",
        startDate,
        endDate,
        currentPage,
        rowsPerPage
      )
    );
  }, [dialogueData, currentPage, rowsPerPage, startDate, endDate]);

  let date;
  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    {
      Header: "Coin",
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row?.uCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row?.uCoin}</span>
        ),
    },

    {
      Header: "Date",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {date && date.length > 0 ? date[0] : "N/A"}
          </span>
        );
      },
    },

    // add more columns as needed
  ];

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event), 20);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="row d-flex align-items-center border-bottom py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="tableMain mt-5">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={currentPage}
            type={"server"}
          />
          <Pagination
            component="div"
            count={data?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={totalUser}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getUserAllHistory })(UserReferralHistory);
