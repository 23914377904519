import "./App.css";
import Login from "./Component/Pages/Login.js";

import Admin from "./Component/Pages/Admin";
import PrivateRoute from "./Component/util/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LOGIN_ADMIN } from "./Component/store/admin/admin.type";
import { setToken } from "./Component/util/setAuth";
import AuthRoute from "./Component/util/AuthRoute";

function App() {
  const dispatch = useDispatch();
  const key = localStorage.getItem("key");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: LOGIN_ADMIN, payload: token });
  }, [setToken, key]);

  // const isAuth = useSelector((state) => state.admin.isAuth);
  // const isAuth = true;
  const navigate = useNavigate();

  const isAuth = true;

  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<AuthRoute />}>
          <Route path="/" element={<Login />} />
          <Route path="/admin/*" element={<Admin />} />
        </Route> */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
      </Routes>

      {/* <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin/*" element={<Admin />} />
      </Routes> */}
    </div>
  );
}

export default App;
