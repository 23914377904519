import axios from "axios";
import { setToast } from "../../util/toast";
import {
  CLOSE_CATEGORY_DIALOG,
  CREATE_NEW_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY,
  EDIT_CATEGORY_ACTIVE,
} from "./types";
import { apiInstanceFetch } from "../../util/api";

export const getCategory = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/giftCategory/allCategory`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_CATEGORY, payload: res.category });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const createNewCategory = (data) => (dispatch) => {
  axios
    .post(`admin/giftCategory`, data)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: CREATE_NEW_CATEGORY, payload: res.data.category });
        setToast("success", "Category created successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const editCategory = (data, giftCategoryId) => (dispatch) => {
  axios
    .patch(`admin/giftCategory/${giftCategoryId}`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Category updated successfully!");

        dispatch({
          type: EDIT_CATEGORY,
          payload: { data: res.data.category, id: giftCategoryId },
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const deleteCategory = (giftCategoryId) => (dispatch) => {
  axios
    .delete(`admin/giftCategory/${giftCategoryId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_CATEGORY, payload: giftCategoryId });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const categoryActive = (giftCategory, active) => (dispatch) => {
  axios
    .put(`admin/giftCategory/${giftCategory._id}`)
    .then((res) => {
      if (res.data.status) {
        setToast(
          "success",
          `${giftCategory.name} is ${active === true ? "Active" : "Deactive"}`
        );

        dispatch({
          type: EDIT_CATEGORY_ACTIVE,
          payload: { data: res.data.category, id: giftCategory._id },
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
