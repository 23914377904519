import axios, { Axios } from "axios";
import { data } from "jquery";
import { setToast } from "../../util/toast";
import * as ActionType from "./fakeHost.type";
import { apiInstanceFetch } from "../../util/api";

export const getFakeHost = (search, start, limit, type) => (dispatch) => {
  apiInstanceFetch
    .get(
      `admin/host?search=${search}&type=${type}&start=${start}&limit=${limit}`
    )
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_FAKE_HOST,
          payload: { data: res.host, total: res.total },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const getFakeHostForStory = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin/host/getFakeHostForStory`)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_FAKE_HOST_FOR_STORY,
          payload: { data: res.host },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

// export const ApprovedSwitch = (agency, disable) => (dispatch) => {
//   axios
//     .put(`admin/host/${agency._id}`)
//     .then((res) => {
//       if (res.data.status) {
//         setToast(
//           "success",
//           `${agency.name} Is ${
//             disable === true ? "Blocked" : "Unblocked"
//           } Successfully!`
//         );
//       } else {
//         setToast("error", res.data.message);
//       }
//     })
//     .catch((error) => setToast("error", error));
// };

// export const blockUnblockSwitch = (host, block) => (dispatch) => {
//   ;
//   axios
//     .put(`admin/host/blockUnblock?hostId=${host._id}`)
//     .then((res) => {
//       ;
//       if (res.data.status) {
//         dispatch({
//           type: ActionType.BLOCKED_HOST,
//           payload: { data: res.data.host, id: host._id },
//         });
//         setToast(
//           "success",
//           `${host.name} Is ${
//             block === true ? "Blocked" : "Unblocked"
//           } Successfully!`
//         );
//       } else {
//         setToast("error", res.data.message);
//       }
//     })
//     .catch((error) => setToast("error", error));
// };
export const createFakeHost = (formData) => (dispatch) => {
  axios
    .post(`admin/host/insertFakeHost`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_FAKE_HOST,
          payload: res.data.host,
        });
        setToast("success", "Host  created Successfully !");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

// UPDATE SELLER

export const updateHost = (id, formData) => (dispatch) => {
  axios
    .patch(`admin/host/updateFakeHost/${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_FAKE_HOST,
          payload: { data: res.data.host, id },
        });

        setToast("success", "Fake Host Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const sendExcelApi = (formData) => (dispatch) => {
  axios
    .post(`/admin/host/insertFakeHostByExcel`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.SEND_EXCEL,
          payload: [res.data.hostData],
        });

        setToast("success", "Host  created Successfully !");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.response.data.message);
    });
};

export const deleteHostImg = (imgId, position) => (dispatch) => {
  axios
    .delete(
      `admin/host/deleteImagesByAdmin?hostId=${imgId}&position=${position}`
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_HOST_IMG,
          payload: res.data.host,
        });
        // setToast("success", "Img deleted successfully!");
      } else {
        c;
      }
    })
    .catch((error) => console.log(error));
};
export const deleteFakeHost = (id) => (dispatch) => {
  axios
    .delete(`admin/host/deleteFakeHostByAdmin?hostId=${id}`)
    .then((res) => {
      if (res.data.status) {
        
        dispatch({
          type: ActionType.DELETE_FAKE_HOST,
          payload: id,
        });
        setToast("success", "Host deleted successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
