import {
    CREATE_NEW_BANNER,
    DELETE_BANNER,
    GET_BANNER,
    VIP_SWITCH,
  } from "./banner.type";
  
  const initialState = {
    banner: [],
    dialog: false,
    dialogData: null,
  };
  
  const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_BANNER:
        return {
          ...state,
          banner: action.payload,
        };
      case CREATE_NEW_BANNER:
        const data = [...state.banner];
        data.unshift(action.payload);
        return {
          ...state,
          banner: data,
        };
      
      case DELETE_BANNER:
        return {
          ...state,
          banner: state.banner.filter((banner) => banner._id !== action.payload),
        };
      case VIP_SWITCH:
        return {
          ...state,
          banner: state.banner.map((banner) => {
            if (banner._id === action.payload._id)
              return {
                ...banner,
                isVIP: action.payload.isVIP,
              };
            else return banner;
          }),
        };

      
  
      default:
        return state;
    }
  };
  
  export default bannerReducer;
  