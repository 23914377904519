import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import { getGift, isTop, deleteGift } from "../../store/Gift/gift.action";

//config
import { baseURL } from "../../util/config";

//sweet alert
import { alert, permissionError, warning } from "../../util/Alert";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";

import Button from "../../extra/Button";
import Title from "../../extra/Title";
import GiftDialog from "./GiftDialog";
import AllGift from "../../Pages/AllGift";
import { useLocation } from "react-router-dom";
import Male from "../../../assets/images/male.jpg";

const Gift = (props) => {
  const dispatch = useDispatch();
  const gift = useSelector((state) => state.gift.gift);
  const [data, setData] = useState([]);
  const { state } = useLocation();
  const GiftClick = localStorage.getItem("GiftClick");


  useEffect(() => {
    GiftClick === null
      ? dispatch(getGift(state?._id))
      : dispatch(getGift("ALL"));
  }, [dispatch, state]);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    setData(gift);
  }, [gift]);

  const handleDelete = (giftId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        // if (!hasPermission) return permissionError();
        if (isDeleted) {
          props.deleteGift(giftId);
          alert("Deleted!", `Category has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  // //Top Switch
  // const handleTop = (value) => {
  //   props.isTop(value._id, value.isTop);
  // };
  return (
    <>
      <div className="mainUserTable">
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="col-6">
              <Title
                name={GiftClick == null ? state?.name + " Gift" : `Gift`}
              />
            </div>

            {/* <div className="row">
                <Title name={`Gift`} />
            </div> */}
          </div>
          <div className="primeHeader mt-4 row">
            {/* <i className="fas fa-plus fa-md"></i> */}
            <div className="col-md-6">
              <Button
                newClass={` border bgsuccess text-white`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`New Gift`}
                btnColor={`btnthemePrime`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "Gift" },
                  });
                }}
                // navigate("/admin/addAgency");

                // onClick={handleChangeImage}
                style={{ borderRadius: "7px" }}
              />
              {dialogue && dialogueType === "Gift" && <GiftDialog />}
            </div>
            <div className="row layout-top-spacing">
              {data?.length > 0 ? (
                data?.map((data, index) => {
                  return (
                    <>
                      {GiftClick == null ? (
                        <div
                          class="col-xl-3 col-md-4 col-sm-6 mt-4"
                          key={index}
                        >
                          <div class="card contact-card card-bg pointer-cursor m-2">
                            <>
                              <div class="card-body row ">
                                <div className="col-6 text-center ">
                                  <img
                                    src={
                                      baseURL + data?.image
                                        ? baseURL + data?.image
                                        : Male
                                    }
                                    height={100}
                                    width={100}
                                    alt=""
                                    className=" rounded-circle"
                                    onError={(e) => (e.target.src = Male)}
                                  />
                                </div>
                                <div className="col-6 text-left">
                                  <div className="contact-card-info m-2  text-left">
                                    <span>
                                      {data?.coin ? data?.coin : "0"} Coins
                                    </span>
                                  </div>
                                  <div class="contact-card-buttons   text-left">
                                    <a
                                      type="button"
                                      class="shadow-none edit-button badge badge-lg  p-2 m-1 d-inline-block"
                                      // onClick={() => handleEdit(data)}
                                      onClick={() => {
                                        dispatch({
                                          type: OPEN_DIALOGUE,
                                          payload: {
                                            data: data,
                                            type: "Gift",
                                          },
                                        });
                                      }}
                                      href
                                    >
                                      <img
                                        src={Edit}
                                        height={30}
                                        width={30}
                                        alt="Edit"
                                        onClick={() => {
                                          dispatch({
                                            type: OPEN_DIALOGUE,
                                            payload: {
                                              data: row,
                                              type: "VipPlan",
                                            },
                                          });
                                        }}
                                      />
                                    </a>


                                    <a
                                      type="button"
                                      class="shadow-none delete-button badge badge-lg  p-2 m-1 d-inline-block"
                                      onClick={() => handleDelete(data?._id)}
                                      href
                                    >
                                      <i class="fas fa-trash"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      ) : (
                        <div className="row  order-md-1 order-last">
                          <h4 className="mt-2 mb-5 gift_category__title">
                            {data?.name} Gifts
                          </h4>
                          <AllGift data={data} />
                        </div>
                      )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" align="center">
                    Nothing to show!!
                  </td>
                </tr>
              )}
            </div>
          </div>

          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getGift, deleteGift })(Gift);
