import * as ActionType from "./fakeStory.type";

const initialState = {
  fakeStory: [],
};

export const fakeStoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GETALL_FAKE_STORY:
      return {
        ...state,
        fakeStory: action.payload,
      };
      case ActionType.CREATE_FAKE_STORY:
        
        let data = [...state.fakeStory];
        data.unshift(action.payload);
        return {
          ...state,
          fakeStory: data,
        };
    case ActionType.DELETE_FAKE_STORY:
      ;
      return {
        ...state,
        fakeStory: state.fakeStory.filter(
          (data) => data._id !== action.payload && data
        ),
      };
    default:
      return state;
  }
};
