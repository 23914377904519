import { connect, useDispatch, useSelector } from "react-redux";
import { allAgencySettlement } from "../../store/settlement/settlement.action";
import { useEffect, useState } from "react";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import AgencyWeekSettlement from "./AgencyWeekSettlement";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import SettlementActionDialogue from "./SettlementActionDialogue";
import { useNavigate } from "react-router-dom";
import BonusInfo from "./BonusInfo";
import Info from "../../../assets/images/Info.png";

const AllSettlement = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const { settlement } = useSelector((state) => state.settlement);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  useEffect(() => {
    props.allAgencySettlement(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    setData(settlement);
  }, [settlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  const handleProfile = (row, type) => {
    navigate("/admin/agencyWeekSettlement", {
      state: { id: row?._id, data: row },
    });
  };
  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Start Date", body: "startDate" },
    { Header: "End Date", body: "endDate", style: { width: "200px" } },
    { Header: "Total Agency", body: "totalAgency" },
    { Header: "Revenue", body: "totalrevenue", sorting: { type: "client" } },
  
    {
      Header: "Total Revenue",
      body: "totalCoinEarned",
      sorting: { type: "client" },
    },
    {
      Header: "Penalty/Bonus",
      body: "penltyOrBonus",
      sorting: { type: "client" },
      Cell: ({ row }) => <span onClick={() => {
        dispatch({
          type: OPEN_DIALOGUE,
          payload: { type: "bonusInfo", data: row },
        });
      }} className={`cursor ${row?.penltyOrBonus > 0 ? "text-success" : row?.penltyOrBonus === 0 ? "text-dark" : "text-danger"}`}>{row?.penltyOrBonus}</span>

    },
    { Header: "Final ", body: "total", sorting: { type: "client" } },
    {
      Header: "Amount",
      Cell: ({ row }) => <span>{row.dollar.toFixed(2) + " $ "}</span>,
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <img
        src={Info}
        height={30}
        width={30}
        alt="Delete"
        onClick={() => handleProfile(row, "agencyWeekSettlement")}
      />
      ),
    },
  ];

  return (
    <>
      <div className="mainSettlementTable">
        {dialogue && dialogueType === "bonusInfo" && <BonusInfo />}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`All Settlement`} />
            </div>
            <div className="d-flex justify-content-end col-md-12 ">
              <div className="col-6 my-3">
                <Searching
                  type={"client"}
                  data={settlement}
                  setSearchData={setSearch}
                  setData={setData}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={mapData}
                  searchValue={search}
                  placeholder={"Searching User..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            </div>
            <Pagination
              component="div"
              count={settlement?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={settlement?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { allAgencySettlement })(AllSettlement);
