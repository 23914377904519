import axios from "axios";
import * as ActionType from "./setting.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getSetting = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/setting`)
    .then((res) => {
      dispatch({ type: ActionType.GET_SETTING, payload: res.setting });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateSetting = (settingData, id) => (dispatch) => {
  axios
    .patch(`admin/setting/${id}`, settingData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_SETTING,
          payload: res.data.setting,
        });
        setToast("success", "Updated Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const isAppActiveSwitch = (settingId) => (dispatch) => {
  axios
    .put(`admin/setting/app?settingId=${settingId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.IS_APP_ACTIVE_SWITCH,
          payload: res.data.setting,
        });
        setToast("success", "Updated Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const allSwitchType = (settingId, type) => (dispatch) => {
  axios
    .put(`admin/appWiseSetting?settingId=${settingId}&type=${type}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.All_SWITCH_TYPE,
          payload: res.data.setting,
        });
        dispatch(getMultiAppSetting());
        setToast("success", "Updated Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
// export const quickSwitch = (settingId, type) => (dispatch) => {
//   axios
//     .put(`admin/setting?settingId=${settingId}&type=${type}`)
//     .then((res) => {
//       if (res.data.status) {
//         dispatch({
//           type: ActionType.QUICK_SWITCH,
//           payload: res.data.setting,
//         });
//         setToast("success", "Updated Successfully!");
//       } else {
//         setToast("error", res.data.message);
//       }
//     })
//     .catch((error) => setToast("error", error.message));
// };

export const addMultiAppSetting = (formData) => (dispatch) => {
  axios
    .post(`admin/appWiseSetting/add`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.ADD_NEWAPP_SETTING,
          payload: res.data.appwisesetting,
        });
      }
      setToast("success", "Add Successfully!");
    })
    .catch((error) => setToast("error", error.message));
};

export const getAppWiseSetting = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/appWiseSetting/byId?settingId=${id}`)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_APP_WISE_SETTING,
          payload: {setting : res.setting,host : res.host},
        });
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const getMultiAppSetting = () => (dispatch) => {
  apiInstanceFetch
    .get("admin/appWiseSetting")
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_MULTIAPP_SETTING,
          payload: res.setting,
        });
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const updateMultiAppSetting = (formdata, id) => (dispatch) => {
  axios
    .put(`admin/appWiseSetting/update?id=${id}`, formdata)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_MULTIAPP_SETTING,
          payload: { data: res.data.setting, id },
        });
      }
      setToast("success", "Update Successfully!");
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const deleteMultiAppSetting = (id) => (dispatch) => {
  axios
    .delete(`admin/appWiseSetting?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_MULTIAPP_SETTING,
          payload: id,
        });
      }
      setToast("success", "Delete Successfully!");
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const isMultiAppActive = (id, type) => (dispatch) => {
  axios
    .put(`admin/appWiseSetting/switch?id=${id}&type=${type}`)
    .then((res) => {
      dispatch({
        type: ActionType.ACTIVE_MULTIAPP_SWITCH,
        payload: { data: res.data.setting, id: id },
      });
      setToast(
        "Success",
        `${res.data.setting.appName}  ${
          res.data.setting.isAppActive === true
            ? `App is Active Successfully`
            : `App is Inactive Successfully`
        }`
      );
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const verifyName = (appName) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/appWiseSetting/verifyName?appName=${appName}`)
    .then((res) => {
      if(res.status){
         dispatch({ type: ActionType.VERIFY_NAME, payload: res.setting });
      }else{
        setToast("error",res.message)
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const verifyPackage = (packageName) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/appWiseSetting/verifyName?packageName=${packageName}`)
    .then((res) => {
      if(res.status){
         dispatch({ type: ActionType.VERIFY_NAME, payload: res.setting });
      }else{
        setToast("error",res.message)
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
