import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { getUserAllHistory } from "../../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import male from "../../../../assets/images/male.jpg";

const UserGiftHistory = (props) => {
  const { userHistory, totalUser, totalCoin } = useSelector(
    (state) => state.user
  );
  const { dialogue, dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    dispatch(
      getUserAllHistory(
        dialogueData._id,
        "gift",
        startDate,
        endDate,
        currentPage,
        rowsPerPage
      )
    );
  }, [currentPage, dialogueData, startDate, endDate, rowsPerPage, "gift"]);

  useEffect(() => {
    setData(userHistory);
  }, [userHistory]);

  const navigate = useNavigate();

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  let date;
  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Details",
      Cell: ({ row }) => {
        var str = row?.hostName;
        if (str?.length > 15) str = str.substring(0, 15) + "...";

        return (
          <>
            <span className="fs-6 text-dark">Gift send to </span>

            <span
              className="text-info fw-bold fs-6"
              // onClick={() => navigate("/admin/hostInfo", { state: row })}
            >
              @{str}
            </span>
          </>
        );
      },
    },

    {
      Header: "Coin",
      Cell: ({ row }) =>
        row.uCoin > 0 ? (
          <span className="text-danger fs-6 fw-semibold">-{row?.uCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-bold">{row?.uCoin}</span>
        ),
    },

    {
      Header: "Date",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {" "}
            {date && date.length > 0 ? date[0] : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return (
          <span className="text-dark fs-6 fw-semibold">
            {" "}
            {date && date.length > 1 ? date[1] : "N/A"}
          </span>
        );
      },
    },

    // add more columns as needed
  ];

  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };
  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setSize( parseInt(event), 20);
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <div className="row d-flex align-items-center border-bottom py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="tableMain mt-5">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={currentPage}
            type={"server"}
          />
          <Pagination
            component="div"
            count={userHistory?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={totalUser}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getUserAllHistory })(UserGiftHistory);
