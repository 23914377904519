import { width } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import dayjs from "dayjs";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { getCommissionPlan,updateCommissionPlan,deleteCommissionPlan } from "../../store/commission/commission.action";
import CommissionDialogue from "./CommissionDialog"
import { addCommissionPlan } from "../../store/commission/commission.action"
import {warning} from "../../util/Alert"

const Commission = (props) => {

  const { commission} = useSelector((state) => state.commission );
  const {dialogue, dialogueType} = useSelector((state) => state.dialogue)

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getCommissionPlan())
  },[dispatch])

  useEffect(()=>{
    setData(commission)
  },[commission]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };
  

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteCommissionPlan(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{index + 1}</span>
      ),
    },
    {
      Header: "Coin",
      body: "upperAmount",
      sorting : {type : "client"},
    },
    {
      Header: "Slab",
      Cell: ({ row }) => <span>{row?.amountPercentage + " %"}</span>,
    },
    {
      Header: "Created At",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn textPink fs-5`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          // onClick={() => handleEdit(row)}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "commission" },
            });
          }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mainCommission ">
        <div className="comission primeHeader">
          <div className="comissionHeader ">
            <div className="row align-items-center">
              <div className="col-12">
                <Title name={`Commission`} />
              </div>
              <div className="col-6"></div>
              <div className="col-6 ms-auto mt-3">
                <Button
                  newClass={`border text-white userBtn`}
                  btnColor={`bg-primary`}
                  btnName={`New`}
                  btnIcon={`fas fa-plus fa-md`}
                  onClick={() => {
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: { type: "commission" },
                    });
                  }}
                  style={{
                    borderRadius: "5px",
                    marginLeft: "auto",
                    width: "120px",
                    float: "right",
                  }}
                />

                {dialogue && dialogueType === "commission" && <CommissionDialogue />}
              </div>
            </div>
          </div>
          <div className="commissionMain">
            <div className="tablemanin mt-3">
              <Table
                data={data}
                mapData={mapData}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            <div className="paginationFooter">
            <Pagination
              component="div"
              count={commission?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={commission?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null,{getCommissionPlan,updateCommissionPlan,deleteCommissionPlan})(Commission);
