import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./user.type";
import jwt_decode from "jwt-decode";

const initialState = {
  user: [],
  totalUser: null,
  userDetails: {},
  history: [],
  totalCoin: null,
  userCallHistory:[],
  Coin:null,
  userHistory:[]
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_USER:
      return {
        ...state,
        user: action.payload.data,
        totalUser: action.payload.totalUser,
      };

    case ActionType.USER_BLOCK:
      return {
        ...state,
        user: state.user.map((userBlock) => {
          if (userBlock._id === action.payload.id) return action.payload.data;
          else return userBlock;
        }),
        userDetails: action.payload.data,
      };

    //Get Plan Purchase User
    case ActionType.GET_PLAN_PURCHASE_USERS:
      return {
        ...state,
        user: action.payload.user,
        total: action.payload.total,
      };

    //Get single user by id
    case ActionType.GET_SINGLE_USERS:
      return {
        ...state,
        userDetails: action.payload,
      };

    //Update User Coin
    case ActionType.UPDATE_USER_COIN:
      return {
        ...state,
        user: action.payload.editUserCoin,
      };

    //Get User History
    case ActionType.GET_USER_CALL_HISTORY:
      return {
        ...state,
        userCallHistory: action.payload.history,
        totalCoin: action.payload.totalCoin ? action.payload.totalCoin : 0,
        totalCall: action.payload.total ? action.payload.total : 0,
      };

      case ActionType.GET_ALL_USER_HISTORY:
        
      return {
        ...state,
        userHistory: action.payload.history,
        totalCoin: action.payload.totalCoin ? action.payload.totalCoin : 0,
        totalUser: action.payload.total ? action.payload.total : 0,
      };
    default:
      return state;
  }
};
