// const Searching = (props) => {
//   const { data, setData, type, serverSearching } = props;

//   const handleSearch = (event) => {
//     let searchValue = event.target.value.toLowerCase();
//     if (type === "server") {
//       serverSearching(searchValue);
//     } else {
//       if (searchValue) {
//         const filteredData = data.filter((item) => {
//           return Object.keys(item).some((key) => {
//             if (key === "_id" || key === "updatedAt" || key === "createdAt") {
//               return false;
//             }
//             const itemValue = item[key];
//             if (typeof itemValue === "string") {
//               return itemValue.toLowerCase().indexOf(searchValue) > -1;
//             } else if (typeof itemValue === "number") {
//               return itemValue.toString().indexOf(searchValue) > -1;
//             }
//             return false;
//           });
//         });
//         setData(filteredData);
//       } else {
//         setData(data);
//       }
//     }
//   };

//   return (
//     <>
//       <div className="col-6 mx-2 " style={{ float: "right" }}>
//         <form action="" className="d-flex">
//           <div className="input-group ">
//             <input
//               type="search"
//               id="search"
//               placeholder="Searching for..."
//               aria-describedby="button-addon4"
//               className="form-control bg-none searchBar"
//               onChange={handleSearch}
//             />
//             <button className="themeBtn text-center fs-6 btn btn-primary ">
//               <i class="bi bi-search"></i> <span class="ms-1"></span>
//             </button>
//             {/* <button className="themeBtn text-center fs-6 btn btn-primary ">
//               <i className="bi bi-search"></i> <span class="ms-1"></span>
//             </button>
//             <Button
//               newClass={`fs-6 btn btn-primary`}
//               btnColor={``}
//               btnIcon={`bi bi-search`}
//             /> */}
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default Searching;

import { useState } from "react";
import Button from "./Button";

const Searching = (props) => {
  const [search, setSearch] = useState("");
  const {
    data,
    setData,
    type,
    serverSearching,
    setSearchData,
    button,
    newClass,
  } = props;

  const handleSearch = (event) => {
    event.preventDefault();

    let searchValue = search ? search : event?.target?.value?.toLowerCase();
    const getLowerCaseSearch = searchValue?.toLowerCase();

    if (getLowerCaseSearch !== undefined) {
      if (type === "client") {
        if (getLowerCaseSearch) {
          const filteredData = data.filter((item) => {
            return Object.keys(item).some((key) => {
              if (key === "_id" || key === "updatedAt" || key === "createdAt") {
                return false;
              }
              const itemValue = item[key];
              if (typeof itemValue === "string") {
                return itemValue.toLowerCase().indexOf(getLowerCaseSearch) > -1;
              } else if (typeof itemValue === "number") {
                return itemValue.toString().indexOf(getLowerCaseSearch) > -1;
              } else if (typeof itemValue === "object" && itemValue !== null) {
                // Check for nested object (agencyId) and handle nested properties
                return Object.values(itemValue).some((nestedValue) => {
                  if (typeof nestedValue === "string") {
                    return (
                      nestedValue.toLowerCase().indexOf(getLowerCaseSearch) > -1
                    );
                  }
                  return false;
                });
              }
              return false;
            });
          });
          setData(filteredData); // Update the filteredData state
        } else {
          setData(data); // Reset the filteredData state to the original data
        }
      } else {
        // For other types, implement the serverSearching function
        serverSearching(searchValue);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch(e);
    }
  };

  return (
    <>
      <div
        className="col-lg-12 col-xl-6 searching-box "
        style={{ float: "right" }}
      >
        <div
          className={`prime-input d-flex m-0 ${newClass}`}
          style={{ borderRadius: "6px" }}
        >
          <input
            type="search"
            autoComplete="false"
            placeholder="Searching for..."
            aria-describedby="button-addon4"
            className="form-input searchBarBorderr "
            onChange={(e) => {
              const inputValue = e.target.value;

              if (!inputValue) {
                handleSearch(e);
                setSearchData ? setSearchData("") : setData(data);
              }
              setSearch(inputValue);
            }}
            onKeyPress={handleKeyPress}
          />
          <Button
            type="button"
            btnIcon={`bi bi-search`}
            newClass={`themeBtn text-center fs-6 bg-primary searchBtn text-white btn-primary`}
            onClick={(e) => handleSearch(e)}
          />
        </div>
      </div>
    </>
  );
};

export default Searching;
