import React, { useEffect, useRef, useState } from "react";
import Male from "../../../../assets/images/men.png";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { baseURL } from "../../../util/config";
import {
  fetchOldSystemChats,
  fetchThumbList,
} from "../../../store/host/host.action";
import axios from "axios";
import Title from "../../../extra/Title";

const ChatPage = () => {
  const { oldChats, thumbList, loading, error, start, limit } = useSelector(
    (state) => state.host
  );

  const { admin } = useSelector((state) => state.admin);
  const [chatValue, setChatValue] = useState("");
  const [image, setImage] = useState(null);
  const [url, setURL] = useState("");
  const [messageValue, setMessageValue] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [isActive, setIsActive] = useState(0); // Track active chat index
  const [selectedChat, setSelectedChat] = useState(); // Track selected chat

  const hostId = selectedChat?.host?._id;

  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const adminId = admin?._id;


  useEffect(() => {
    dispatch(fetchThumbList(start, limit));
  }, []);

  useEffect(() => {
    if (thumbList.length > 0 && !selectedChat) {
      setSelectedChat(thumbList[0]); // Set selectedChat to the first item in thumbList
    }
  }, [thumbList]);

  useEffect(() => {
    if (adminId && hostId) {
      dispatch(fetchOldSystemChats(adminId, hostId)); // Fetch chats for the selected host
    }
  }, [selectedChat, adminId, hostId]);

  useEffect(() => {
    setMessageValue(oldChats?.message);
  }, [oldChats, hostId]);

  const handleTypeHere = (e) => {
    setChatValue(e.target.value);
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const filteredChats = thumbList.filter((chatList) => {
    const isNameMatch = chatList?.host?.name
      ?.toLowerCase()
      .includes(searchItem.toLowerCase());
  
    const isMessageMatch = chatList?.message?.some((msg) =>
      msg?.text?.toLowerCase().includes(searchItem.toLowerCase())
    );
  
    // Return true if either name or message matches
    return isNameMatch || isMessageMatch;
  });
  

  // Here Socket is Connected :-

  useEffect(() => {
    if (!socketRef.current && adminId) {
      socketRef.current = io(baseURL, {
        transports: ["websocket", "polling"],
        query: {
          adminRoom: adminId,
        },
      });

      socketRef.current.on("connect", () => {
        socketRef.current.emit("joinRoom", { adminRoom: adminId });
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.on("disconnect", () => {
          console.log("Disconnected from the server");
        });

        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [adminId]);


  // Message From Multiple Host :-

  useEffect(() => {
    if (socketRef?.current) {
      const handleNewMessage = (newMessage) => {
        if (!newMessage.hostId) {
          console.error("Invalid hostId in new message:", newMessage);
          return;
        }

        // If the new message is from the currently selected chat, update messageValue
        // if (newMessage.hostId === hostId) {
        //   setMessageValue((prev) => [...prev, newMessage]);
        // }
      };

      socketRef.current.on("sytemChatMessageForAdmin", handleNewMessage);

      return () => {
        socketRef.current?.off("sytemChatMessageForAdmin", handleNewMessage);
      };
    }
  }, [selectedChat]);

  const handleSendMessage = (e) => {
    e.preventDefault();

    const messageData = {
      personType: 2,
      adminId: adminId,
      hostId: hostId,
      messageType: image ? 2 : 1,
      headingType: 0,
      text: chatValue ? chatValue : "",
      image: image ? url : "",
    };

    // Emit the message via socket.io
    if (socketRef.current) {
      socketRef.current.emit("sytemChatMessageForAdmin", messageData);
    }
    setMessageValue((prev) => [...prev, messageData]);
    setChatValue("");
    setImage(null);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(
        "/admin/systemChat/handleChatImage",
        formData
      );
      const getUrl = response.data;
      setURL(getUrl?.imageUrl);
    } catch (error) {
      console.error("Error occurred while uploading the image:", error);
    }

    if (file) {
      setImage(file);
    }
  };

  const handleFilePicker = () => {
    document.getElementById("fileInput").click();
  };

  const handleChatListActive = (index, chat) => {
    setIsActive(index);
    setSelectedChat(chat); // Ensure this correctly sets the selected chat host
  };

  return (
    <div className="chat-page">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="section chats">
          <Title name={`Chats`} />
          <div className="search-bar mt-2 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search ChatsList..."
              value={searchItem}
              onChange={handleSearch}
            />
          </div>
          {filteredChats.map((chatList, i) => {
            const lastMessage =
              chatList?.message?.slice(-1)[0]?.text || "No messages available";

            const truncateMessage = (text) => {
              const words = text.split(" ");
              return words.length > 10
                ? words.slice(0, 3).join(" ") + "..."
                : text;
            };

            return (
              <div
                className={isActive === i ? "chat-itemActive" : "chat-item"}
                key={i}
                onClick={() => handleChatListActive(i, chatList)} // Update selected chat
              >
                {/* Check if the profile picture is a valid image URL */}
                {(() => {
                  const regex = /https?:\/\/[^\\]*\\.*\.(jpg|png|jpeg|gif)/;
                  const isImage = regex.test(chatList?.host?.profilePic);
                  return (
                    <img
                      src={isImage ? Male : chatList?.host?.profilePic  } // Use Male as fallback if it's not an image
                      alt="User"
                      className="avatar"
                    />
                  );
                })()}

                <div className="chat-details">
                  <h6
                    className={isActive === i ? "text-white" : "text-black"}
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    {chatList?.host?.name}
                  </h6>
                  <p className={isActive === i ? "text-white" : "text-black"}>
                    {truncateMessage(lastMessage)}
                  </p>
                </div>
              </div>

            );
          })}
        </div>
      </div>

      <div className="chat-area">
        {selectedChat && (
          <>
            <div className="chat-header sticky-header">
              <div className="user-info">
                {/* Check if the profile picture is a valid image URL */}
                {(() => {
                  const regex = /https?:\/\/[^\\]*\.(jpg|png|jpeg|gif)/;
                  const isImage = regex.test(selectedChat?.host?.profilePic);

                  return (
                    <img
                      src={isImage ? selectedChat?.host?.profilePic : Male} // Use Male as fallback if it's not an image
                      alt="User"
                      className="avatar"
                    />
                  );
                })()}
                <div className="mt-2">
                  <h6 className="mb-0">{selectedChat?.host?.name}</h6>
                </div>
              </div>
              <div className="chat-actions">
                <i className="icon-settings"></i>
                <i className="icon-video"></i>
              </div>
            </div>


            <div className="chat-messages mt-5">
              {messageValue?.map(
                (message, i) => (
                  (
                    <div
                      className={`message ${
                        message.personType === 1 ? "received" : "sent"
                      }`}
                      key={i}
                    >
                      <div className="d-flex align-items-start ml-2">
                        {message.personType === 1 ? (
                          // For received messages
                          <>
                            {message?.image ? (
                              <img
                                src={
                                  typeof message.image === "string" &&
                                  message.image.includes("/storage\\")
                                    ? Male
                                    : message.image || Male
                                }
                                alt="User"
                                className="mb-2"
                                height={150}
                                width={150}
                              />
                            ) : (
                              <p className="chat-submessage text-white">
                                {message.text}
                              </p>
                            )}
                          </>
                        ) : (
                          // For sent messages
                          <>
                            {message?.image ? (
                              <img
                                src={
                                  typeof message.image === "string" &&
                                  message.image.includes("/storage\\")
                                    ? Male
                                    : message.image || Male
                                }
                                alt="User"
                                className="mb-2"
                                height={150}
                                width={150}
                              />
                            ) : (
                              <div>
                                {message?.heading ? (
                                  <p className="chat-submessage text-white">
                                    {" "}
                                    {message.heading && (
                                      <span className="text-white">
                                        {" "}
                                        {message.heading &&
                                          message.heading}{" "}
                                      </span>
                                    )}
                                    <br></br>
                                    {message.text}
                                  </p>
                                ) : (
                                  <p className="chat-submessage text-white">
                                    {message.text}
                                  </p>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )
                )
              )}
            </div>

            {image && (
              <div style={{ display: "flex", justifyContent: "end" }}>
                <img
                  src={url.includes("/storage\\") ? Male : url}
                  alt="Image"
                  height={100}
                  width={100}
                />
              </div>
            )}

            <form className="stickyposition"  onSubmit={handleSendMessage}>
              <div className="chat-input sticky-headerbottom">
                <input
                  type="text"
                  placeholder="Type your message here..."
                  className="form-control border-0 shadow-none"
                  value={chatValue}
                  onChange={handleTypeHere}
                />
                <i
                  className="bi bi-paperclip mx-2"
                  style={{ fontSize: "20px" }}
                  onClick={handleFilePicker}
                ></i>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  webkitdirectory
                  directory
                />

                <button
                  className="btn d-flex align-items-center text-white ps-3 pe-3"
                  style={{
                    backgroundColor: "#191919",
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSendMessage();
                    }
                  }}
                >
                  Send
                  <i
                    className="bi bi-send ms-1 text-white"
                    style={{
                      fontSize: "13px",
                      marginLeft: "10px",
                    }}
                  ></i>
                </button>
              </div>
            </form>

          </>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
