// GET_USER
export const GET_USER = "GET_USER";
export const USER_BLOCK = "USER_BLOCK";
//  get Plan Purchase users
export const GET_PLAN_PURCHASE_USERS = "GET_PLAN_PURCHASE_USERS";

//  get single user PROFILE
export const GET_SINGLE_USERS = "GET_SINGLE_USERS";

// get user history
export const GET_USER_CALL_HISTORY = "GET_USER_CALL_HISTORY";

export const GET_ALL_USER_HISTORY = "GET_ALL_USER_HISTORY";

// Update User Coin IN PROFILE
export const UPDATE_USER_COIN = "UPDATE_USER_COIN";




