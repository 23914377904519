import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./admin.type";
import jwt_decode from "jwt-decode";

const initialState = {
  admin: {},
  isAuth: false,
};

export const adminReducer = (state = initialState, action) => {
  let decode;
  switch (action.type) {
    // case ActionType.SET_ADMIN:
    //   if (action.payload) {
    //     decode = jwt_decode(action.payload);
    //   }
    //   setToken(action.payload);
    //   SetDevKey(key);
    //   localStorage.setItem("token", action.payload);
    //   localStorage.setItem("Key", key);

    //   return {
    //     ...state,
    //     isAuth: true,
    //     admin: decode,
    //   };

    case ActionType.LOGIN_ADMIN:
      if (action.payload) {
        decode = jwt_decode(action.payload);
      }
      // Set Token And Key In Axios
      setToken(action.payload);
      SetDevKey(key);
      // Set Token And Key In Session
      localStorage?.setItem("token", action?.payload);
      localStorage?.setItem("key", key);
      localStorage?.setItem("isAuth", true);
      return {
        ...state,
        admin: decode,
        isAuth: true,
      };

    case ActionType.UNSET_ADMIN:
      window.localStorage.clear();
      window.localStorage.clear();
      SetDevKey(null);
      setToken(null);
      return {
        ...state,
        isAuth: false,
        admin: {},
      };
    // case SEND_EMAIL:
    //   return {
    //     ...state,
    //     admin: action.payload,
    //   };

    case ActionType.UPDATE_PROFILE:
      
      return {
        ...state,
        admin: {
          ...state.admin,
          _id: action?.payload?._id,
          name: action?.payload?.name,
          email: action?.payload?.email,
          image: action?.payload?.image,
          flag: action?.payload?.flag,
          fcm_token: action?.payload?.fcm_token,
        },
      };

    case ActionType.LOGOUT_ADMIN:
      localStorage?.removeItem("key", key);
      localStorage?.removeItem("token", setToken);
      localStorage?.removeItem("isAuth", false);
      setToken(null);
      SetDevKey(null);
      return {
        ...state,
        admin: {},
        isAuth: false,
      };
    default:
      return state;
  }
};
