import React, { useState } from "react";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Chip, ListItem, Paper } from "@mui/material";
import Input from "./Input";
import Button from "./Button";

export default function TopicList(props) {
  const {
    label,
    handleAddData,
    handleDeleteChip,
    setAddChipData,
    addChipData,
    addChip,
    disabled
  } = props;

  return (
    <div className="topic-list-chip">
      <div className="row">
        <div className="col-11 topicFontSize">
          <Input
            label={label}
            id={`label`}
            type={`text`}
            placeholder={`Add Topic...!`}
            value={addChipData}
            onChange={(e) => {
              setAddChipData(e.target.value);
            }}
            style={{ borderRadius: "20px" }}
          />
        </div>
        <div className="col-1 my-auto topicBTN cursor-pointer">
          <Button
            onClick={handleAddData}
            btnIcon={`fas fa-plus fa-md`}
            newClass={"add-detail-btn text-white p-4 cursor-pointer"}
            type={"button"}
            disabled={disabled} 
          />
        </div>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              marginTop: "20px",
              marginBottom: "20px",
            }}
            component="div"
            style={{
              overflow: "scroll",
              maxHeight: "170px",
              minHeight: "170px",

              background: "none",
              borderRadius: "12px",
            }}
          >
            {addChip?.length > 0 && addChip?.map((data, index) => {
              let icon;

              if (data.label === "React") {
                icon = <TagFacesIcon />;
              }
              return (
                <Chip
                  variant="outlined"
                  color="success"
                  icon={icon}
                  label={data}
                  key={index}
                  onDelete={() => handleDeleteChip(index)}
                />
              );
            })}
          </Paper>
      </div>
    </div>
  );
}
