import React, { useMemo } from "react";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import dayjs from "dayjs";
import {
  getMultiAppSetting,
  deleteMultiAppSetting,
  isMultiAppActive,
} from "../../store/setting/setting.action";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";

import { warning } from "../../util/Alert";
import MultiAppSetting from "./multiAppSetting/MultiAppSetting";
import { EMPTY_APP_WISE_SETTING } from "../../store/setting/setting.type";
import { baseURL } from "../../util/config";
import Male from "../../../assets/images/male.jpg"
const AppWiseSetting = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("ALL");
  const [type, setType] = useState(0);
  const dispatch = useDispatch();

  const { multiAppSetting } = useSelector((state) => state.setting);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    setData(multiAppSetting);
  }, [multiAppSetting]);

  useEffect(() => {
    dispatch(getMultiAppSetting());
  }, [dispatch]);

  const handleEditData = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: { data: row, type: type },
    });
  };
  const openLogo = (url) => {
    window.open(url, "_blank");
  };

  const mapData = [
    {
      Header: "NO",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Logo",
      Cell: ({ row }) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            openLogo(row?.logoType == 0 ? baseURL + row?.logo : row?.logo)
          }
        >
          <div className="px-2 py-1">
            <img
              src={row?.logoType == 0 ? baseURL + row?.logo : row?.logo ? row?.logoType == 0 ? baseURL + row?.logo : row?.logo : Male}
              alt="Logo"
              draggable="false"
              style={{ borderRadius: "25px" }}
              height={50}
              width={50}
              onError={(e) => e.target.src = Male}
            />
          </div>
        </div>
      ),
    },
    {
      Header: "App Name",
      body: "appName",
    },
    {
      Header: "Privacy Policy Link",
      body: "privacyPolicyLink",
      Cell: ({ row }) => (
        <span className="text-success">
          {row?.privacyPolicyLink ? row?.privacyPolicyLink : "-"}
        </span>
      ),
    },
    {
      Header: "T & C Link",
      body: "termAndCondition",
    },
    {
      Header: "Package Name",
      body: "packageName",
      Cell: ({ row }) => <span>{row?.packageName}</span>,
    },
    {
      Header: "App Version",
      body: "appVersion",
      Cell: ({ row }) => <span>{row?.appVersion}</span>,
    },
    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "isAppActive",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isAppActive}
          onClick={() => handleClickBlock(row._id, "appActive")}
        />
      ),
    },
    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn textPink fs-5`}
          btnIcon={`far fa-edit`}
          // onClick={() => handleEdit(row)}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
          onClick={() => {
            handleEditData(row, "MultiApp");
          }}
        />
      ),
    },
    {
      Header: "Delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteMultiAppSetting(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClickBlock = (id, type) => {
    props.isMultiAppActive(id, type);
  };

  const handleNewOpen = () => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: { type: "MultiApp" },
    });
    dispatch({ type: EMPTY_APP_WISE_SETTING });
  };
  return (
    <>
      {dialogue && dialogueType === "MultiApp" && <MultiAppSetting />}
      <div
        className="mainUserTable  position-relative"
        style={{
          display: `${dialogueType === "MultiApp" ? "none" : "block"}`,
        }}
      >
        <div className="userHeader primeHeader">
          <Title name={`Multi App Setting`} />
        </div>
        <div className="primeHeader mt-4 row">
          {/* <i className="fas fa-plus fa-md"></i> */}
          <div className="col-md-6 mt-2">
            <Button
              newClass={` border bgsuccess text-white`}
              btnColor={`btnthemePrime`}
              btnIcon={`fas fa-plus fa-md`}
              btnName={`New App`}
              onClick={() => handleNewOpen()}
              style={{ borderRadius: "7px" }}
            />
          </div>
        </div>

        <div className="userMain">
          <div className="tableMain mt-3">
            <Table data={data} mapData={mapData} type={"server"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getMultiAppSetting,
  deleteMultiAppSetting,
  isMultiAppActive,
})(AppWiseSetting);
