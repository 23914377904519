import axios from "axios";
import * as ActionType from "./reward.type";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getReward = (search) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/rewardLevel/fetchRewardLevelByAdmin`)
    .then((res) => {
      
      if (res.staus === true) {
        dispatch({
          type: ActionType.GET_REWARD_LEVEL,
          payload: res.rewardLevel,
        });
        // setToast("success", res.message);
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const createReward = (data) => (dispatch) => {
  axios
    .post(`admin/rewardLevel/createRewardLevel`, data)
    .then((res) => {
          
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_REWARD_LEVEL,
          payload: res.data.rewardLevel,
        });
        setToast("success", "Reward Created successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

// Update Coin PLan
export const updateReward = (data, id) => (dispatch) => {
  
  axios
    .patch(`admin/rewardLevel/updateRewardLevel`, data)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_REWARD_LEVEL,
          payload: { data: res.data.rewardLevel, id },
        });
        setToast("success", "Reward updated successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};






