import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getHostFeedBack,getUserFeedBack } from "../../store/feedback/feedback.action";
import NoImage from "../../../assets/images/noimage.png"
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import  { baseURL } from "../../util/config";
const FeedBackDialog = (props) => {

  const { dialogue, dialogueData } = useSelector((state) => state.dialogue);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [type,setType] =  useState("");
  const [screenshot, setDScreenshot] = useState([]);
  const [solved,setSolved] = useState();

 
  

  useEffect(()=>{
   setName(dialogueData.name);
   setContact(dialogueData.contact);
   setDescription(dialogueData.description);  
  setType(<span>{dialogueData.type == 1? "Recharge" :dialogueData.type == 2? "Gifting" : dialogueData.type == 3? "Stream" :dialogueData.type == 4? "Events" :dialogueData.type == 5? "Suggestions" : "Others"}</span>)
  setDScreenshot(dialogueData.screenshot)
  // setSolved(<span className="text-success">{dialogueData.isSolved == true ? "Solved" : "Pending"  }</span>)
  setSolved(
    dialogueData.isSolved === true ? (
      <span className="text-success fw-bold">Solved</span>
    ) : (
      <span className="text-danger fw-bold">Pending</span>
    )
  );
},[dialogueData])
 const showImage = (openImage)=>{
  window.open(openImage , "_blank")
 }
  
  const dispatch = useDispatch();

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue" style={{width:"450px",overflow:'auto',maxHeight:"100vh"}} >
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Feedback Info</div>
          <div
            className="closeBtn "
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i className="fa-solid fa-xmark ms-2"></i>
          </div>
        </div>
        <div className="dialogueMain" style={{overflow:'auto',maxHeight:"100vh"}}>
          <div className="feedName d-flex mb-3">
            <div className="fw-bolder" style={{width:"160px"}}>Name</div>
            <div className="">:{ "     " + name}</div>
          </div>
          <div className="d-flex mb-3 align-items-center">
            <div className="fw-bolder" style={{width:"160px"}}>Screenshot</div> :
         <img src={screenshot ? baseURL + screenshot : NoImage} className="ms-3 cursor" onClick={()=>showImage(baseURL + screenshot)} style={{width:"70px",height:"70px",borderRadius:"5px",objectFit:"cover"}}/> 
          </div>
          <div className="d-flex mb-3">
            <div className="fw-bolder" style={{width:"160px"}}>Contact</div>
            <span>:{ "     " +contact}</span>
          </div>
          <div className="d-flex mb-3 w-100" >
            <div className="fw-bolder" style={{width:"160px"}}>Description</div> :
            <span className="overflow-auto description ps-2"style={{width:"auto" ,height:"50px"}}>  <span>{description}</span> </span>
          </div>
          <div className="d-flex mb-3">
            <div className="fw-bolder" style={{width:"160px"}}>Feedback Type</div>
            <span>:{"     "}{type}</span>
          </div>
          <div className="d-flex mb-3">
            <div className="fw-bolder" style={{width:"160px"}}>Feedback Status</div>
            <span style={{maxWidth:"100%",overflow:"auto"}}>:{"     "}{solved}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null,{getHostFeedBack,getUserFeedBack})(FeedBackDialog);
