import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../util/Alert";
import Button from "../../extra/Button";
import { createAgency, updateAgency } from "../../store/agency/agency.action";
import { hostCountry } from "../../store/host/host.action";
import { Typography } from "@mui/material";
import Select from "react-select";
import cancel from "../../../assets/images/cancel.png";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "1px solid var(--inputBorder--)",
    borderRadius: "6px 0 0 6px",
    padding: "16px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#dee2e694",
  }),
};
const AgencyDialog = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const { country1 } = useSelector((state) => state.host);

  const [mongoId, setMongoId] = useState(0);
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState({
    value: "",
    label: "",
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState({
    name: "",
    image: "",
    mobileNo: "",
    password: "",
    code: "",
    email: "",
    country: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hostCountry());
  }, [dispatch]);


  useEffect(() => () =>  {
    if (dialogueData == null) {
      setMongoId("");
      setName("");
      setMobileNo("");
      setCode("");
      setPassword("");
      setImagePath("");
      setEmail("");
      setCountry({ value: "647edf4a693e0fdc4a7705ff", label: "India" });
    }
  }, []);

  useEffect(() => {
    if (dialogueData) {
      
      setMongoId(dialogueData?._id);
      setName(dialogueData?.name);
      setMobileNo(dialogueData?.mobileNo);
      setCode(dialogueData?.code);
      setPassword(dialogueData?.password);
      setImagePath(dialogueData?.image);
      setEmail(dialogueData?.email);
      setCountry({
        value: dialogueData?.countryId?._id,
        label: dialogueData?.countryId?.name,
      });
    }
  }, [dialogueData]);

  const handleImage = (e) => {
    // BOTH ARE WORKING STEP : 1
    // if (e.target.files[0] == "") {
    //     setError({
    //       ...error,
    //       image: "Image require",
    //     });

    // } else {
    //      setError({
    //        ...error,
    //        image: "",
    //      })
    // }

    // BOTH ARE WORKING STEP : 2

    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    if (
      !name ||
      !image ||
      !password ||
      !code ||
      !mobileNo ||
      !imagePath ||
      !email ||
      !country
    ) {
      let error = {};
      if (!name) error.name = "Name is required !";
      if (!password) error.password = "Password is required !";
      if (!code) error.code = "Code is Required !";
      if (!mobileNo) error.mobileNo = "Mobile Number is required !";
      if (!image || !imagePath) error.image = "Image is required!";
      if (!email) error.email = "Email is Required!";
      if (!country) error.country = "Select Country!";

      return setError({ ...error });
    } else {
      const mobileNoValid = isNumeric(mobileNo);
      const EmailValid = isEmailValid(email);
      if (!mobileNoValid) {
        return setError({
          ...error,
          mobileNo: "Mobile number must be ten digit!",
        });
      } else if (!EmailValid) {
        return setError({
          ...error,
          email: "Invalid email id",
        });
      } else {
        //   if (!hasPermission) return permissionError();
        const countries = country?.value;
        const formData = new FormData();
        formData.append("name", name);
        formData.append("image", image);
        formData.append("code", code);
        formData.append("password", password);
        formData.append("mobileNo", mobileNo);
        formData.append("email", email);
        formData.append("countryId", countries);

        if (mongoId) {
          props.updateAgency(formData, mongoId);
        } else {
          props.createAgency(formData);
        }
        dispatch({ type: CLOSE_DIALOGUE });
      }
    }
  };

  const options = country1.map((countryData) => ({
    value: countryData._id,
    label: countryData.name,
  }));

  useEffect(() => {
    // Set the initial country value after the component mounts
    const initialCountryData = country1.find(
      (country1) => country1._id === country.value
    );

    if (initialCountryData) {
      setCountry({
        value: initialCountryData._id,
        label: initialCountryData.name,
      });
    }
  }, [country1]);

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d{10}$/.test(val);
    return validNumber;
  };

  const isEmailValid = (value) => {
    const val = value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
    return emailPattern;
  };

  const handleCreateCode = () => {
    let generateRandomCode = Math.floor(Math.random() * 900000) + 100000;
    setCode(generateRandomCode.toString());

    setError((prevErrors) => ({
      ...prevErrors,
      code: "",
    }));
  };

  const showImg = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="mainDialogue fade-in">
      <div
        className="Dialogue"
        style={{ overflow: "auto", maxHeight: "100vh" }}
      >
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">
            {dialogueData ? "Update Agency" : "Create Agency"}
          </div>
          <div
            className="closeBtn closeDialogue text-center"
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
            {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}
         
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row g-0">
            <div className="col-md-6 col-sm-12">
              <Input
                label={`Agency Name`}
                id={`name`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12 ps-md-2 ps-sm-0">
              <Input
                label={`Agency Password`}
                id={`password`}
                type={`password`}
                value={password}
                errorMessage={error.password && error.password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      password: `Password is required!`,
                    });
                  } else {
                    return setError({
                      ...error,
                      password: "",
                    });
                  }
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <Input
                label={`Mobile Number`}
                id={`mobileNo`}
                type={`number`}
                value={mobileNo}
                errorMessage={error.mobileNo && error.mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      mobileNo: `Mobile number Is Required!`,
                    });
                  } else {
                    return setError({
                      ...error,
                      mobileNo: "",
                    });
                  }
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12 ps-md-2 ps-sm-0">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="form-group mb-0">
                  <p
                    className="form-label fs-6 fw-semRbold"
                    style={{ marginBottom: "5px" }}
                  >
                    Code
                  </p>
                </div>
                <div class="input-group mb-4">
                  <div
                    class="input-group"
                    id="code-toggle"
                    style={{ height: "48px" }}
                  >
                    {!dialogueData && (
                      <a
                        href={() => false}
                        class="input-group-text"
                        style={{ cursor: "pointer", height: "100%" }}
                      >
                        <i
                          id="showPassword"
                          class="fas fa-info-circle "
                          aria-hidden="true"
                          onClick={handleCreateCode}
                        ></i>
                      </a>
                    )}
                    <Input
                      type={`number`}
                      newClass={`${dialogueData ? "w-100" : "w83"}`}
                      className={`form-control form-input `}
                      readOnly={dialogueData && `readOnly`}
                      fieldClass={`${!dialogueData && "rounded-0"} `}
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                        if (!e.target.value)
                          return setError({
                            ...error,
                            code: "Code Is Required !",
                          });
                        else {
                          return setError({
                            ...error,
                            code: "",
                          });
                        }
                      }}
                      // onKeyPress={handleKeyPress}
                    />
                  </div>
                  {error.code && (
                    <div class="pl-1 text-left errorMessage">{error.code}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <Input
                label={`Email`}
                id={`email`}
                type={`email`}
                value={email}
                errorMessage={error.email && error.email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      email: `Email is required!`,
                    });
                  } else {
                    return setError({
                      ...error,
                      email: "",
                    });
                  }
                }}
              />
            </div>
            {/* <div className="col-md-6 col-sm-12 ps-md-2 ps-sm-0">
              <div className="form-group">
                <h6 className="fs-6">Country</h6>
                <>
                  <select
                    className=" form-input px-2 py-2"
                    aria-label="Default select example"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                      if (e.target.value === "SelectCountry") {
                        return setError({
                          ...error,
                          country: "Please select country!",
                        });
                      } else {
                        return setError({
                          ...error,
                          country: "",
                        });
                      }
                    }}
                  >
                    <option value="">Select Country</option>
                    {country1?.map((item) => {
                      return item.name ? (
                        <option
                         
                          value={item?._id}
                        >
                          {item?.name}
                        </option>
                      ) : (
                        ""
                      );
                    })}
                  </select>
                  {error.country && (
                    <div className="ml-2 mt-1">
                      {error.country && (
                        <p className="text-error">{error.country}</p>
                      )}
                    </div>
                  )}
                </>
              </div>
            </div> */}
            <div className="col-md-6 col-sm-12 ps-md-2 ps-sm-0">
              <div>
                <h6 className="fs-6 m-0F">Country</h6>

                <Select
                  value={country}
                  options={options}
                  styles={customStyles}
                  onChange={(selectedOption) => {
                    setCountry(selectedOption);
                    // Handle error
                  }}
                />
                <>
                  {/* <Select
                    value={country.value}
                    defaultValue={country}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption);
                      setError((prevError) => ({
                        ...prevError,
                        country: "",
                      }));
                    }}
                    options={options}
                  /> */}
                  {!selectedOption?.value && (
                    <div className="ml-2 mt-1">
                      <p className="errorMessage">{error.country}</p>
                    </div>
                  )}
                </>
              </div>
            </div>

            <div className="col-12">
              <Input
                label={`Agent Image`}
                id={`image`}
                type={`file`}
                onChange={(e) => handleImage(e)}
                errorMessage={error.image && error.image}
              />
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath}
                    alt="banner"
                    draggable="false"
                    width={100}
                    height={100}
                    className="m-0 cursor rounded"
                    onClick={() => showImg(imagePath)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white `}
                newClass={`closeDialogue`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createAgency, updateAgency })(AgencyDialog);
