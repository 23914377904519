import axios from "axios";
import * as ActionType from "./income.type";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getIncome = (search) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/incomeLevel/fetchIncomeLevelByAdmin?search=${search}`)
    .then((res) => {
      if (res.staus === true) {
        dispatch({
          type: ActionType.GET_INCOME_PLAN,
          payload: res.incomeLevel,
        });
        // setToast("success", res.message);
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const createIncome = (data) => (dispatch) => {
  axios
    .post(`admin/incomeLevel/createIncomeLevel`, data)
    .then((res) => {
        
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_INCOME_PLAN,
          payload: res.data.incomeLevel,
        });
        setToast("success", "IncomePlan Create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

// Update Coin PLan
export const updateIncomePlan = (data, id) => (dispatch) => {
  axios
    .patch(`admin/incomeLevel/updateIncomeLevel`, data)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_INCOME_PLAN,
          payload: { data: res.data.incomeLevel, id },
        });
        setToast("success", "Income Level updated successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};






