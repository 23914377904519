import * as ActionType from "./commission.type";


const initialState = {
    commission: [],
    total: null,

};

export const commissionReducer = (state = initialState,action) =>{ 
    switch (action.type){
        case ActionType.GET_COMMISSION:
            return{
                ...state,
                commission: action.payload,
            };

        case ActionType.ADD_COMMISSION:
            
            let data = [...state.commission];
            data.unshift(action.payload);
            return{
                ...state,
                commission: data,
            }  
            
        case ActionType.EDIT_COMMISSION:
            return {
                ...state,
                commission: state.commission.map((data) =>
                data._id === action.payload.id ? action.payload.data : data
                ),

            }   
        
        case ActionType.DELETE_COMMISSION:
            return {
                ...state,
                commission: state.commission.filter((data) => 
                data._id !== action.payload && data
                ),
            };

            default:
                return state;
    }
}