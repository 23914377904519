import * as ActionType from "./feedback.type";

const initialState = {
  userFeedBack: [],
  hostFeedBack: [],
  total:null,
  dialog: false,
  dialogData: null,
};

export const feedBackReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Feedback
    
    case ActionType.GET_USER_FEEDBACK: 
      return {
        ...state,
        userFeedBack: action.payload.data,
        total: action.payload.total,

      };

      case ActionType.GET_HOST_FEEDBACK: 
        return {
          ...state,
          hostFeedBack: action.payload.data,
          total: action.payload.total,
        };
    //Feedback Solved Switch
    case ActionType.FEEDBACK_SOLVED: 
      return {
        ...state,
        userFeedBack: state.userFeedBack.filter(
          (solvedFeedBack) =>
            solvedFeedBack._id !== action.payload.id && solvedFeedBack
        ),
        hostFeedBack: state.hostFeedBack.filter(
            (solvedFeedBack) =>
              solvedFeedBack._id !== action.payload.id && solvedFeedBack
          ),

      };
    //Feedback Delete
    case ActionType.FEEDBACK_DELETE: 
      return {
        ...state,
        userFeedBack: state.userFeedBack.filter((feedBack) => feedBack._id !== action.payload),
        hostFeedBack: state.hostFeedBack.filter((feedBack) => feedBack._id !== action.payload),
      };

    //Open Feedback Dialog
    case ActionType.OPEN_FEEDBACK_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    //Close Feedback Dialog
    case ActionType.CLOSE_FEEDBACK_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};
