import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { createTopic, editTopic } from "../../store/topic/topic.action";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "1px solid var(--inputBorder--)",
    borderRadius: "6px 0 0 6px",
    padding: "16px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#dee2e694",
  }),
};

const TopicDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const [mongoId, setMongoId] = useState("");

  const [topic, setTopic] = useState("");

  const [type, setType] = useState(1);

  const [errors, setError] = useState({
    topic: "",
    type: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    
    if (dialogueData) {
      setMongoId(dialogueData._id);
      setTopic(dialogueData.topic);
      setType(dialogueData?.type);
    }
  }, [dialogueData]);
  useEffect(
    () => () => {
      setError({
        topicName: "",
        topic: "",
      });
      setMongoId("");
      setTopic("");
      setType("");
    },
    [dialogue]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!topic) {
      const errors = {};

      if (!topic) errors.topic = "Topic is required!";

      return setError({ ...errors });
    }

    
    const data = {
      topic,
      type :type ? type :1 
    };
    // if (!hasPermission) return permissionError();
    if (mongoId) {
      props.editTopic(mongoId, data);
    } else {
      props.createTopic(data);
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            {dialogueData ? "Update Topic" : "Create Topic"}
            <div className="headerTitle fw-bold"> </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div
            className="dialogueMain"
            style={{ overflow: "auto", maxHeight: "100vh" }}
          >
            <div className="row">
              <div className="col-12">
                <Input
                  label={`Topic`}
                  id={`topic`}
                  type={`text`}
                  value={topic}
                  errorMessage={errors.topic && errors.topic}
                  onChange={(e) => {
                    setTopic(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        topic: `Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...errors,
                        topic: "",
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 form-group">
                <div className="form-group">
                  <label className="text-gray mb-2">Topic Type</label>
                  <select
                    class="form-input"
                    aria-label="Default select example"
                    value={type}
                    onChange={(e) => {
                      setType(parseInt(e.target.value));
                    }}
                  >
               
                    <option value={1} >
                      Interested Topics
                    </option>
                    <option value={2}>I want you're</option>
                    <option value={3}>Describe myself</option>
                    <option value={4}>more information</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="dialogueFooter">
              <div className="dialogueBtn">
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={() => {
                    dispatch({ type: CLOSE_DIALOGUE });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createTopic, editTopic })(TopicDialogue);
