import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import Analytics from "../../extra/Analytics";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import male from "../../../assets/images/male.jpg";
import { getAgencyHitory } from "../../store/agency/agency.action";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Searching from "../../extra/Searching";

const AgencyHistory = (props) => {
  const { agencyHistory, totalData, totalCoin } = useSelector(
    (state) => state.agency
  );

  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goPrevious = () => {
    state ? navigate(-1) : dispatch({ type: CLOSE_DIALOGUE });
    localStorage.removeItem("dialogueData");
  };
  useEffect(() => {
    dispatch(getAgencyHitory(state?._id ? state?._id : dialogueData._id,startDate,endDate,search));
  }, [dialogueData, state, startDate, endDate, search]);

  useEffect(() => {
    setData(agencyHistory);
  }, [agencyHistory]);

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", goPrevious);
    return () => {
      window.removeEventListener("popstate", goPrevious);
    };
  }, []);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });
  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };
  const historyTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    // { Header: "Code", body: "uniqueId", class: "fw-bold fs-6" },
    { Header: "Name", body: "name", class: "fw-bold fs-6" },

    {
      Header: "Country",
      body: "country",

      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.country == "" ? "india" : row?.country}
        </span>
      ),
    },
    {
      Header: "Host Coin",
      body: "coin",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-warning">{row?.coin}</span>
      ),
    },
    {
      Header: "Gift Coin",
      body: "giftHistory",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-danger">
          {!row?.giftCoin ? 0 : row?.giftCoin}
        </span>
      ),
    },
    {
      Header: "Video Call Coin",
      body: "callCoin",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-info">
          {!row?.callCoin ? 0 : row?.callCoin}
        </span>
      ),
    },
    {
      Header: "Admin Coin",
      body: "adminCoin",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-dark">
          {!row?.adminCoin ? 0 : row?.adminCoin}
        </span>
      ),
    },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainPlanTable ">
        <div className="planTable ">
          <div className="primeHeader ">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={"Agency History"} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <div className="profileBtn d-flex align-items-center">
                  <button
                    className="btn btn-primary btn-icon px-4"
                    onClick={goPrevious}
                  >
                    <i class="fa-solid fa-angles-left text-white fs-6"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan ">
              <div className="coin-plan-btn">
                <div className="row m-3 border-bottom mt-4">
                  <div className="col-6 title-head title-head d-flex align-self-end">
                    <h4 className="page-title mb-0 ">
                      <span className="text-primary">
                        {" "}
                        {dialogueData?.name}{" "}
                      </span>{" "}
                      's Host Details
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-end align-items-center fs-5 fw-bold">
                    Total Revenue :
                    <span className="text-primary">
                      {" "}
                      {totalCoin ? totalCoin : "0"}
                    </span>
                  </div>
                </div>
                <div className="row px-3 pt-2">
                  {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                    <div className="text-primary d-flex align-items-center">
                      {/* <span>
                        <img
                          src={agencyImage}
                          alt="agency"
                          width="40px"
                          height="40px"
                          className="rounded-circle me-3"
                        />
                      </span> */}
                  {/* <h4 className="fw-bold m-0">{data.name}</h4> 
                    </div>
                  </div> */}
                  <div className="col-12 col-md-6 d-flex align-items-center">
                    <div>
                      <Analytics
                        analyticsStartDate={startDate}
                        analyticsStartEnd={endDate}
                        analyticsStartDateSet={setStartDate}
                        analyticsStartEndSet={setEndDate}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                    <Searching
                      type={`client`}
                      data={agencyHistory}
                      setData={setData}
                      column={historyTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>

                <div className="userMain mt-3 border-top">
                  <div className="primeMainHistory border-top">
                    <Table
                      data={data}
                      mapData={historyTable}
                      serverPerPage={rowsPerPage}
                      serverPage={currentPage}
                      type={"server"}
                    />
                  </div>
                  <Pagination
                    component="div"
                    count={agencyHistory?.length}
                    serverPage={currentPage}
                    type={"server"}
                    onPageChange={handleChangePage}
                    serverPerPage={rowsPerPage}
                    totalData={totalData}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencyHitory })(AgencyHistory);
