//Get Feedback
export const GET_USER_FEEDBACK = "GET_USER_FEEDBACK";
export const GET_HOST_FEEDBACK = "GET_HOST_FEEDBACK";

//isSolved Feedback Switch
export const FEEDBACK_SOLVED = "FEEDBACK_SOLVED";

//isSolved Feedback Switch
export const FEEDBACK_DELETE = "FEEDBACK_DELETE";

//Open and Close Feedback Dialog
export const OPEN_FEEDBACK_DIALOG = "OPEN_FEEDBACK_DIALOG";
export const CLOSE_FEEDBACK_DIALOG = "CLOSE_FEEDBACK_DIALOG";
