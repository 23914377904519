import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import Input from "../../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../../util/Alert";
import Button from "../../../extra/Button";
import {
  createFakeHost,
  // sendExcelApi,
  updateHost,
  deleteHostImg,
} from "../../../store/FakeHost/fakeHost.action";
import { hostCountry } from "../../../store/host/host.action";
import { baseURL } from "../../../util/config";
import ReactDropzone from "react-dropzone";
import saveAs from "file-saver";
import { addMultiAppSetting } from "../../../store/setting/setting.action";
import * as XLSX from "xlsx";
import axios from "axios";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import Male from "../../../../assets/images/male.jpg"

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "1px solid var(--inputBorder--)",
    borderRadius: "6px 0 0 6px",
    padding: "16px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#dee2e694",
  }),
};

const FakeHostDialog = (props) => {
  const { agency } = useSelector((state) => state.agency);
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const { country1 } = useSelector((state) => state.host);
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  
  const navigate = useNavigate();

  const [mongoId, setMongoId] = useState("");
  const [name, setName] = useState("");
  const initialCountry = {
    value: "647edf4a693e0fdc4a7705ff",
    label: "India",
  };
  const [country, setCountry] = useState(initialCountry);

  const [selectedOption, setSelectedOption] = useState("");
  const [imageType, setImageType] = useState(1);
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [oneImageType, setOneImageType] = useState(1);
  const [oneImage, setOneImage] = useState([]);
  const [oneImagePath, setOneImagePath] = useState("");
  const [video, setVideo] = useState([]);
  const [videoPath, setVideoPath] = useState("");
  const [videoType, setVideoType] = useState(1);
  const [age, setAge] = useState();
  const [bio, setBio] = useState("");
  const [multiImage, setMultiImage] = useState([]);
  const [coin, setCoin] = useState(0);
  const [callCharge, setCallCharge] = useState(0);
  const [imgMulti, setImgMulti] = useState(false);
  const [imgText, setImgText] = useState();

  const [error, setError] = useState({
    name: "",
    image: "",
    video: "",
    videoPath: "",
    bio: "",
    // excelData: "",
    country: "",
    age: "",
    oneImage: "",
    oneImagePath: "",
    // coin: "",
    // callCharge: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    props.hostCountry();
  }, []);

  useEffect(
    () => () => {
      setMongoId("");
      setName("");
      setImage([]);
      setImageType(1);
      setImagePath("");
      setVideo("");
      setVideoType(1);
      setVideoPath("");
      setBio("");
      setAge("");
      setOneImageType(1);
      setOneImage("");
      setOneImagePath("");
      setCountry("");
      setCallCharge("");
      setCoin("");
    },
    [dialogue]
  );

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?._id);
      setName(dialogueData?.name);
      setImageType(dialogueData?.imageType);
      setBio(dialogueData?.bio);
      setAge(dialogueData?.age);
      setMultiImage(dialogueData?.multiImage);
      setCountry({
        value: dialogueData?.countryId?._id,
        label: dialogueData?.countryId?.name,
      });
      {
        dialogueData?.imageType == 0
          ? setImage(dialogueData?.image)
          : setImagePath(dialogueData?.image?.map((img)=> img.url));
      }
      setVideo(dialogueData?.video);
      setVideoType(dialogueData?.videoType);
      setVideoPath(
        dialogueData?.videoType == 0 ? dialogueData?.video : dialogueData?.video
      );
      setOneImageType(dialogueData?.profilePicType);
      setOneImage(dialogueData?.profilePic);
      setOneImagePath(
        dialogueData?.profilePicType == 0
          ? dialogueData?.profilePic
          : dialogueData?.profilePic
      );
      setCallCharge(dialogueData?.callCharge);
      setCoin(dialogueData?.coin);
    } 
  }, [dialogueData]);

  useEffect(() => {
    if (!dialogueData) {
      if (imagePath?.length || image?.length) {
        setImgMulti(true);
      } else {
        setImgMulti(false);
      }
    } else {
      const fileObjects = [];
      image.forEach((item) => {
        if (typeof item === "object" && item.path && item.preview) {
          fileObjects.push(item);
        }
      });
      let path = null;
      if (typeof imagePath === "string") {
        path = imagePath.split(",");
      }
      const combinedString = dialogueData?.image.join(", ");
      const checkPath = combinedString === imgText;
      const imageCheck = dialogueData?.image?.length === image?.length;

      if (imageCheck === false || checkPath === false) {
        setImgMulti(true);
      }
      if (image?.length === 0 && imagePath?.length === 0) {
        setImgMulti(false);
      }
    }
  }, [image, imagePath]);

  useEffect(() => {
    // Set the initial country value after the component mounts
    const initialCountryData = country1.find(
      (country1) => country1._id === initialCountry.value
    );

    if (initialCountryData) {
      setCountry({
        value: initialCountryData._id,
        label: initialCountryData.name,
      });
    }
  }, [country1]);

  const onPreviewDrop = (files) => {
    setError({ ...error, image: "" });
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setImage(image.concat(files));
  };
  const handleDeleteImg = (file123, index) => {
    const newFiles = [...image];
    image.splice(newFiles.indexOf(file123), 1);
    if (file123.preview) {
      const images = image.filter((ele) => {
        return ele.preview !== file123.preview;
      });
      setImagePath(images);
      setImage(images);
    } else {
      const newFiles = [...image];
      image.splice(newFiles.indexOf(file123), 1);
      setImage(newFiles);
    }
    if (mongoId) {
      props.deleteHostImg(dialogueData?._id, index);
    }
  };
  let name_ = []; // Initialize name_ as an array
  name_.unshift(name);

  let image_ = []; // Initialize image_ as an array
  image_.unshift(imagePath);

  const onPreviewDropVideo = (files) => {
    setError({ ...error, video: "" });
    files.map((file) => {
      file.preview = URL.createObjectURL(file);
    });

    setVideo(video.concat(files));
  };

  const handleOneImage = (e) => {
    setError((prevErrors) => ({
      ...prevErrors,
      oneImage: "",
    }));
    setOneImage(e.target.files[0]);
    setOneImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const options = country1?.map((countryData) => {
    return {
      value: countryData?._id,
      label: countryData?.name,
    };
  });

  const isValidURL = (url) => {
    if (typeof url !== "string") {
      return false; // Make sure url is a string
    }

    const urlRegex = /^(ftp|http|https):\/[^ "]+$/;
    return urlRegex.test(url) || url?.startsWith("blob:");
  };

  const handleSubmit = (e) => {
    if (selectedOption?.value) {
      setCountry(selectedOption?.value);
    }
    if (
      !name ||
      !age ||
      age < 18 ||
      !bio ||
      // (imageType === 1 ? !imagePath || !isValidURL(imagePath) : !image) ||
      (imageType === 0 && image.length === 0) ||
      // (oneImageType === 1
      //   ? !oneImagePath || !isValidURL(oneImagePath)
      //   : !oneImage) ||
      (videoType === 1 ? !videoPath || !isValidURL(videoPath) : !video)
    ) {
      let error = {};

      // if (imageType === 1 ? !imagePath || !isValidURL(imagePath) : !image) {
      //   error.image = "Invalid Image Link!";
      // }

      // if (
      //   oneImageType === 1
      //     ? !oneImagePath || !isValidURL(oneImagePath)
      //     : !oneImage
      // ) {
      //   error.oneImage = "Invalid Image Link!";
      // }

      if (videoType === 1 ? !videoPath || !isValidURL(videoPath) : !video) {
        error.video = "Invalid Video Link!";
      }
      if (imageType === 0 && image.length === 0)
        error.image = "Image is Required !";

      if (!age) error.age = "Age is Required";
      if (!bio) error.bio = "Bio is Required";
      if (age < 18) error.age = "Age is Not Valid";
      if (!name) error.name = "Name is required !";
      if (oneImagePath ? !oneImagePath : oneImage.length === 0)
        error.oneImage = "Image is Required !";

      if (videoPath ? !videoPath : video.length == 0)
        error.video = "Video is Required !";
      if (imageType == 0 ? image?.length == 0 : !imagePath)
        error.image = "Image is Required !";
      return setError({ ...error });
    } else {
      //   if (!hasPermission) return permissionError();
      const formData = new FormData();
      formData.append("name", name);
      formData.append("age", age);
      formData.append("bio", bio);
      formData.append("imageType", imageType);

      // type 1 link 0 image
      if (imageType == 1) {
        // formData.append("image", image);
        if (dialogueData) {
          if (typeof imagePath === "string" && imagePath.trim() !== "") {
            var path = imagePath.split(",") || [""];
            for (let i = 0; i < path.length; i++) {
              formData.append("image", path[i]);
            }
          }
        } else {
          if (typeof imagePath === "string" && imagePath.trim() !== "") {
            var path = imagePath.split(",") || [""];
            for (let i = 0; i < path.length; i++) {
              formData.append("image", path[i]);
            }
          }
        }
      } else {
        // if (!dialogueData) {
        for (let i = 0; i < image.length; i++) {
          formData.append("image", image[i]);
        }
        // } else {
        //   formData.append("image", image);
        // }
      }
      formData.append("videoType", videoType);

      // videoType 1 image 0 link

      if (videoType == 1) {
        formData.append("video", videoPath);
      } else {
        formData.append("video", video);
      }

      formData.append("profilePicType", oneImageType);

      if (oneImageType == 1) {
    ;
        formData.append("profilePic", oneImagePath);
      } else {
    ;
        formData.append("profilePic", oneImage);
      }

      formData.append("coin", coin);
      formData.append("callCharge", callCharge);
      if (mongoId) {
        props.updateHost(mongoId, formData);
      } else {
        props.createFakeHost(formData);
      }
      
        handleClosed();
      
    }
  };
  const handleChange = (e) => {
    const enteredValue = e.target.value.trim();

    if (!enteredValue) {
      setImagePath("");
    } else {
      // Check if the entered URL ends with ".mp4"
      if (enteredValue.endsWith(".mp4")) {
        setError({
          ...error,
          image: "MP4 videos are not allowed! Please enter a valid image link.",
        });
      } else {
        setImagePath(enteredValue);
        setImgText(enteredValue);
        setError({
          ...error,
          image: "",
        });
      }
    }
  };
  const handleVideoChange = (e) => {
    const enteredValue = e.target.value.trim();

    // Check if the entered URL is not valid
    if (!isValidURL(enteredValue)) {
      setError({
        ...error,
        video: "Please enter a valid video URL!",
      });
    } else {
      setVideoPath(enteredValue);
      setError({
        ...error,
        video: "",
      });
    }
  };

  const handleClosed = () => {
    localStorage.removeItem("dialogueData");
    dispatch({ type: CLOSE_DIALOGUE });
  };
  const showImg = (url) => {
    window.open(url, "_blank");
  };
  const HandleInputVideo = (e) => {
    if (e.target.files[0] == "") {
      error.video = "Video Is Required";
    } else {
      error.video = "";
    }
    if (e.target.files[0]) {
      setVideo(e.target.files[0]);
      setVideoPath(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <div className="mainHostEdit fade-in">
      <div className="hostProfile">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">
            {mongoId ? "Fake Host Update" : "Fake Host Create"}
          </div>
          <div className="closeBtn " onClick={handleClosed}>
            <i className="fa-solid fa-xmark ms-2"></i>
          </div>
        </div>
        <div
          className="dialogueMain bg-white mx-3"
          style={{ overflow: "auto", borderRadius: "10px" }}
        >
          <div className="row">
            <div className="row">
              <div className="col-lg-6">
                <Input
                  label={`Host Name`}
                  id={`name`}
                  type={`text`}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Input
                  label={`Bio`}
                  id={`bio`}
                  type={`text`}
                  value={bio}
                  errorMessage={error.bio && error.bio}
                  onChange={(e) => {
                    setBio(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        bio: `Bio is required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        bio: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 ">
                <Input
                  label={`Age`}
                  id={`age`}
                  type={`number`}
                  value={age}
                  errorMessage={error.age && error.age}
                  onChange={(e) => {
                    setAge(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        age: `Age is required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        age: "",
                      });
                    }
                  }}
                />
              </div>
              {/* <div className="col-lg-6">
                <div className="form-group">
                  <h6 className="fs-6 m-0F">Country</h6>

                  <Select
                    value={country}
                    options={options}
                    styles={customStyles}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption);
                      // Handle error
                    }}
                  />
                  <> */}
                    {/* <Select
                      value={country}
                      defaultValue={country}
                      onChange={(selectedOption) => {
                        setCountry(selectedOption);
                        setError((prevError) => ({
                          ...prevError,
                          country: "",
                        }));
                      }}
                      options={options}
                    /> */}
                    {/* {!selectedOption?.value && (
                      <div className="ml-2 mt-1">
                        <p className="errorMessage">{error.country}</p>
                      </div>
                    )}
                    {}
                  </>
                </div>
              </div> */}
              <div className="col-lg-6">
                <Input
                  label={`Coin`}
                  id={`coin`}
                  type={`number`}
                  defaultValue={0}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setCoin("") : "";
                  }}
                  value={coin}
                  // errorMessage={error.coin && error.coin}
                  onChange={
                    (e) => setCoin(parseInt(e.target.value))
                    // if (!e.target.value) {
                    //   return setError({
                    //     ...error,
                    //     coin: `Coin Is Required`,
                    //   });
                    // } else {
                    //   return setError({
                    //     ...error,
                    //     coin: "",
                    //   });
                    // }
                    // }
                  }
                />
              </div>
              {/* <div className="col-lg-6">
                <Input
                  label={`Call Rate`}
                  id={`callRate`}
                  defaultValue={0}
                  type={`number`}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setCallCharge("") : "";
                  }}
                  value={callCharge}
                  // errorMessage={error.callCharge && error.callCharge}
                  onChange={
                    (e) => setCallCharge(parseInt(e.target.value))
                    //   if (!e.target.value) {
                    //     return setError({
                    //       ...error,
                    //       callCharge: `Call Rate Is Required`,
                    //     });
                    //   } else {
                    //     return setError({
                    //       ...error,
                    //       callCharge: "",
                    //     });
                    //   }
                    // }
                  }
                />
              </div> */}
            </div>

            {/* FOR IMAGE  START*/}

            <>
              <div className="col-md-6 d-flex  mt-4">
                <label className="text-gray">Album Type : </label>
                <div class="col-3 d-flex align-items-center">
                  <Input
                    id={`image`}
                    type={`radio`}
                    value={`0`}
                    name={`imageType`}
                    newClass={`me-2 mb-0`}
                    // disabled={imgMulti}
                    onClick={(e) => {
                      setImageType(e.target.value);
                    }}
                    checked={imageType == 0 ? true : false}
                  />
                  <label class="form-check-label" for="image">
                    Image
                  </label>
                </div>
                <div class=" col-3 d-flex align-items-center">
                  <Input
                    type={`radio`}
                    id={`linkImage`}
                    value={`1`}
                    name={`imageType`}
                    // disabled={imgMulti}
                    newClass={`me-2 mb-0`}
                    onClick={(e) => {
                      setImageType(e.target.value);
                    }}
                    checked={imageType == 1 ? true : false}
                  />
                  <label class="form-check-label" for="linkImage">
                    Link
                  </label>
                </div>
              </div>
              <div className={imageType == 0 ? "row mt-4" : "d-none"}>
                <div className="col-lg-6">
                  <label className="form-control-label" for="input-username">
                    Select (Multiple) image
                  </label>
                  {/* <Input
                      type={`file`}
                      accept={`image/*`}
                      onChange={handleImage}
                      newClass={`col-md-6 d-flex align-self-end`}
                      errorMessage={error.excelData && error.excelData}
                    /> */}
                  <>
                    <ReactDropzone
                      onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="me-4">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                              style={{
                                height: 130,
                                width: 130,
                                border: "2px dashed gray",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              <i
                                className="fas fa-plus"
                                style={{ paddingTop: 30, fontSize: 70 }}
                              ></i>
                            </div>
                            {/* <div className="img-container" style= {{display:"inline" , position:"relative" , float:"left"}}>
                                <i class="fas fa-times-circle text-danger" style= {{right:"10px" , position:"absolute" , float:"left" , top:"4px" ,cursor:"pointer" }}></i>
                                </div> */}
                          </div>
                        </section>
                      )}
                    </ReactDropzone>
                    {error.image && (
                      <div className="ml-2 mt-1">
                        {error.image && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{error.image}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </div>

                {/* <div
                  className="col-lg-6 ps-5 d-flex"
                  style={{ maxHeight: "190px"}}
                > */}
                <div className="col-lg-6 setImage ps-5">
                  <div
                    className="scroll-content w-100 flex-wrap"
                    style={{ height: "190px", overflow: "auto" }}
                  >
                    {imageType == 0 ? (
                      <>
                        {image?.map((file123, index) => {
                          return (
                            <div key={index}>
                              <img
                                height="80px"
                                width="80px"
                                alt="app"
                                className="cursor mx-4"
                                src={
                                  mongoId && !file123?.preview
                                    ? file123
                                    : file123?.preview ? mongoId && !file123?.preview
                                    ? file123
                                    : file123?.preview : Male
                                }
                                onClick={() =>
                                  showImg(
                                    !file123?.preview
                                      ? file123
                                      : file123?.preview
                                  )
                                }
                                onError={(e) => e.target.src = Male}
                                style={{
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                  border: "2px solid #fff",
                                  borderRadius: 10,
                                  marginTop: 10,
                                  float: "left",
                                  objectFit: "cover",
                                }}
                              />

                              <div className="img-container">
                                <i
                                  class="fas fa-times-circle text-danger"
                                  onClick={() =>
                                    handleDeleteImg(file123, index)
                                  }
                                ></i>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      imagePath && (
                        <>
                          <img
                            height="80px"
                            width="80px"
                            alt="app"
                              src={imagePath ? imagePath : Male}
                            className="cursor"
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              float: "left",
                              objectFit: "cover",
                              marginRight: 15,
                            }}
                              onError={(e) => e.target.src = Male}
                            onClick={() => showImg(imagePath)}
                          />
                        </>
                      )
                    )}
                  </div>
                  {/* {!dialogueData &&
                    !mongoId &&
                    image_.map((src, index) => {
                      const imageUrls = src?.split(",");
                      return imageUrls.map((imageUrl, i) =>
                        imageUrl ? (
                          <img
                            src={imageUrl}
                            alt={``}
                            key={`${index}-${i}`}
                            width={80}
                            height={80}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              // height:130,
                              // width:130,
                              float: "left",
                              objectFit: "cover",
                              marginRight: 15,
                            }}
                          />
                        ) : (
                          ""
                        )
                      );
                    })} */}
                  {!dialogueData &&
                    !mongoId &&
                    image_.map((src, index) => {
                      if (typeof src === "string") {
                        const imageUrls = src.split(",");
                        return imageUrls.map((imageUrl, i) =>
                          imageUrl ? (
                            <img
                              src={imageUrl ? imageUrl : Male}
                              alt=""
                              key={`${index}-${i}`}
                              width={80}
                              onError={(e) => e.target.src = Male}
                              height={80}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "cover",
                                marginRight: 15,
                              }}
                            />
                          ) : (
                            ""
                          )
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className={imageType == 1 ? "row mt-4 col-md-12" : "d-none"}
                >
                  {/* <textarea
                    type="text"
                    rows={3}
                    cols={3}
                    className="form-control"
                    required=""
                    // defaultValue={image?.map((img) => img.preview).join(",")}
                    placeholder="Enter image link...."
                    value={image?.map((img) => img.preview).join(",")}
                    onChange={(e) => {
                      const enteredImages = e.target.value
                        .split(",")
                        .map((url) => ({
                          file: null,
                          preview: url.trim(),
                        }));
                      setImage(enteredImages);
                      if (!e.target.value) {
                        setError({
                          ...error,
                          image: "Image is Required!",
                        });
                      } else {
                        setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  /> */}
                  <textarea
                    type="text"
                    rows={3}
                    cols={3}
                    className="form-control"
                    required=""
                    placeholder="Enter image link...."
                    value={imagePath}
                    onChange={handleChange}

                  />
                  {error.image && (
                    <div className="ml-2 mt-1">
                      {error.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{error.image}</span>
                        </div>
                      )}
                    </div>
                  )}

                  {!mongoId && (
                    <>
                      <div className="d-flex mt-2">
                        <span className="text-danger">Note : </span>
                        <span className="" style={{ color: "red" }}>
                          You can add multiple image Link separate by comma (,)
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* FOR IMAGE  END*/}

              {/* <div className="col-md-6 d-flex  mt-4">
                <label className="text-gray">Video Type : </label>
                <div class="col-3 d-flex align-items-center">
                  <Input
                    type={`radio`}
                    name={`videoType`}
                    id={`video`}
                    value={`0`}
                    newClass={`mb-0 me-2`}
                    onClick={(e) => {
                      setVideoType(e.target.value);
                    }}
                    checked={videoType == 0 ? true : false}
                  />
                  <label class="form-check-label" for="image">
                    Video
                  </label>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <Input
                    type={`radio`}
                    name={`videoType`}
                    id={`linkVideo`}
                    value={`1`}
                    newClass={`mb-0 me-2`}
                    checked={videoType == 1 ? true : false}
                    onClick={(e) => {
                      setVideoType(e.target.value);
                    }}
                  />
                  <label class="form-check-label" for="link">
                    Link
                  </label>
                </div>
              </div>
              {!dialogueData ? (
                <div className={videoType == 0 ? "row mt-4" : "d-none"}>
                  <div className="col-lg-6">
                    <label className="form-control-label" for="input-username">
                   Video
                    </label>
                    <input
                          type="file"
                          className="form-control form-control-sm"
                          accept="video/*"
                          required=""
                          controls
                          // value={}
                          onChange={HandleInputVideo}
                        />
                    <>
                   
                      {error.video && (
                        <div className="ml-2 mt-1">
                          {error.video && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{error.video}</span>
                            </div>
                          )}
                        </div>
                      )}
                         {videoPath && (
                          <>
                            <video
                              height="100px"
                              width="100px"
                              alt="app"
                              src={videoPath}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "contain",
                                marginRight: 15,
                              }}
                            />
                          </>
                        )}
                    </>
                  </div>
                </div>
              ) : (
                <div className={videoType == 0 ? "row mt-4" : "d-none"}>
                  <div className="col-lg-6 mt-4">
                    <>
                      <Input
                        type={`file`}
                        accept={`video/*`}
                        onChange={handleVideo}
                      />
                      {error.video && (
                        <div className="ml-2 mt-1">
                          {error.video && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{error.video}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  </div>
                  <div className="col-lg-6 ps-5">
                    {videoType == 0 && video?.length > 0 ? (
                      <>
                      <video
                          height="80px"
                          width="80px"
                          alt="app"
                          controls
                          src={videoPath}
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                            border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "contain",
                            marginRight: 15,
                          }}
                          />
                      </>
                    ) : (
                      dialogueData && (
                        <video
                          height="80px"
                          width="80px"
                          alt="app"
                          controls
                          src={videoPath}
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                            border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "contain",
                            marginRight: 15,
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
              )}

              <div className={videoType == 1 ? "row mt-4" : "d-none"}>
                <textarea
                  type="text"
                  rows={3}
                  cols={3}
                  className="form-control"
                  required=""
                  placeholder="Enter video link......."
                  value={
                    mongoId
                      ? videoPath
                      : ""
                  }
                  onChange={(e) => {
                    const enteredVideos = e.target.value
                      .split(",")
                      .map((url) => ({
                        file: null,
                        preview: url.trim(),
                      }));
                    setVideo(enteredVideos);
                    if (!e.target.value) {
                      setError({
                        ...error,
                        video: "video is Required!",
                      });
                    } else {
                      setError({
                        ...error,
                        video: "",
                      });
                    }
                  }}
                />
                {error.video && (
                  <div className="ml-2 mt-1">
                    {error.video && (
                      <div className="pl-1 text__left">
                        <span className="text-red">{error.video}</span>
                      </div>
                    )}
                  </div>
                )}

                {!mongoId && (
                  <>
                    <span className="text-danger">Note : </span>
                    <span className="">
                      You can add multiple video Link separate by comma (,)
                    </span>
                  </>
                )}
              </div> */}
              <div className="col-md-6 d-flex  mt-4">
                <label className="text-gray">Video Type : </label>
                <div class="col-3 d-flex align-items-center">
                  <Input
                    id={`video`}
                    type={`radio`}
                    value={`0`}
                    name={`videoType`}
                    newClass={`me-2 mb-0`}
                    onClick={(e) => {
                      setVideoType(e.target.value);
                    }}
                    checked={videoType == 0 ? true : false}
                  />
                  <label class="form-check-label" for="image">
                    Video
                  </label>
                </div>
                <div className=" col-3 d-flex align-items-center">
                  <Input
                    type={`radio`}
                    id={`linkVideo`}
                    value={`1`}
                    name={`videoType`}
                    newClass={`me-2 mb-0`}
                    onClick={(e) => {
                      setVideoType(e.target.value);
                    }}
                    checked={videoType == 1 ? true : false}
                  />
                  <label class="form-check-label" for="linkImage">
                    Link
                  </label>
                </div>
              </div>
              <div className="row mt-4 align-items-center">
                <div className={videoType == 0 ? "col-md-6" : "d-none"}>
                  <div className="form-group image-input">
                    <label className="mb-2 text-gray">video</label>
                    <Input
                      type={`file`}
                      accept={`video/*`}
                      onChange={HandleInputVideo}
                      newClass={`col-md-12 d-flex align-self-end`}
                    />
                    {error.video && (
                      <div className="ml-2 mt-1">
                        {error.video && (
                          <div className="pl-1 text__left">
                            <span className="text-danger">{error.video}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={videoType == 1 ? "col-md-12" : "d-none"}>
                  <div className="form-group">
                    <label className="mb-2 text-gray">Video Link</label>
                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="Video link "
                      value={videoPath}
                      onChange={handleVideoChange}
                    />
                    {error.video && (
                      <div className="ml-2 mt-1">
                        {error.video && (
                          <div className="pl-1 text__left">
                            <span className="errorMessage">{error.video}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={videoType == 0 ? "col-md-6 d-flex ps-5" : "d-none"}
                >
                  {videoPath && (
                    <>
                      <video
                        height="150px"
                        width="150px"
                        alt="app"
                        src={videoPath ? videoPath : Male}
                        onError={(e) => e.target.src = Male}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                          border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          height: "80px",
                          width: "80px",
                          float: "left",
                          objectFit: "cover",
                          marginRight: 15,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-6 d-flex  mt-4">
                <label className="text-gray">Profile Type :</label>
                <div class="col-3 d-flex align-items-center">
                  <Input
                    id={`oneImage`}
                    type={`radio`}
                    value={`0`}
                    name={`oneImageType`}
                    newClass={`me-2 mb-0`}
                    onClick={(e) => {
                      setOneImageType(e.target.value);
                    }}
                    checked={oneImageType == 0 ? true : false}
                  />
                  <label class="form-check-label" for="image">
                    Image
                  </label>
                </div>
                <div className=" col-3 d-flex align-items-center">
                  <Input
                    type={`radio`}
                    id={`linkOneImage`}
                    value={`1`}
                    name={`oneImageType`}
                    newClass={`me-2 mb-0`}
                    onClick={(e) => {
                      setOneImageType(e.target.value);
                    }}
                    checked={oneImageType == 1 ? true : false}
                  />
                  <label class="form-check-label" for="linkImage">
                    Link
                  </label>
                </div>
              </div>
              <div className="row mt-4 align-items-center">
                <div className={oneImageType == 1 ? "col-md-12" : "d-none"}>
                  <div className="form-group">
                    <label className="mb-2 text-gray">Image Link</label>
                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="Image link "
                      value={oneImagePath}
                    
                      onChange={(e) => {
                        setOneImagePath(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            oneImage: "Image is Required!",
                          });
                        } else {
                          return setError({
                            ...error,
                            oneImage: "",
                          });
                        }
                      }}
                    />
                    {error.oneImage && (
                      <div className="ml-2 mt-1">
                        {error.oneImage && (
                          <div className="pl-1 text__left">
                            <span className="errorMessage">
                              {error.oneImage}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={oneImageType == 0 ? "col-md-6" : "d-none"}>
                  <span>Image</span>
                  <div className="mt-3 image-input">
                    <Input
                      type={`file`}
                      accept={`image/*`}
                      onChange={handleOneImage}
                    
                      style={{ width: "100%" }}
                      newClass={`col-md-12 d-flex align-self-end`}
                    />
                    {error.oneImage && (
                      <div className="ml-2 mt-1">
                        {error.oneImage && (
                          <div className="pl-1 text__left">
                            <span className="text-danger">
                              {error.oneImage}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    oneImageType == 0 ? "col-md-6 d-flex ps-5" : "d-none"
                  }
                >
                  {oneImagePath && (
                    <>
                      <img
                        height="80px"
                        width="80px"
                        alt="app"
                        src={oneImagePath ? oneImagePath : Male}
                        onError={(e) => e.target.src = Male}
                        className="cursor"
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                          border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          height: "80px",
                          width: "80px",
                          float: "left",
                          objectFit: "cover",
                          marginRight: 15,
                        }}
                      />
                    </>
                  )}
                </div>
               
              </div>
            </>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {mongoId ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={handleClosed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  createFakeHost,
  hostCountry,
  updateHost,
  deleteHostImg,
  addMultiAppSetting,
})(FakeHostDialog);
