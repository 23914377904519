import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
  createNewFlashCoin,
  updateFlashCoin,
} from "../../store/flashCoin/FlashCoin.action";

const FlashDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [mongoId, setMongoId] = useState(0);
  const [coin, setCoin] = useState(0);
  const [dollar, setDollar] = useState(0);
  const [rupee, setRupee] = useState(0);
  //   const [rupeeWithDiscount, setRupeeWithDiscount] = useState(0);
  const [platFormType, setPlatFormType] = useState("");
  const [tag, setTag] = useState("");
  const [productKey, setProductKey] = useState("");
  const [discount, setDiscount] = useState(0);

  const [imagePath, setImagePath] = useState("");
  const [image, setImage] = useState([]);
  const [error, setError] = useState({
    coin: "",
    rupee: "",
    rupeeWithDiscount: "",
    image: "",
    discount: "",
    productKey: "",
    dollar: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setCoin(dialogueData?.coin);
    setRupee(dialogueData?.rupee);
    // setRupeeWithDiscount(dialogueData?.rupeeWithDiscount);
    // setPlatFormType(dialogueData?.platFormType);
    setImagePath(dialogueData?.image);
    setTag(dialogueData?.tag);
    setDiscount(dialogueData?.discount);
    setDollar(dialogueData?.dollar);
    setProductKey(dialogueData?.productKey);
  }, [dialogueData]);
  const handleImage = (e) => {
    // BOTH ARE WORKING STEP : 1
    // if (e.target.files[0] == "") {
    //     setError({
    //       ...error,
    //       image: "Image require",
    //     });

    // } else {
    //      setError({
    //        ...error,
    //        image: "",
    //      })
    // }

    // BOTH ARE WORKING STEP : 2

    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };
  const handleSubmit = (e) => {
    const coinValid = isNumeric(coin);
    const dollarValid = isNumeric(dollar);
    const rupeeValid = isNumeric(rupee);
    const discountValid = isNumeric(discount);
    if (
      !coin ||
      !coinValid ||
      !rupee ||
      !rupeeValid ||
      !dollarValid ||
      dollar === "" ||
      !productKey ||
      // !productKeyValid ||
      // !tagValid ||
      !image ||
      // !imageValid ||
      !discount ||
      !discountValid
    ) {
      const error = {};
      if (!coin) {
        error.coin = "Coin is required!";
      } else if (!coinValid) {
        error.coin = "Invalid Coin!";
      }

      if (!productKey) {
        error.productKey = "ProductKey is required!";
      }
      if (!rupee) {
        error.rupee = "Rupee is required!";
      } else if (!rupeeValid) {
        error.rupee = "Invalid rupee!";
      }
      if (!discount) {
        error.discount = "Discount is required!";
      } else if (!discountValid) {
        error.discount = "Invalid discount!";
      }
      if (dollar === "") {
        error.dollar = "Dollar is required!";
      } else if (!dollarValid) {
        error.dollar = "Invalid dollar!";
      }

      if (!image || !imagePath) error.image = "Image is required!";

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("coin", coin);
      formData.append("tag", tag);
      formData.append("productKey", productKey);
      formData.append("rupee", rupee);
      formData.append("dollar", dollar);
      formData.append("discount", discount);
      formData.append("image", image);

      if (mongoId) {
        props.updateFlashCoin(formData, mongoId);
      } else {
        props.createNewFlashCoin(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <>
      <div className="mainDialogue fade-in">
        <div
          className="Dialogue"
          style={{ overflow: "auto", maxHeight: "100vh" }}
        >
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Flash Coin Dialog</div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-6">
                <Input
                  label={`Coin`}
                  id={`coin`}
                  type={`number`}
                  value={coin}
                  errorMessage={error.coin && error.coin}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setCoin("") : "";
                  }}
                  onChange={(e) => {
                    setCoin(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        coin: `Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        coin: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Tag`}
                  id={`tag`}
                  type={`text`}
                  value={tag}
                  placeholder={`Enter Tag`}
                  onChange={(e) => setTag(e.target.value)}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Rupee(₹)`}
                  id={`rupee`}
                  type={`number`}
                  value={rupee}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setRupee("") : "";
                  }}
                  errorMessage={error.rupee && error.rupee}
                  onChange={(e) => {
                    setRupee(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        rupee: `Extra Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        rupee: "",
                      });
                    }
                  }}
                />
              </div>
              {/* <div className="col-6">
                <Input
                  label={`Discount(₹)`}
                  id={`rupeeWithDiscount`}
                  type={`number`}
                  value={rupeeWithDiscount}
                  errorMessage={
                    error.rupeeWithDiscount && error.rupeeWithDiscount
                  }
                  onChange={(e) => {
                    setRupeeWithDiscount(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        rupeeWithDiscount: `Rupee discount is required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        rupeeWithDiscount: "",
                      });
                    }
                  }}
                />
              </div> */}
              <div className="col-6">
                <Input
                  label={`Discount`}
                  id={`discount`}
                  type={`number`}
                  value={discount}
                  errorMessage={error.discount && error.discount}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setDiscount("") : "";
                  }}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        discount: `Discount is required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        discount: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Dollar`}
                  id={`dollar`}
                  type={`number`}
                  value={dollar}
                  errorMessage={error.dollar && error.dollar}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setDollar("") : "";
                  }}
                  onChange={(e) => {
                    setDollar(parseInt(e.target.value));
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        dollar: `Dollar is required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        dollar: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Product Key`}
                  id={`productKey`}
                  type={`text`}
                  value={productKey}
                  errorMessage={error.productKey && error.productKey}
                  placeholder={`Enter ProductKey`}
                  onChange={(e) => {
                    setProductKey(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        productKey: `Product key is required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        productKey: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12">
                <Input
                  label={`Upload Icon`}
                  id={`image`}
                  type={`file`}
                  onChange={(e) => handleImage(e)}
                  errorMessage={error.image && error.image}
                />
                {imagePath && (
                  <div>
                    <img
                      src={imagePath}
                      alt="banner"
                      draggable="false"
                      width={100}
                      height={100}
                      className="m-0"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="dialogueFooter">
              <div className="dialogueBtn">
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={() => {
                    dispatch({ type: CLOSE_DIALOGUE });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createNewFlashCoin, updateFlashCoin })(
  FlashDialog
);
