import * as actionType from "./notification.type";

//Initial State
const initialState = {
  notificationDialog: {},
  // agencyNotificationDialog: false,
  // notificationDialogData: null,
  // agencyNotificationDialogData: null,
  // dialogType: null,
  userNotification: {},
  id:null

};

//Notification Reducer
export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SEND_USER_NOTIFICATION:
      return {
        ...state,

        userNotification:action.payload.data,
        id:action.payload.id
      };

      case actionType.SEND_AGENCY_NOTIFICATION:
        return{
          ...state,
          agencyNotification:action.payload.data,
          id:action.payload.id
        }


    default:
      return state;
  }
};
