import * as ActionType from "./report.type";
import { setToast } from "../../util/toast";
import axios from "axios";
import { apiInstanceFetch } from "../../util/api";

export const getUserReport = (start, limit) => (dispatch) => {
    
  apiInstanceFetch
    .get(`admin/report/userReport?start=${start}&limit=${limit}`)
    .then((res) => {
        
      if (res.status) {
        dispatch({
          type: ActionType.GET_USER_REPORT,
          payload:{data: res.report , total : res.total},
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error.message));
};
export const getHostReport = (start, limit) => (dispatch) => {
    
  apiInstanceFetch
    .get(`admin/report/hostReport?start=${start}&limit=${limit}`)
    .then((res) => {
        
      if (res.status) {
        dispatch({
          type: ActionType.GET_HOST_REPORT,
          payload: {data : res.report , total : res.total},
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => console.log(error.message));
};
