import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./income.type";
import jwt_decode from "jwt-decode";

const initialState = {
  income: [],
  total: null,
  // totalCoin: null,
  totalDollar: null,
  history: [],
};

export const IncomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_INCOME_PLAN:
      return {
        ...state,
        income: action.payload,
      };
    case ActionType.CREATE_INCOME_PLAN:
      let data = [...state.income];
      data.unshift(action.payload);
      return {
        ...state,
        income: data,
      };
    case ActionType.EDIT_INCOME_PLAN:
      return {
        ...state,
        income: state.income.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    default:
      return state;
  }
};
