//Get Setting
export const GET_SETTING = "GET_SETTING";
export const VERIFY_NAME = "VERIFY_NAME";

//Update Setting
export const UPDATE_SETTING = "UPDATE_SETTING";

// is app active
export const IS_APP_ACTIVE_SWITCH = "IS_APP_ACTIVE_SWITCH";

// google switch
export const All_SWITCH_TYPE = "All_SWITCH_TYPE";

// quick switch
export const QUICK_SWITCH = "QUICK_SWITCH";

export const ADD_NEWAPP_SETTING = "ADD_NEWAPP_SETTING";

export const GET_MULTIAPP_SETTING = "GET_MULTIAPP_SETTING";

export const UPDATE_MULTIAPP_SETTING = "UPDATE_MULTIAPP_SETTING";

export const DELETE_MULTIAPP_SETTING = "DELETE_MULTIAPP_SETTING";

export const ACTIVE_MULTIAPP_SWITCH = "ACTIVE_MULTIAPP_SWITCH";
export const GET_APP_WISE_SETTING = "GET_APP_WISE_SETTING";
export const EMPTY_APP_WISE_SETTING = "EMPTY_APP_WISE_SETTING";
