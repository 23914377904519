import axios from "axios";
import { setToast } from "../../util/toast";
import {
    GET_TOPIC,
    CREATE_NEW_TOPIC,
    EDIT_TOPIC,
    DELETE_TOPIC
} from "./topic.type";
import { apiInstanceFetch } from "../../util/api";

export const getTopic = (start,limit) => (dispatch) => {
  
  apiInstanceFetch
    .get(`admin/topic?start=${start}&limit=${limit}`)
    .then((res) => {
        
      if (res.status) {
        dispatch({ type: GET_TOPIC, payload:{data : res.topic , totalTopic:res.total} });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const createTopic = (id,data) => (dispatch) => {
  
  axios
    .post(`admin/topic`, data)
    .then((res) => {
      
      if (res.data.status) {
        setToast("success", "Topic created successfully!");
       
        dispatch({ type: CREATE_NEW_TOPIC, payload: res.data.data });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const editTopic = (topicId, data) => (dispatch) => {
  
  axios
    .patch(`admin/topic/${topicId}`, data)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: EDIT_TOPIC,
          payload: { data: res.data.topic, id: topicId },
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const deleteTopic = (topicId,index) => (dispatch) => {
  
  axios
    .delete(`admin/topic/${topicId}?position=${index}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: DELETE_TOPIC,    payload: { data: res.data.data, id: topicId } });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

