import axios from "axios";
import * as ActionType from "./FlashCoin.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";
export const getFlashCoin = (login) => (dispatch) => {
  apiInstanceFetch
    .get("admin/flashCoin")
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_FLASH_COIN,
          payload: res.response,
        });
        // setToast("success", "Coin Plan Get Successfully!");
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const createNewFlashCoin = (formData) => (dispatch) => {
  
  axios
    .post(`admin/flashCoin`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_FLASH_COIN,
          payload: res.data.response,
        });
        setToast("success", "Flash coin create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

// Update Coin PLan
export const updateFlashCoin = (formData, id) => (dispatch) => {
  axios
    .patch(`admin/flashCoin/${id}`, formData)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_FLASH_COIN,
          payload: { data: res.data.response, id },
        });
        setToast("success", "CoinPlan update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const deleteFlashCoin = (id) => (dispatch) => {
    
  axios
    .delete(`admin/flashCoin/${id}`)
      .then((res) => {
        
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_FLASH_COIN,
          payload: id,
        });
        setToast("success", "CoinPlan Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const isActiveFlashCion = (id) => (dispatch) => {
  axios
    .put(`admin/flashCoin/${id}`)
      .then((res) => {
        
      if (res.data.status) {
        dispatch({
          type: ActionType.ACTIVE_SWITCH,
          payload: { data: res.data.response, id },
        });

        setToast(
          "success",
          `${
            res.data.response.isActive === true
              ? "Flash Coin  Active SuccessFully"
              : "Flash Coin Disable SuccessFully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// Purchase History Get

// export const getPurchaseHistory = (startDate, endDate) => (dispatch) => {
//   axios
//     .get(`/coinPlan/purchaseHistory?startDate=${startDate}&endDate=${endDate}`)
//     .then((res) => {
//       dispatch({ type: ActionType.PURCHASE_HISTORY, payload: res.data });
//     })
//     .catch((error) => console.log("error", error));
// };
