import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { approvedHostRequest, declineHostRequest, getPendingHost } from "../../../store/host/host.action"
import Searching from "../../../extra/Searching";
import Title from "../../../extra/Title";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import dayjs from "dayjs";
import Button from "../../../extra/Button";
import { FaCheck } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { AcceptProductWarning } from "../../../util/Alert";
import Input from "../../../extra/Input";
import { toast } from "react-toastify";
import Male from "../../../../assets/images/male.jpg"
const PendingHost = () => {
    const [search, setSearch] = useState("ALL");
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const { pendingHost, total } = useSelector((state) => state.host)
    const [declineModal, setDeclineModal] = useState(false)
    const [type, setType] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const dispatch = useDispatch()
    const [reason, setReason] = useState("")
    const [error, setError] = useState("")
    const [hostId, setHostId] = useState("")
    useEffect(() => {
        dispatch(getPendingHost(
            search === "" ? "ALL" : search,
            currentPage,
            rowsPerPage,
            type
        ))
    }, [search, currentPage, rowsPerPage])
    const handleFilterData = useMemo(() => {
        return (filteredData) => {
            if (typeof filteredData === "string") {
                setSearch(filteredData);
            } else {
                setData(filteredData);
            }
        };
    }, [search]);
    useEffect(() => {
        setData(pendingHost);
    }, [pendingHost]);
    useEffect(() => {
        setCurrentPage(1);
    }, [search]);

    // // pagination
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setCurrentPage(1);
    };
    const handleAccept = async (id) => {
        try {
            const data = await AcceptProductWarning("Accept");
            if (data) {
                dispatch(approvedHostRequest({ hostId: id, type: 2 }))
                    .then((res) => {
                        toast.success("Host accepted successfully.");
                        dispatch(getPendingHost(search === "" ? "ALL" : search, currentPage, rowsPerPage, type));
                    })
                    .catch((error) => toast.error(error.message));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = () => {
        if (!reason) {
            setError({ reason: "Reason is required" });
        } else {
            dispatch(declineHostRequest({ hostId: hostId, type: 3, reason }))
                .then((res) => {
                    toast.success("Host declined successfully.");
                    setDeclineModal(false); // Close modal on success
                    dispatch(getPendingHost(search === "" ? "ALL" : search, currentPage, rowsPerPage, type));
                    setReason("") // Clear reason input on success
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    };

    const handleProfile = (row, type) => {
        dispatch({
            type: OPEN_DIALOGUE,
            payload: {
                type: type,
                data: row,
            },
        });

        let dialogueData_ = {
            dialogue: true,
            type,
            dialogueData: row,
        };

        localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
        localStorage.setItem("realHostInfo", JSON.stringify(dialogueData_));
    };

    const mapData = [
        {
            Header: "NO",
            width: "20px",
            Cell: ({ index }) => (
                <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
            ),
        },
        {
            Header: "Author",
            body: "profilePic",
            Cell: ({ row }) => (
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleProfile(row, "hostInfo")}
                >
                    <div className="d-flex px-2 py-1">
                        <div>
                            <img
                                src={row?.profilePic || Male}
                                alt=""
                                loading="eager"
                                draggable="false"
                                style={{ borderRadius: "25px" }}
                                height={50}
                                width={50}
                                onError={(e) => e.target.src = Male}
                            />
                        </div>
                        <div className="d-flex flex-column justify-content-center text-start ms-3">
                            <b className="mb-0 text-sm text-capitalize ">{row.name}</b>
                            <div>
                                <div className="d-flex">
                                    {/* <PlaceIcon
                                        style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                                    /> */}
                                    <p
                                        className="text-xs text-secondary mb-0  text-capitalize"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {row?.countryId?.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            Header: "Unique Id",
            body: "uniqueId",
            Cell: ({ row }) => (
                <span className="text-success">
                    {row.uniqueId ? row.uniqueId : "-"}
                </span>
            ),
        },
        {
            Header: "Agency Name",
            body: "agencyId",
            Cell: ({ row }) => <span>{row?.agencyId?.name}</span>,
        },
        {
            Header: "Gender",
            body: "gender",
            Cell: ({ row }) => <span>{row.gender ? row.gender : "Female"}</span>,
        },
        {
            Header: "Coin",
            body: "coin",
            sorting: { type: "server" },
            Cell: ({ row }) => <span>{row?.coin}</span>,
        },
        {
            Header: "Created At",
            body: "createdAt",
            Cell: ({ row }) => (
                <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
            ),
        },

        // {
        //     Header: "Status",
        //     body: "",
        //     Cell: ({ row }) =>
        //         row?.isOnline === true &&
        //             row?.isLive === true &&
        //             row?.isBusy === true ? (
        //             <div
        //                 className="bg-primary py-1 px-2 d-inline-block text-white"
        //                 style={{
        //                     fontSize: "11px",
        //                     borderRadius: "5px",
        //                 }}
        //             >
        //                 Live
        //             </div>
        //         ) : row?.isOnline === true && row?.isBusy === true ? (
        //             <div
        //                 className="bgPink py-1 px-2 d-inline-block text-white"
        //                 style={{
        //                     fontSize: "11px",
        //                     borderRadius: "5px",
        //                 }}
        //             >
        //                 Busy
        //             </div>
        //         ) : row?.isOnline === true ? (
        //             <div
        //                 className="bg-success py-1 px-2 d-inline-block text-white"
        //                 style={{
        //                     fontSize: "11px",
        //                     borderRadius: "5px",
        //                 }}
        //             >
        //                 Online
        //             </div>
        //         ) : (
        //             <div
        //                 className="bg-danger py-1 px-2 d-inline-block text-white"
        //                 style={{
        //                     fontSize: "11px",
        //                     borderRadius: "5px",
        //                 }}
        //             >
        //                 Offline
        //             </div>
        //         ),
        // },

        {
            Header: "Accept",
            body: "",
            Cell: ({ row }) => (
                <>
                    <div onClick={() => {
                        handleAccept(row?._id)
                    }}> <FaCheck /></div>

                </>

            ),
        },
        {
            Header: "Decline",
            body: "",
            Cell: ({ row }) => (
                <>
                    <div style={{ fontSize: "20px" }} onClick={() => {
                        setDeclineModal(true)
                        setHostId(row?._id)
                    }}><MdOutlineCancel /></div>

                </>

            ),
        },
    ];
    return (
        <>
            {
                declineModal && (
                    <div className="mainDialogue fade-in" style={{ zIndex: "9999999" }}>
                        <div
                            className="Dialogue"
                            style={{ overflow: "auto", maxHeight: "100vh", width: "800px" }}
                        >
                            <div className="dialogueHeader">
                                <div className="headerTitle fw-bold">Reason</div>
                                <div
                                    className="closeBtn "
                                    onClick={() => {
                                        setDeclineModal(False)
                                        setReason("")
                                    }}
                                >
                                    <i className="fa-solid fa-xmark ms-2"></i>
                                </div>
                            </div>
                            <div
                                className="dialogueMain bg-white"
                                style={{ overflow: "auto", borderRadius: "10px" }}
                            >
                                <div className="col-12">
                                    <Input
                                        label={`Reason`}
                                        placeholder={`Reason`}
                                        id={`reason`}
                                        type={`reason`}
                                        value={reason}
                                        // errorMessage={error.reason && error.reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                            setError({}); // Clear error on input change
                                        }}
                                    />
                                    {
                                        error && (
                                            <div className="text-danger">{error.reason}</div>

                                        )
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <Button
                                            newClass={`themeFont mx-2 btn `}
                                            btnColor={`btn-outline-danger`}
                                            btnName={`Close`}
                                            onClick={() => {
                                                setDeclineModal(false)
                                                setReason("")
                                            }}
                                            style={{
                                                borderRadius: "10px",
                                                border: "1px solid red",
                                            }}
                                        />
                                        <Button
                                            newClass={`themeFont mx-2 btn`}
                                            btnColor={`btn-outline-success`}
                                            btnName={`Submit`}
                                            onClick={handleSubmit}
                                            style={{
                                                borderRadius: "10px",
                                                border: "1px solid green",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="userHeader primeHeader">
                <Title name={`Pending Host`} />
            </div>
            <div className="primeHeader mt-4 row">
                <div className="d-flex justify-content-end col-md-6">
                    {/* <div className="userHeader primeHeader">
                        <Title name={`Pending Host`} />
                    </div>
                    <Searching
                        type={"server"}
                        data={pendingHost}
                        setData={setData}
                        setSearchData={setSearch}
                        onFilterData={handleFilterData}
                        serverSearching={handleFilterData}
                        button={true}
                        column={mapData}
                        placeholder={"Searching Host..."}
                    /> */}
                </div>
            </div>
            <div className="userMain">
                <div className="tableMain mt-3">
                    <Table
                        data={data}
                        mapData={mapData}
                        serverPerPage={rowsPerPage}
                        serverPage={currentPage}
                        type={"server"}
                    // onChildValue={handleChildValue}
                    />
                </div>

                <Pagination
                    component="div"
                    count={pendingHost?.length}
                    type={"server"}
                    serverPerPage={rowsPerPage}
                    totalData={total}
                    serverPage={currentPage}
                    onPageChange={handleChangePage}
                    setCurrentPage={setCurrentPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    )
}
export default PendingHost