import React from "react";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import ToggleSwitch from "../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import {
  hostAgency,
  approvedDisableSwitch,
  blockUnblockSwitch,
} from "../../store/host/host.action";
import { useLocation, useNavigate } from "react-router-dom";
import Searching from "../../extra/Searching";
import HostInfo from "../Host/RealHost/HostInfo";

import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import HostDialog from "../Host/RealHost/HostDialog";
import HostHistory from "../Host/RealHost/History/HostHistory";

const AgencyWiseHost = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  // const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");

  const dispatch = useDispatch();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { agencyWiseHost, total, host } = useSelector((state) => state.host);
  const { state } = useLocation();
  
  useEffect(() => {
    setData(agencyWiseHost);
  }, [agencyWiseHost]);

  useEffect(() => {
    props.hostAgency(search, currentPage, rowsPerPage, state?.id);
  }, [search, currentPage, rowsPerPage]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  const handleClickBlock = (userDetails) => {
    props.blockUnblockSwitch(
      userDetails,
      userDetails?.isBlock === true ? false : true
    );
  };

  // const handlePrevious = (url) =>{
  //   window.open(url,"_blank")
  // }
  const handelSettlement = (row, type) => {
    ;
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  const mapData = [
    {
      Header: "NO",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "AUTHOR",
      body: "profilePic",
      Cell: ({ row }) => (
        <div
          onClick={() => handleProfile(row, "hostInfo")}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex px-2 py-1">
            <div>
              <img
                src={row?.profilePic}
                alt=""
                draggable="false"
                style={{ borderRadius: "25px", objectFit: "cover" }}
                height={50}
                width={50}
                // onClick={()=>handlePrevious(row?.profilePic)}
              />
            </div>
            <div className="d-flex flex-column justify-content-center text-start ms-3">
              <b className="mb-0 text-sm text-capitalize ">{row?.name}</b>
              <div>
                <div className="d-flex">
                  <PlaceIcon
                    style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                  />
                  <p
                    className="text-xs text-secondary mb-0  text-capitalize"
                    style={{ fontSize: "12px" }}
                  >
                    {row?.countryId?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: "Unique Id",
      body: "uniqueId",
      Cell: ({ row }) => <span>{row.uniqueId ? row.uniqueId : "-"}</span>,
    },
    {
      Header: "Agency Name",
      body: "agencyId",
      Cell: ({ row }) => <span>{row?.agencyId?.name}</span>,
    },
    {
      Header: "Gender",
      body: "gender",
      Cell: ({ row }) => <span>{row.gender}</span>,
    },
    {
      Header: "Coin",
      body: "coin",
      sorting: { type: "client" },
      Cell: ({ row }) => <span>{row?.coin}</span>,
    },
    {
      Header: "Call Rate",
      body: "callCharge",
      sorting: { type: "client" },
      Cell: ({ row }) => <span>{row?.callCharge}</span>,
    },
    {
      Header: "Last Settlement Coin",
      body: "lastSettlementCoin",
      Cell: ({ row }) => (
        <span>{row.lastSettlementCoin ? row.lastSettlementCoin : "0"}</span>
      ),
    },
    {
      Header: "Received Coin",
      body: "receiveCoin",
      sorting: { type: "client" },
      Cell: ({ row }) => <span>{row.receiveCoin}</span>,
    },
    {
      Header: "Received Gift",
      body: "receiveGift",
      sorting: { type: "client" },
      Cell: ({ row }) => <span>{row.receiveGift}</span>,
    },
    {
      Header: "App Version",
      body: "appVersion",
      Cell: ({ row }) => <span>{row.appVersion}</span>,
    },
    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Last Alive",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span>{dayjs(row.lastLogin).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Status",
      body: "",
      Cell: ({ row }) =>
        row?.isOnline === true && row?.isLive === true ? (
          <div
            className="bg-primary py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Live
          </div>
        ) : row?.isOnline === true && row?.isbusy === true ? (
          <div
            className="bgPink py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Busy
          </div>
        ) : row?.isOnline === true ? (
          <div
            className="bg-success py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Online
          </div>
        ) : (
          <div
            className="bg-danger py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Offline
          </div>
        ),
    },
    {
      Header: "Block Host",
      body: "isDisable",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isBlock}
          onClick={() => handleClickBlock(row)}
        />
      ),
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn textPink fs-5 pointer`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: {
                data: row,
                type: "Host",
                extraData: { agency: false },
              },
            });
          }}
        />
      ),
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-white fs-6 border`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-info`}
          onClick={() => handleProfile(row, "hostInfo")}
        />
      ),
    },
    {
      Header: "History",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-success fs-5`}
          btnColor={``}
          // btnName={`${row.block ? "Block" : "Unblock"}`}
          btnIcon={`fa-solid fa-history`}
          onClick={() => handelSettlement(row, "hostHistory")}
        />
      ),
    },

    // add more columns as needed
  ];



  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
    localStorage.setItem("agencyWiseHost", JSON.stringify(dialogueData_));
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  return (
    <>
      <div className="mainUserTable">
        {dialogue && dialogueType == "hostInfo" && (
          <div className="mainHostInfo">
            <HostInfo />
          </div>
        )}
        {dialogue && dialogueType == "hostHistory" && (
          <div className="mainHostInfo">
            <HostHistory />
          </div>
        )}
        <div
          className="userTable"
          style={{
            display: `${
              dialogueType === "hostInfo"
                ? "none"
                : dialogueType === "hostHistory"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Agency Host`} />
            </div>
          </div>
          <div className="primeHeader mt-4 row">
            <div className="col-md-6 my-auto">
              <h5 className="mb-0 fw-bold">
                <span className="text-primary h4">
                  {agencyWiseHost[0]?.agencyId?.name
                    ? agencyWiseHost[0]?.agencyId?.name
                    : state?.agencyName}{" "}
                </span>
                's Host
              </h5>
            </div>
            <div className="d-flex justify-content-end col-md-6 mt-2">
              <Searching
                type={"server"}
                data={agencyWiseHost}
                setData={setData}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                column={mapData}
                placeholder={"Searching Host..."}
              />
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
              />
              <Pagination
                component="div"
                count={agencyWiseHost?.length}
                serverPage={currentPage}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={total}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
        {dialogue && dialogueType === "Host" && <HostDialog />}
      </div>
    </>
  );
};

export default connect(null, {
  hostAgency,
  approvedDisableSwitch,
  blockUnblockSwitch,
})(AgencyWiseHost);
