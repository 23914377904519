import { Link } from "react-router-dom";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import {
  getPlan,
  deleteCoinPlan,
  isActiveCoinPlan,
  isTopCoinPlan,
} from "../../store/coinPlan/coinPlan.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import CoinPlanDialogue from "./CoinPlanDialogue";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import EditInfo from "../../../assets/images/Edit.png";
import Delete from "../../../assets/images/delete.png";
const CoinPlan = (props) => {

  const { coinPlan } = useSelector((state) => state.coinPlan);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );   
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [search, setSearch] = useState("ALL");
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getPlan(search == "" ? "ALL" : search));
  }, [dispatch, search]);



  useEffect(() => {
    setData(coinPlan);
  }, [coinPlan]);

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteCoinPlan(id);
        }
      })
      .catch((err) => console.log(err));
  };
  // Active Plan
  const handleClick = (id) => {
    props.isActiveCoinPlan(id);
  };

  const handleIsTop = (id) => {
    props.isTopCoinPlan(id);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Coin Plan Table
  const planTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    { Header: "Coin", body: "coin", sorting: { type: "client" } },
    { Header: "Extra Coin", body: "extraCoin", sorting: { type: "client" } },
    { Header: "INR", body: "rupee", sorting: { type: "client" } },
    { Header: "USD", body: "dollar", sorting: { type: "client" } },

    { Header: "Tag", body: "tag" },
    {
      Header: "Created Date",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Active",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isActive}
          onClick={() => handleClick(row?._id)}
        />
      ),
    },
    {
      Header: "Is Top",
      body: "isTop",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isTop}
          onClick={() => handleIsTop(row?._id)}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <img
        src={EditInfo}
        height={30}
        width={30}
        alt="Edit"
        onClick={() =>
          dispatch({
            type: OPEN_DIALOGUE,
            payload: { data: row, type: "plan" },
          })
        }
      />
      ),
    },
    {
      Header: "Delete",
      body: "delete",

      Cell: ({ row }) => (
        <img
        src={Delete}
        height={30}
        width={30}
        alt="Delete"
        onClick={() => handleDelete(row?._id)}
      />
      ),
    },

    // add more columns as needed
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="planHeader primeHeader">
            <div className="col">
              <Title name={`Plan`} />
            </div>
            <div className="row g-0 mt-3">
              <div className="col-12 boxBetween ">
                <div className="col-md-6 col-sm-12 my-2">
                  <Button
                    newClass={`border text-white userBtn`}
                    btnColor={`bg-primary`}
                    btnName={`Add Plan`}
                    btnIcon={`fas fa-plus fa-md`}
                    onClick={() => {
                      dispatch({
                        type: OPEN_DIALOGUE,
                        payload: { type: "plan" },
                      });
                    }}
                    style={{
                      borderRadius: "5px",
                      width: "120px",
                    }}
                  />

                  {dialogue && dialogueType === "plan" && <CoinPlanDialogue />}
                </div>
                <div className="d-flex justify-content-end col-md-6 col-sm-12">
                  <Searching
                    type={"server"}
                    data={coinPlan}
                    setData={setData}
                    setSearchData={setSearch}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    button={true}
                    column={planTable}
                    placeholder={"Searching Coin plan..."}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                type={"client"}
                data={data}
                mapData={planTable}
                PerPage={rowsPerPage}
                Page={page}
              />
            </div>
            <Pagination
              type={"client"}
              component="div"
              count={coinPlan?.length}
              serverPage={page}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={coinPlan.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* <Table data={data} columns={planTable} searching={true} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getPlan,
  deleteCoinPlan,
  isActiveCoinPlan,
  isTopCoinPlan,
})(CoinPlan);
