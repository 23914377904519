import  axios, { Axios } from "axios";
import { data } from "jquery";
import { setToast } from "../../util/toast";
import * as ActionType from "./fakeStory.type";
import { apiInstanceFetch } from "../../util/api";


export const getAllStories = (start,limit) => (dispatch) => {
    apiInstanceFetch
    .get(`admin/story/getFakeStory?start=${start}&limit=${limit}`)
    .then((res) =>{
        
        dispatch({
            type: ActionType.GETALL_FAKE_STORY,
            payload: res.story
          });
    }).catch((error) => console.log("error", error.message));
}

export const createFakeStory = (data) => (dispatch) => {
  
    axios
      .post(`admin/story/addFake`, data)
      .then((res) => {
        
        if (res.data.status) {
          dispatch({ type: ActionType.CREATE_FAKE_STORY, payload: res.data.story });
          setToast("success", "Reel created successfully!");
       
        } else {
          setToast("error", res.data.message);
        }
      })
      .catch((error) => setToast("error", error.message));
  };

  export const deleteStory = (id) => (dispatch) => {
    
    axios
    .delete(`client/host/story/${id}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_FAKE_STORY,
          payload: id,
        });
        setToast("success", "Story Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
  }