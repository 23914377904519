import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getHostReport, getUserReport } from "../../store/report/report.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Title from "../../extra/Title";
import Pagination from "../../extra/Pagination";
// import FeedBackDialog from "./FeedBackDialog";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
// import SolvedFeedBackDialog from "./SolvedFeedBackDialog";
import Male from "../../../assets/images/boy.jpg";
const Report = (props) => {
  const { userReport, hostReport, total } = useSelector(
    (state) => state.userReport
  );
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [type, setType] = useState(1);

  const navigate = useNavigate();
  const handleFeedBack = (id) => {
    // props.feedBackSolved(id);
  };
  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 20));
    setCurrentPage(1);
  };

  useEffect(() => {
    type === 1 && dispatch(getUserReport(currentPage, rowsPerPage));
    type === 2 && dispatch(getHostReport(currentPage, rowsPerPage));
  }, [type, currentPage, rowsPerPage]);

  useEffect(() => {
    type === 1 && setData(userReport);
    type === 2 && setData(hostReport);
  }, [type, userReport, hostReport]);

  const handleInfo = (id) => {
    type === 2 &&
      navigate("/admin/hostInfo", {
        state: id,
      });
    type === 1 &&
      navigate("/admin/userInfo", {
        state: id,
      });
  };

  //   const handleUserFeedBack = () => {
  //     setType(1);
  //   };

  //   const handleHostFeedBack = () => {
  //     setType(2);
  //   };

  return (
    <>
      <div className="mainUserTable">
        {/* {dialogue && dialogueType === "feedBack" && <FeedBackDialog />}
        {dialogue && dialogueType === "SolvedFeedBack" && (
          <SolvedFeedBackDialog />
        )} */}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Report Details`} />
            </div>
          </div>
          <div className="d-flex row pe-4 mt-4 px-3">
            {type == 1 ? (
              <div
                className="d-flex fs-4 justify-content-start fw-bold align-items-center col-6 ps-4"
                style={{ color: "rgb(56 157 218)" }}
              >
                User Report
              </div>
            ) : (
              <div className="d-flex fs-4 primeColor justify-content-start fw-bold align-items-center col-6 ps-4">
                Host Report
              </div>
            )}

            <div className="d-flex justify-content-end col-6">
              <Button
                btnName={`User`}
                newClass={`text-white ms-2`}
                onClick={() => setType(1)}
                style={{
                  width: "100px",
                  borderRadius: "5px",
                  background: "rgb(56 157 218)",
                }}
              />
              <Button
                btnName={`Host`}
                newClass={`bgInfo text-white ms-2 btnPrime`}
                onClick={() => setType(2)}
                style={{ width: "100px", borderRadius: "5px" }}
              />
            </div>
          </div>
          <div className="userMain primeMain">
            <div className="tableMain mt-3">
              <div className="table-responsive shadow-sm">
                <table
                  className="table table-hover card-table table-vcenter text-nowrap"
                  style={{ borderSpacing: "0px" }}
                >
                  <thead className="reportBorder">
                    <tr className="text-center reportTable">
                      <th width="50px" className="fw-bold fs-14 ">
                        No
                      </th>
                      <th width="270px" className="fw-bold fs-14">
                        Avatar
                      </th>
                      <th width="220px" className="fw-bold fs-14">
                        Name
                      </th>
                      {/* <th width="210px" className="fw-bold fs-18">
                        Country
                      </th> */}
                      <th width="180px" className="fw-bold fs-14">
                        Count
                      </th>
                      <th width="120px" className="fw-bold fs-14">
                        Coin
                      </th>
                      <th width="100px" className="fw-bold fs-14">
                        Info
                      </th>
                    </tr>
                  </thead>
                </table>

                {data?.length > 0 ? (
                  data?.map((repo, ids) => {
                    return (
                      <>
                        <div class="accordion" id={`accordionExample`}>
                          <div class="accordion-item userTable">
                            <h2 class="accordion-header" id={`heading${ids}`}>
                              <button
                                class="accordion-button justify-content-between p-0 collapsed "
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${ids}`}
                                aria-expanded="true"
                                aria-controls={`collapse${ids}`}
                              >
                                <table
                                  className="w-100 table m-0 text-nowrap"
                                  style={{ borderSpacing: "0px" }}
                                >
                                  <tr
                                    className="text-center py-0 "
                                    style={{
                                      fontSize: "13px",
                                      borderStyle: "hidden",
                                    }}
                                  >
                                    <td
                                      width="41px"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {(currentPage - 1) * rowsPerPage +
                                        parseInt(ids) +
                                        1}
                                    </td>

                                    <td className="p-0" width="221px">
                                      <img
                                        src={repo?.image || Male}
                                        alt="Profile"
                                        className="m-auto table_image "
                                        height={80}
                                        width={80}
                                        style={{
                                          borderRadius: "50px",
                                        }}
                                        onError={(e) => e.target.src = Male}
                                      />
                                    </td>
                                    <td
                                      width="179px"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {repo?.name}
                                    </td>
                                    {/* <td
                                      width="170px"
                                      className="text-warning fw-bolder"
                                      style={{ fontSize: "18px" }}
                                    >
                                      {repo._id.country}
                                    </td> */}
                                    <td
                                      width="150px"
                                      className="text-info fw-bolder"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {repo?.count}
                                    </td>
                                    <td
                                      width="105px"
                                      className="text-danger fw-bolder"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {repo?.coin}
                                    </td>
                                    <td
                                      width="75px"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary bg-primary btn-icon p-0 py-2"
                                      >
                                        <i
                                          class="fa-solid fa-info text-white"
                                          style={{
                                            borderRadius: "5px",
                                            margin: "auto",
                                            width: "40px",
                                          }}
                                        ></i>
                                      </button>
                                    </td>
                                  </tr>
                                </table>
                              </button>
                            </h2>
                            <div
                              id={`collapse${ids}`}
                              class="accordion-collapse collapse"
                              aria-labelledby={`heading${ids}`}
                              data-bs-parent={`#accordionExample`}
                              style={{ backgroundColor: "#f6f6f6" }}
                            >
                              <div class="accordion-body py-4">
                                <h5 className="fw-semibold text-primary">
                                  Report {type == 1 ? "User" : "Host"} Details
                                </h5>
                                <table className="w-100 m-0 text-nowrap">
                                  <tr className="text-center border-bottom">
                                    <th width="50px" className="fw-bold">
                                      No
                                    </th>
                                    <th width="350px" className="py-3">
                                      Avatar
                                    </th>
                                    <th width="350px" className="py-3">
                                      Name
                                    </th>
                                    {/* <th width="100px" className="py-3">
                                      Country
                                    </th> */}
                                    <th width="350px" className="py-3">
                                      Coin
                                    </th>
                                    <th className="py-3">Description</th>
                                  </tr>
                                  {repo?.data?.map((com, index) => {
                                    return (
                                      <>
                                        <tr className="text-center border-bottom py-2 mb-2 ">
                                          <td className="py-2 ">{index + 1}</td>
                                          <td className="py-2 ">
                                            <img
                                              src={
                                                type == 1
                                                  ? com?.hostImage
                                                  : com?.userImage ?
                                                    type == 1
                                                      ? com?.hostImage
                                                      : com?.userImage : Male
                                              }
                                              alt="Profile"
                                              height={50}
                                              width={50}
                                              style={{
                                                borderRadius: "25px",
                                              }}
                                              onError={(e) => e.target.src = Male}
                                              className="table_image m-auto"
                                            />
                                          </td>
                                          <td className="py-2 ">
                                            {type == 1
                                              ? com?.hostName
                                              : com?.userName}
                                          </td>
                                          {/* <td className="py-2  text-danger fw-bolder">
                                            {com?.user?.country}
                                          </td> */}
                                          <td className="py-2  text-success fw-bolder">
                                            {type == 1
                                              ? com?.hostCoin
                                              : com?.userCoin}
                                          </td>
                                          <td
                                            className="py-2"
                                            style={{
                                              maxWidth: "5px",
                                              whiteSpace: "nowrap",
                                              overflow: "auto",
                                            }}
                                          >
                                            {com?.description}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <table className="w-100 table table-hover card-table table-vcenter text-nowrap border-bottom">
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data Found !
                      </td>
                    </tr>
                  </table>
                )}
              </div>
            </div>
            <Pagination
              component="div"
              count={userReport?.length}
              serverPage={currentPage}
              type={"server"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={total}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(null, {
  getHostReport,
  getUserReport,
})(Report);
