import * as ActionType from "./agency.type";

const initialState = {
  agency: [],
  agencyCommission: [],
  totalData: 0,
  agencyHistory: {},
  totalCoin: null,
};

export const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_AGENCY:
      return {
        ...state,
        agency: action.payload.data,
        totalData: action.payload.total,
      };

    case ActionType.ADD_AGENCY:
      let data = [...state.agency];
      data.unshift(action.payload);
      return {
        ...state,
        agency: data,
      };

    case ActionType.AGENCY_DISABLE:
      return {
        ...state,
        agency: state.agency.map((agencyDisable) => {
          if (agencyDisable._id === action.payload.id)
            return action.payload.data;
          else return agencyDisable;
        }),
        // userProfile: action.payload.data,
      };

    case ActionType.EDIT_AGENCY:
      return {
        ...state,
        agency: state.agency.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };

    case ActionType.AGENCY_HISTORY:
      return {
        ...state,
        agencyHistory: action.payload.history,
        totalData: action.payload.total,
        totalCoin: action.payload.totalCoin,
      };
    case ActionType.AGENCY_COMMISSION:
      return {
        ...state,
        agencyCommission: action.payload,
      };
    case ActionType.ADD_AGENCY_COMMISSION:
      return {
        ...state,
        agencyCommission: {
          ...state.agencyCommission,
          commission: action?.payload,
        },
      };

    // case ActionType.UPDATE_AGENCY_COMMISSION:
    //   return {
    //     ...state,
    //     agencyCommission: state.agencyCommission?.commission.map((data) => {
    //       if (data.commissionId === action.payload.id) {
    //         return action.payload.data;
    //       } else {
    //         return data;
    //       }
    //     }),
    //   };
    case ActionType.UPDATE_AGENCY_COMMISSION:
      return {
        ...state,
        agencyCommission: action.payload.data,
      };
    case ActionType.DELETE_AGENCY_COMMISSION:
      return {
        ...state,
        agencyCommission: {
          ...state.agencyCommission,
          commission: state.agencyCommission.commission.filter(
            (commission) => commission?._id !== action.payload.id
          ),
        },
      };
    default:
      return state;
  }
};
