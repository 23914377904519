import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { useScrollTrigger } from "@mui/material";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { feedBackSolved } from "../../store/feedback/feedback.action";

const SolvedFeedBackDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [adminDescription, setAdminDescription] = useState("");
  const [error, setError] = useState({ adminDescription: "" });
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!adminDescription) {
      let error = {};

      if (!adminDescription) error.adminDescription = "Description is required";
      return setError({ ...error });
    } else {
      props.feedBackSolved({ adminDescription }, dialogueData?._id);
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div
          className="Dialogue"
          style={{ overflow: "auto", maxHeight: "100vh" }}
        >
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Solved FeedbAck</div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>

          <div className="dialogueMain">
            <div className="col-12">
              <Input
                label={`Description/Reason`}
                id={``}
                type={`text`}
                value={adminDescription}
                errorMessage={error.adminDescription && error.adminDescription}
                onChange={(e) => {
                  setAdminDescription(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      adminDescription: `Description Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      adminDescription: "",
                    });
                  }
                }}
              />
            </div>

            <div className="dialogueFooter">
              <div className="dialogueBtn">
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={() => {
                    dispatch({ type: CLOSE_DIALOGUE });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { feedBackSolved })(SolvedFeedBackDialog);
