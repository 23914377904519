import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./coinPlan.type";
import jwt_decode from "jwt-decode";

const initialState = {
  coinPlan: [],
  total: null,
  // totalCoin: null,
  totalDollar: null,
  history: [],
};

export const coinPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_COIN_PLAN:
      return {
        ...state,
        coinPlan: action.payload,
      };
    case ActionType.CREATE_COIN_PLAN:
      let data = [...state.coinPlan];
      data.unshift(action.payload);
      return {
        ...state,
        coinPlan: data,
      };
    case ActionType.EDIT_COIN_PLAN:
      return {
        ...state,
        coinPlan: state.coinPlan.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.DELETE_COIN_PLAN:
      return {
        ...state,
        coinPlan: action.payload.data,
      };
    case ActionType.ACTIVE_SWITCH:
      return {
        ...state,
        coinPlan: state.coinPlan.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.PURCHASE_HISTORY:
      return {
        ...state,
        history: action.payload.data,
        // totalCoin: action.payload.totalCoin,
        total: action.payload.total,
        totalDollar: action.payload.totalDollar,
      };
    case ActionType.IS_TOP:
      
      return {
        ...state,
        coinPlan: action.payload.data,
      };
    default:
      return state;
  }
};
