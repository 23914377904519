import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../util/Alert";
import Button from "../../extra/Button";
import { createNewGift, editGift } from "../../store/Gift/gift.action";
import { getCategory } from "../../store/giftCategory/action";
import ReactDropzone from "react-dropzone";
import { baseURL } from "../../util/config";
import Cancel from "../../../assets/images/cancel.png"

const GiftDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { category } = useSelector((state) => state.category);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const [mongoId, setMongoId] = useState(0);
  const [coin, setCoin] = useState("");
  const [categories, setCategories] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState({
    coin: "",
    image: "",
    categories: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setError({
      image: "",
      coin: "",
      categories: "",
    });
    setCategories("");
    setCoin("");
    setImage([]);
  }, []);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setCoin(dialogueData?.coin);
    setCategories(dialogueData?.categoryId);
    setImagePath(dialogueData?.image ? baseURL + dialogueData?.image : "");
  
  }, [dialogueData]);

  
  const onPreviewDrop = (files) => {
    setError({ ...error, image: "" });
    files.forEach((file, index) => {
      
      Object.assign(file, { preview: URL.createObjectURL(file) });
      setImage((prevImages) => prevImages.concat(file));
    });
  };

  
  const handleImage = (e) => {
    // BOTH ARE WORKING STEP : 1
    // if (e.target.files[0]) {
    //   setImage(e.target.files[0]);
    //   const reader = new FileReader();
    //   reader.addEventListener("load", () => {
    //     setImagePath(reader.result);
    //   });
    //   reader.readAsDataURL(e.target.files[0]);
    // }

    // BOTH ARE WORKING STEP : 2

    // setError((prevErrors) => ({
    //   ...prevErrors,
    //   image: "",
    // }));
    // setImage(e.target.files[0]);
    // setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleDeleteImg = (file123, index) => {
    const newFiles = [...image];
    image.splice(newFiles.indexOf(file123), 1);
    if (file123.preview) {
      const images = image.filter((ele) => {
        return ele.preview !== file123.preview;
      });
      setImagePath(images);
      setImage(images);
    } else {
      const newFiles = [...image];
      image.splice(newFiles.indexOf(file123), 1);
      setImage(newFiles);
    }
  };

  const handleSubmit = (e) => {
    if (
      !coin ||
      coin < 0 ||
      !categories
      // (GiftClick !== null && (categories === "Select Category" || !categories))
    ) {
      let error = {};
      if (!coin) error.coin = "Coin is Required!";
      if (coin < 0) error.coin = "Invalid coin!";
      if (!image.length === 0 || !imagePath) error.image = "Image is Required!";
      if (!categories || categories === "Select categories name")
        error.categories = "Categories name is Required!";

      return setError({ ...error });
    } else {
      //   if (!hasPermission) return permissionError();

      const formData = new FormData();
      formData.append("coin", coin);
      if (dialogueData) {
        formData.append("image", image);
      } else {
        for (let i = 0; i < image.length; i++) {
          formData.append("image", image[i]);
        }
      }

      formData.append("categoryId", categories);

      if (mongoId) {
        props.editGift(formData, mongoId);
      } else {
        props.createNewGift(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <div className="mainDialogue fade-in">
      <div
        className="Dialogue"
        style={{ width: "500px", overflow: "auto", maxHeight: "100vh" }}
      >
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Gift Dialog</div>
          <div
            className="closeBtn closeDialogue text-center"
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
            {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label={`Coin`}
                id={`coin`}
                type={`number`}
                value={coin}
                errorMessage={error.coin && error.coin}
                onChange={(e) => {
                  setCoin(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      coin: `Coin is required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      coin: "",
                    });
                  }
                }}
              />
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label class="float-left dialog__input__title mb-2">
                  Category
                </label>
                {/* {GiftClick === null ? (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Category Name"
                    // value={categoryDetail?.name}
                  />
                ) : ( */}
                <>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={categories}
                    onChange={(e) => {
                      setCategories(e.target.value);
                      if (e.target.value === "Select Category") {
                        return setError({
                          ...error,
                          categories: "Please select a Category!",
                        });
                      } else {
                        return setError({
                          ...error,
                          categories: "",
                        });
                      }
                    }}
                  >
                    <option value="Select Category" selected>
                      Select Category
                    </option>
                    {category?.map((category12) => {
                      return (
                        <option value={category12._id}>
                          {category12.name}
                        </option>
                      );
                    })}
                  </select>
                  {error.categories && (
                    <div className="ml-2 mt-1">
                      {error.categories && (
                        <div className="pl-1 text__left">
                          <span className="text-error">{error.categories}</span>
                        </div>
                      )}
                    </div>
                  )}
                </>
                {/* )} */}
              </div>
            </div>

            <div className="col-12 mt-3">
              {!dialogueData ? (
                <div>
                  <label className="form-control-label" for="input-username">
                    Select (Multiple) image or GIF
                  </label>

                  <ReactDropzone
                    onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                    accept="image/*"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="me-4">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            style={{
                              height: 130,
                              width: 130,
                              border: "2px dashed gray",
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ paddingTop: 30, fontSize: 70 }}
                            ></i>
                          </div>
                          {/* <div className="img-container" style= {{display:"inline" , position:"relative" , float:"left"}}>
                                <i class="fas fa-times-circle text-danger" style= {{right:"10px" , position:"absolute" , float:"left" , top:"4px" ,cursor:"pointer" }}></i>
                                </div> */}
                        </div>
                      </section>
                    )}
                  </ReactDropzone>
                  {error.image && (
                    <div className="ml-2 mt-1">
                      {error.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{error.image}</span>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-lg-12 mt-3 fs-5">
                    {Array.isArray(image) && image.length > 0 && (
                      <>
                        {image.map((file123, index) => {
                          return (
                            <>
                              <div key={index}>
                                <img
                                  height="80px"
                                  width="80px"
                                  alt="app"
                                  className="cursor mx-3"
                                  src={
                                    !mongoId && !file123?.preview
                                      ? file123
                                      : file123?.preview
                                  }
                                  style={{
                                    boxShadow:
                                      "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                    border: "2px solid #fff",
                                    borderRadius: 10,
                                    marginTop: 10,
                                    float: "left",
                                    objectFit: "cover",
                                  }}
                                  draggable="false"
                                />

                                <div
                                  className="img-container"
                                  style={{
                                    display: "inline",
                                    position: "relative",
                                    float: "left",
                                  }}
                                >
                                  <i
                                    class="fas fa-times-circle text-danger"
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDeleteImg(file123, index)
                                    }
                                  ></i>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <Input
                  label={`Image`}
                  id={`image`}
                  type={`file`}
                  onChange={(e) => handleImage(e)}
                  errorMessage={error.image && error.image}
                />
              )}

              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath}
                    alt="banner"
                    draggable="false"
                    width={100}
                    className="m-0"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createNewGift, editGift, getCategory })(
  GiftDialog
);
