import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useTheme } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";


const Pagination = (props) => {
  const [pages, setPages] = useState([]);
  const {
    type,
    serverPage,
    setServerPage,
    serverPerPage,
    onPageChange,
    onRowsPerPageChange,
    totalData,
  } = props;


  const onPageChangeClient = (serverPage) => {
    setServerPage(serverPage);
  };

  useEffect(() => {
    const totalPages = Math.ceil(totalData / serverPerPage + 1);
    const range = Math.min(3, totalPages);
    const start = Math.max(1, serverPage - Math.floor(range / 2));
    const end = Math.min(start + range, totalPages);

    const pageNumbers = Array.from(
      { length: end - start },
      (_, i) => start + i
    );
    setPages(pageNumbers);
  }, [serverPage, totalData, serverPerPage]);

  const totalCount = Math.min((serverPage + 1) * serverPerPage, totalData);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, serverPage - 1);
  };

  //next page button
  const handleNextButtonClick = (event) => {
    onPageChange(event, serverPage + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / serverPerPage) - 1));
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(totalData / serverPerPage);
    return Array.from({ length: totalPages }, (_, i) => (
      <button
        key={i}
        className={`pageNumberButton ${
          i === serverPage ? "activePageNumberButton" : ""
        }`}
        onClick={(event) => onPageChange(event, i)}
      >
        {i + 1}
      </button>
    ));
  };

  return (
    <div className="pagination col-12 row gx-0">
      {type === "server" && totalData > 0 && (
        <>
          <div className="col-sm-12 col-md-4 pageCenterMob">
            <p className="count">
              Showing
              <span className="fw-bold text-primary">
                {Math.min(serverPage * serverPerPage + 1, totalData)}-
                {Math.min((serverPage + 1) * serverPerPage, totalData)}
              </span>
              From <span className="fw-bold text-primary">{totalData}</span>
            </p>
          </div>
          <div className="col-sm-12 col-md-8 boxCenter flex-wrap-reverse justify-content-md-end justify-content-sm-center">
            <button
              className="page-btn"
              disabled={serverPage === 0}
              onClick={handleFirstPageButtonClick}
            >
              <FirstPageIcon />
            </button>
            <button
              className="page-btn"
              disabled={serverPage === 0}
              onClick={handleBackButtonClick}
            >
              <KeyboardArrowLeft />
            </button>
            {renderPageNumbers()}
            <button
              className="page-btn"
              disabled={serverPage >= Math.ceil(totalData / serverPerPage) - 1}
              onClick={handleNextButtonClick}
            >
              <KeyboardArrowRight />
            </button>
            <button
              className="page-btn"
              disabled={serverPage >= Math.ceil(totalData / serverPerPage) - 1}
              onClick={handleLastPageButtonClick}
            >
              <LastPageIcon />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;
