import axios from "axios"
import * as ActionType from "./commission.type"
import { setToast } from "../../util/toast"
import { apiInstanceFetch } from "../../util/api"

export const getCommissionPlan =  () => (dispatch) =>{
  apiInstanceFetch
    .get("admin/projectSetting")
    .then((res) =>{
        if(res.status){
            dispatch({
                type:ActionType.GET_COMMISSION,
                payload:res.setting, 
            })
        } else{
            setToast("error", res.data)
        }
    })
    .catch((error) =>{
        setToast("error", error);
    })

}

export const addCommissionPlan = (body) => (dispatch) =>{
    axios
        .post(`admin/projectSetting`,body)
        .then((res)=>{
            
          if(res.data.status){
            dispatch({
                type: ActionType.ADD_COMMISSION,
                payload: res.data.setting,
            })
            setToast("Success","Commission Plan Created Successfully")
          }
          else{
            setToast("error",res.data.message)
          }
        })
        .catch((err)=>{
            setToast("error",err)
        })

}

export const updateCommissionPlan = (data,id) =>(dispatch) =>{
    axios
    .patch(`admin/projectSetting/${id}`,data)
    .then((res) => {    
      
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_COMMISSION,
          payload: { data: res.data.setting, id },
        });
        setToast("success", "Commission Plan update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
}

export const deleteCommissionPlan = (id) => (dispatch) =>{ 
    axios
      .delete(`admin/projectSetting/${id}`)
    .then((res)=>{
        if(res.data.status){
            dispatch({
                type: ActionType.DELETE_COMMISSION,
                payload: id,
            });
            setToast("Success","Commission Plan deleted Successfully");
        } else{
            setToast("error",res.data.message);
        }
    })
    .catch((error) =>setToast("error",error.message))
}