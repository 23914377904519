import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import Button from "../../../extra/Button";

import Input from "../../../extra/Input";
import * as XLSX from "xlsx";

import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setToast } from "../../../util/toast";
import { SEND_EXCEL } from "../../../store/FakeHost/fakeHost.type";
import cancel from "../../../../assets/images/cancel.png";

const CreateFakeHost = (props) => {
  const [excelData, setExcelData] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [excelFileData, setExcelFileData] = useState();

  const [error, setError] = useState({
    excelData: "",
  });
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    if (!excelData) {
      let error = {};
      if (!excelData) error.excelData = "No File is uploaded yet!";
      setError({ ...error });
    } else {
      const formData = new FormData();

      formData.append("file", excelFileData);

      // props.sendExcelApi(formData);

      axios
        .post(`/admin/host/insertFakeHostByExcel`, formData)
        .then((res) => {
          if (res.data.status) {
            dispatch({
              type: SEND_EXCEL,
              payload: res.data.hostData,
            });
            setToast("success", "Host created successfully !");
            dispatch({ type: CLOSE_DIALOGUE });
          } else {
            setError({ ...error, error: res.data.error });
          }
        })
        .catch((error) => {
          setError({ ...error, error: error.response?.data?.message });
        });
    }
  };

  const handleFile = (e) => {
    setError((prevErrors) => ({
      ...prevErrors,
      excelData: "",
    }));
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    setExcelFileData(e.target.files[0]);
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.onload = (e) => {
          const fileData = e.target.result;
          const workbook = XLSX.read(fileData, { type: "array" });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { defval: "" }); // Setting defval to empty string
          setExcelFile(fileData);
          setExcelData(data);
        };
        reader.readAsArrayBuffer(selectedFile);
      } else {
        setTypeError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  return (
    <div className="mainDialogue fade-in" style={{zIndex:"9999999"}}>
      <div className="faeHostDialog">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Fake Host Dialog</div>
          <div
            className="closeBtn "
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <img src={cancel} height={30} width={30} alt="Cancel" />
          </div>
        </div>
        <div
          className="dialogueMain bg-white mx-4"
          style={{ overflow: "auto" }}
        >
          <div className="row">
            {/* Excel */}
            <div>
              {/* <form className="form-group custom-form" onSubmit={handleFileSubmit}> */}
              {/* <input type="file" className="form-control" required onChange={handleFile} /> */}
              <label className="form-control-label mb-3" for="input-username">
                Upload Excel File
              </label>
              <Input
                type={`file`}
                onChange={handleFile}

                // newClass={`col-md-6 d-flex align-self-end`}
              />
              {/* <button type="submit" className="btn btn-success btn-md">UPLOAD</button> */}
              {error.excelData && (
                <div className="pl-1 text__left">
                  <span className="text-red">{error.excelData}</span>
                </div>
              )}
              {typeError && (
                <div className="alert alert-danger" role="alert">
                  {typeError}
                </div>
              )}
              {/* </form> */}
              <div className="viewer">
                {excelData && (
                  <div
                    className="table-responsive"
                    style={{ overflow: "scroll", maxHeight: "349px" }}
                  >
                    <table className="table excel-sheet-table">
                      <thead className="text-center">

                        <tr>
                          <th> No</th>
                          {excelData &&
                            excelData.length > 0 &&
                            Object.keys(excelData[0])?.map((key) => (
                              <th key={key}>{key}</th>
                            ))}
                        </tr>
                      </thead>

                      <tbody className="text-center">
                        {excelData?.map((individualExcelData, index) => (
                          <tr key={index}>
                            <td>{index+1}</td>
                            {Object.keys(individualExcelData).map((key) => (
                              <td key={key}>{individualExcelData[key]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div style={{ overflow: "scroll", maxHeight: "200px" }}>
                {error?.error?.map((errors) => (
                  <>
                    <div className="alert alert-danger" role="alert">
                      {errors}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(CreateFakeHost);
