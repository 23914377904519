import React from "react";
import Table from "../../../extra/Table";
import Button from "../../../extra/Button";
import Title from "../../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Pagination from "../../../extra/Pagination";
import {
  getFakeHost,
  deleteFakeHost,
  // ApprovedSwitch,
  // blockUnblockSwitch
} from "../../../store/FakeHost/fakeHost.action";
import HostInfo from "../RealHost/HostInfo";
import {
  approvedDisableSwitch,
  blockUnblockSwitch,
} from "../../../store/host/host.action";
import { useNavigate } from "react-router-dom";

import Searching from "../../../extra/Searching";

import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../../store/dialogue/dialogue.type";
import FakeHostDialog from "./FakeHostDialog";
import { baseURL } from "../../../util/config";
import CreateFakeHost from "./CreateFakeHost";
import Male from "../../../../assets/images/malePlace.png";
import { warning } from "../../../util/Alert";
const FakeHost = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");

  const [type, setType] = useState(2);

  
  const dispatch = useDispatch();

  const { fakeHost, total } = useSelector((state) => state.fakeHost);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    dispatch(
      getFakeHost(
        search === "" ? "ALL" : search,
        currentPage,
        rowsPerPage,
        type
      )
    );
  }, [search, currentPage, rowsPerPage, type]);

  useEffect(() => {
    setData(fakeHost);
  }, [fakeHost]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  const handleEdit = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  const handleCreate = (type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
      },
    });
  };

  const handleClickBlock = (userDetails) => {
    props.blockUnblockSwitch(
      userDetails,
      userDetails?.isBlock === true ? false : true
    );
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Author",
      body: "profilePic",
      Cell: ({ row }) => (
        <div>
          <div
            className="d-flex px-2 py-1"
            style={{ cursor: "pointer" }}
            onClick={() => handleProfile(row, "hostInfo")}
          >
            <div>
              <img
                src={row?.profilePic ? row?.profilePic : Male}
                alt=""
                onError={(e) => {
                  e.target.src = Male;
                }}
                loading="lazy"
                draggable="false"
                style={{
                  borderRadius: "25px",
                  objectFit: "cover",
                  boxSizing: "border-box",
                }}
                height={50}
                width={50}
              />
            </div>
            <div className="d-flex flex-column justify-content-center text-start ms-3">
              <b className="mb-0 text-sm text-capitalize ">{row?.name}</b>
              <div>
                <div className="d-flex">
                  <PlaceIcon
                    style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                  />
                  <p
                    className="text-xs text-secondary mb-0  text-capitalize"
                    style={{ fontSize: "12px" }}
                  >
                    {row?.countryId?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },

    {
      Header: "Unique Id",
      body: "uniqueId",
      Cell: ({ row }) => <span>{row?.uniqueId}</span>,
    },

    {
      Header: "Gender",
      body: "gender",
      Cell: ({ row }) => <span>{row?.gender}</span>,
    },

    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Last Active Time",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span>{dayjs(row?.lastLogin).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Block Host",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isBlock}
          onClick={() => handleClickBlock(row)}
        />
      ),
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn textPink fs-5`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          onClick={() => handleEdit(row, "fakeHostDialog")}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
          }}
        />
      ),
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-white fs-6 border`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-info`}
          onClick={() => handleProfile(row, "hostInfo")}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];
  const mapData1 = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Author",
      body: "profilePic",
      Cell: ({ row }) => (
        <div>
          <div
            className="d-flex px-2 py-1"
            style={{ cursor: "pointer" }}
            onClick={() => handleProfile(row, "hostInfo")}
          >
            <div>
              <img
                src={row?.profilePic ? row?.profilePic : Male}
                alt=""
                loading="lazy"
                draggable="false"
                style={{
                  borderRadius: "25px",
                  objectFit: "cover",
                  boxSizing: "border-box",
                }}
                height={50}
                width={50}
              />
            </div>
            <div className="d-flex flex-column justify-content-center text-start ms-3">
              <b className="mb-0 text-sm text-capitalize ">{row?.name}</b>
            </div>
          </div>
        </div>
      ),
    },

    {
      Header: "Unique Id",
      body: "uniqueId",
      Cell: ({ row }) => <span>{row?.uniqueId}</span>,
    },

    {
      Header: "Gender",
      body: "gender",
      Cell: ({ row }) => <span>{row?.gender}</span>,
    },

    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Last Active Time",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span>{dayjs(row?.lastLogin).format("DD MMM YYYY")}</span>
      ),
    },
  ];
  const handleInfo = (userId) => {
    navigate("/admin/hostInfo", {
      state: userId,
    });
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteFakeHost(id);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="mainUserTable fake-host-page">
        {dialogue && dialogueType == "fakeHostDialog" && (
          <div className="mainHostInfo">
            <FakeHostDialog />
          </div>
        )}
        {dialogue && dialogueType == "hostInfo" && (
          <div className="mainHostInfo">
            <HostInfo />
          </div>
        )}

        <div
          className="userTable"
          style={{
            display: `${dialogueType === "fakeHostDialog" ? "none" : "block"}`,
          }}
        >
          <div
            className="userTable"
            style={{
              display: `${dialogueType === "hostInfo" ? "none" : "block"}`,
            }}
          >
            <div className="userHeader primeHeader">
              <div className="row">
                <Title name={type === 2 ? "Fake Host" : "App Wise Demo Host"} />
              </div>
            </div>
            <div class="dropdown mt-2 ms-4">
              <button
                className="btn waves-effect waves-light btn-primary btn-sm float-left dropdown-toggle py-2"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {type === 2 ? "Fake Host" : "App Wise Demo Host"}
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
                style={{ backgroundColor: "white", marginTop: "10px" }}
              >
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => setType(2)}
                  >
                    Fake Host
                  </a>
                </li>
                {/* <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => setType(3)}
                  >
                    App Wise Demo Host
                  </a>
                </li> */}
              </ul>
            </div>
            {type === 2 && (
              <>
                <div className="primeHeader mt-4 row justify-content-center align-items-center">
                  <div className="col-md-6 justify-content-sm-between justify-content-ms-start gx-2 mt-2">
                    <Button
                      newClass="border bg-success text-white smBTN me-3"
                      btnIcon="fas fa-plus fa-md"
                      btnName="New Host"
                      onClick={() => handleCreate("fakeHostDialog")}
                      style={{ borderRadius: "7px" }}
                    />
                    <Button
                      newClass="border bg-primary text-white smBTN"
                      btnIcon="fas fa-plus fa-md"
                      btnName="Upload Excel"
                      onClick={() => {
                        dispatch({
                          type: OPEN_DIALOGUE,
                          payload: { type: "fakeHostExcel" },
                        });
                      }}
                      style={{ borderRadius: "7px" }}
                    />
                    {dialogue && dialogueType === "fakeHostExcel" && (
                      <CreateFakeHost />
                    )}
                  </div>
                  <div className="col-md-6 text-center pageSm ">
                    <Searching
                      type="server"
                      data={fakeHost}
                      setData={setData}
                      setSearchData={setSearch}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      column={mapData}
                      newClass={`w-100`}
                      placeholder="Searching Host..."
                    />
                  </div>
                </div>

                <div className="userMain">
                  <div className="tableMain mt-3">
                    <Table
                      data={data}
                      mapData={mapData}
                      serverPerPage={rowsPerPage}
                      serverPage={currentPage}
                      type={"server"}
                    />
                  </div>
                </div>
                <Pagination
                  component="div"
                  count={fakeHost?.length}
                  serverPage={currentPage}
                  type={"server"}
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={total}
                  setCurrentPage={setCurrentPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className="userFooter primeFooter"></div>
              </>
            )}

            {type === 3 && (
              <>
                <div className="primeHeader mt-4 row justify-content-center align-items-center">
                  <div className="col-md-6 justify-content-sm-between justify-content-ms-start gx-2 mt-2"></div>
                  <div className="col-md-6 text-center pageSm ">
                    <Searching
                      type="server"
                      data={fakeHost}
                      setData={setData}
                      setSearchData={setSearch}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      column={mapData}
                      newClass={`w-100`}
                      placeholder="Searching Host..."
                    />
                  </div>
                </div>

                <div className="userMain">
                  <div className="tableMain mt-3">
                    <Table
                      data={data}
                      mapData={mapData1}
                      serverPerPage={rowsPerPage}
                      serverPage={currentPage}
                      type={"server"}
                    />
                  </div>
                </div>
                <Pagination
                  component="div"
                  count={fakeHost?.length}
                  serverPage={currentPage}
                  type={"server"}
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={total}
                  setCurrentPage={setCurrentPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className="userFooter primeFooter"></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getFakeHost,
  approvedDisableSwitch,
  blockUnblockSwitch,
  deleteFakeHost,
})(FakeHost);
