import axios from "axios";
import * as ActionType from "./user.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getUser =
  (search, start, limit, sort, type, appName) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/user?search=${search}&start=${start}&limit=${limit}&sort=${sort}&sortType=${type}&appName=${appName}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GET_USER,
            payload: { data: res.user, totalUser: res.total },
          });
        } else {
          setToast("error", res.message);
        }
      })

      .catch((error) => console.error(error));
  };

export const blockSwitch = (user, block) => (dispatch) => {
  axios
    .put(`admin/user/${user._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.USER_BLOCK,
          payload: { data: res.data.user, id: user?._id },
        });
        setToast(
          "success",
          `${user.name} Is ${
            block === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

// user Profile
export const singleGetUser = (userID) => (dispatch) => {
  apiInstanceFetch
    .get(`client/user/profile?userId=${userID}`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: ActionType.GET_SINGLE_USERS, payload: res.user });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

// // UPDATE USER COIN
export const updateUserCoin = (val, ids) => (dispatch) => {
  axios
    .post(`admin/user/addLessCoin`, { userId: ids, coin: val })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_USER_COIN,
          payload: { editUserCoin: res.data.response },
        });
        setToast("success", "User Coin Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
};

//Get User History
export const getUserCallHistory =
  (userId, startDate, endDate, start, limit) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/history/callHistoryForUser?userId=${userId}&startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_USER_CALL_HISTORY,
          payload: {
            history: res.history,
            // totalCallCharge: res.data.totalCallCharge,
            total: res.total,
            totalCoin: res.totalCoin,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const getUserAllHistory =
  (id, type, startDate, endDate, start, limit) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/history/historyForUser?userId=${id}&startDate=${startDate}&endDate=${endDate}&type=${type}&start=${start}&limit=${limit}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GET_ALL_USER_HISTORY,
            payload: {
              history: res.history,
              total: res.total,
              totalCoin: res.totalCoin,
            },
          });
        }
      });
  };
