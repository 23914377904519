import * as ActionType from "./report.type";
const initialState = {
  userReport: [],
  total : null ,
  hostReport: [],
};
export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_USER_REPORT:
        
      return {
        ...state,
        userReport: action.payload.data,
        total: action.payload.total,
      };
      case ActionType.GET_HOST_REPORT :
        
        return{
            ...state,
            hostReport :action.payload.data,
            total :action.payload.total
        }
        default : return state
  }
};
