// get host
export const GET_FAKE_HOST = "GET_FAKE_HOST";

// CREATE HOST
export const CREATE_FAKE_HOST = "CREATE_FAKE_HOST";

// UPDATE HOST
export const UPDATE_FAKE_HOST = "UPDATE_FAKE_HOST";

//DISABLE SWITCH
export const DISABLE_FAKE_HOST = "DISABLE_FAKE_HOST";


export const DELETE_FAKE_HOST = "DELETE_FAKE_HOST";


// BLOCKED HOST

export const BLOCKED_HOST = "BLOCKED_HOST";

// Approved Host
export const APPROVED_HOST = "APPROVED_HOST";

export const SEND_EXCEL="SEND_EXCEL"

export const DELETE_HOST_IMG="DELETE_HOST_IMG"


export const GET_FAKE_HOST_FOR_STORY="GET_FAKE_HOST_FOR_STORY"