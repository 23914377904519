import { useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import { useEffect, useState } from "react";
import Button from "../../../extra/Button";
import {
  approvedHostImageRequest,
  approvedHostVideoRequest,
  getHost,
} from "../../../store/host/host.action";
import { toast } from "react-toastify";
import Male from "../../../../assets/images/male.jpg";
import cancel from "../../../../assets/images/cancel.png";

const HostVideo = ({ search, currentPage, rowsPerPage, sort, type }) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [updatedVideoStatus, setUpdatedVideoStatus] = useState({
    index: null,
    status: null,
  });

  const [imageStatuses, setImageStatuses] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (
      dialogueData?.privateVideo &&
      Array.isArray(dialogueData.privateVideo)
    ) {
      setUpdatedVideoStatus({ index: null, status: null });
    }
  }, [dialogueData]);

  // Handle dropdown change
  const handleStatusChange = (index, newStatus) => {
    setUpdatedVideoStatus({ index, status: newStatus });
  };
  const handleSubmit = () => {
    const data = {
      hostId: dialogueData?._id,
      position: updatedVideoStatus?.index,
      type: updatedVideoStatus?.status,
    };
    setSubmitted(true);
    dispatch(approvedHostVideoRequest(data)).then((res) => {
      if (res?.status) {
        toast.success(res?.message);
        dispatch(
          getHost(
            search === "" ? "ALL" : search,
            currentPage,
            rowsPerPage,
            sort,
            type
          )
        );
        dispatch({ type: CLOSE_DIALOGUE });
      } else {
        toast.error(res?.message);
      }
    });
    // Additional code to process the selected statuses if needed
  };
  return (
    <>
      <div className="mainDialogue fade-in" style={{ zIndex: "9999999" }}>
        <div
          className="Dialogue"
          style={{ overflow: "auto", maxHeight: "100vh", width: "800px" }}
        >
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Host Video Dialog</div>
            <div
              className="closeBtn closeDialogue text-center"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark" aria-hidden="true"></i>
              {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

            </div>
          </div>
          <div
            className="dialogueMain bg-white"
            style={{ overflow: "auto", borderRadius: "10px", padding: "16px" }}
          >
            {Array.isArray(dialogueData?.privateVideo) && dialogueData?.privateVideo.length > 0 ? (
              <div className="imageGrid">
                {dialogueData.privateVideo.map((video, index) => (
                  <div key={index} className="imageContainer">
                    <video
                      src={video?.url} // Assuming `video.url` contains the video URL
                      className="videoItem"
                      style={{ objectFit: "cover" }}
                      onError={(e) => {
                        // Fallback to an image if the video fails to load
                        const videoElement = document.createElement("video");
                        videoElement.src = Male; // Fallback image
                        videoElement.alt = `Image ${index}`;
                        videoElement.className = "imageItem";
                        videoElement.style.objectFit = "cover";
                        e.target.replaceWith(videoElement);
                      }}
                      height={150}
                      width={200}
                      controls // Optionally add controls for video playback
                    ></video>
                    {video?.verificationStatus === 2 || video?.verificationStatus === 3 ? (
                      // Show status text after submit
                      <span className="statusLabel">
                        <input
                          type="text"
                          disabled
                          className={`form-control form-input mt-2`}
                          value={
                            video?.verificationStatus === 2 ? "Verified" : "Rejected"
                          }
                        />
                      </span>
                    ) : (
                      // Show dropdown if not yet submitted
                      <select
                        className="form-select"
                        aria-label="Image status select"
                        value={
                          updatedVideoStatus.index === index
                            ? updatedVideoStatus.status
                            : video.verificationStatus
                        }
                        onChange={(e) =>
                          handleStatusChange(index, parseInt(e.target.value))
                        }
                      >
                        <option value="Select image status" disabled>
                          Select Image Status
                        </option>
                        <option value="1">Pending</option>
                        <option value="2">Verified</option>
                        <option value="3">Rejected</option>
                      </select>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              // Show "No Data Found" when there are no videos
              <div className="text-center">
                <p className="text-muted">No videos found.</p>
              </div>
            )}
          </div>

          {
            Array.isArray(dialogueData?.privateVideo) && dialogueData?.privateVideo.length > 0 && 
         
            <div className="dialogueFooter">
              <div className="dialogueBtn">
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={() => {
                    dispatch({ type: CLOSE_DIALOGUE });
                  }}
                />
              </div>
            </div>
          }
        
        </div>
      </div>
    </>
  );
};
export default HostVideo;
// export default connect(null, {
//     approvedHostImageRequest,
// })(HostImage);
