import React, { useState } from "react";
import Analytics from "../../../../extra/Analytics";
import Title from "../../../../extra/Title";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../../../extra/Table";
import Pagination from "../../../../extra/Pagination";
import moment from "moment";
import {
  getHostLiveHistory,
  getHostHistory,
} from "../../../../store/host/host.action";

import $ from "jquery";
// import male from "../../../../assets/images/maleImage.png";
import { useLocation, useNavigate } from "react-router-dom";

const HostLiveHistory = (props) => {
  const { history, totalCoin, total } = useSelector((state) => state.host);
  const { state } = useLocation();
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(state ? state?.startDate : "ALL");
  const [endDate, setEndDate] = useState(state ? state?.endDate : "ALL");
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  let propValue;
  if (state) {
    if (state.type === "agency") {
        propValue = dialogueData._id;
    } else {
        propValue = state.id;
    }
} else {
    propValue = dialogueData?._id;
}

  useEffect(() => {
    dispatch(getHostLiveHistory(
      propValue,
      currentPage,
      rowsPerPage,
      startDate,
      endDate
    ))
  }, [ propValue, currentPage, rowsPerPage, startDate, endDate]);

  useEffect(() => {
    setData(history);
  }, [history]);

  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const navigate = useNavigate();

  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Duration",
      body: "duration",
      Cell: ({ row }) => {
        return (
          <span className="fw-semibold fs-6">
            {moment.utc(row?.duration * 1000).format("HH:mm:ss")}
          </span>
        );
      },
    },
    {
      Header: "Coin",
      body: "coin",
      sorting: { type: "client" },
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-semibold">+{row?.coin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row?.coin}</span>
        ),
    },

    {
      Header: "Date",
      body: "date",
      sorting: { type: "client" },
      Cell: ({ row }) => {
        date = row?.endTime?.split(",") || "-";
        return (
          <span className="fs-6 fw-semibold">
            {date && date?.length > 0 ? date[0] : "N/A"}
          </span>
        );
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.endTime?.split(",") || "-";
        return (
          <span className="fs-6 fw-semibold">
            {date && date.length > 1 ? date[1] : "N/A"}
          </span>
        );
      },
    },
  ];
  return (
    <>
      <div className="row d-flex align-items-center  py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics
            analyticsStartDate={startDate}
            analyticsStartEnd={endDate}
            analyticsStartDateSet={setStartDate}
            analyticsStartEndSet={setEndDate}
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-2 d-flex justify-content-end">
              <Title name={`Total Coin : ` + totalCoin} />
            </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-2 d-flex justify-content-end">
              <Title
                name={
                  `Total Duration : ` +
                  moment.utc(totalLiveDuration * 1000).format("HH:mm:ss")
                }
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="userMain">
        <div className="primeMainHistory tableMain border-top">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={currentPage}
            type={"server"}
          />
        </div>
        <div className="paginationFooter">
          <Pagination
            component="div"
            count={history?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={total}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getHostLiveHistory })(HostLiveHistory);
