import { key } from "../../util/config";
import { SetDevKey, setToken } from "../../util/setAuth";
import * as ActionType from "./FlashVip.type";
import jwt_decode from "jwt-decode";

const initialState = {
  flashVip: [],
  total: null,
  history: [],
};

export const flashVipReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_FLASH_VIP_PLAN:
      return {
        ...state,
        flashVip: action.payload,
      };
    case ActionType.CREATE_FLASH_VIP_PLAN:
      let data = [...state.flashVip];
      data.unshift(action.payload);
      return {
        ...state,
        flashVip: data,
      };
    case ActionType.EDIT_FLASH_VIP_PLAN:
      
      return {
        ...state,
        flashVip: state.flashVip.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.DELETE_FLASH_VIP_PLAN:
      
      return {
        ...state,
        flashVip: state.flashVip.filter(
          (data) => data._id !== action.payload && data
        ),
      };
    case ActionType.ACTIVE_SWITCH:
      return {
        ...state,
        flashVip: state.flashVip.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    // case ActionType.PURCHASE_HISTORY:
    //   return {
    //     ...state,
    //     history: action.payload.history,
    //     totalCoin: action.payload.totalCoin,
    //     total: action.payload.total,
    //   };
    default:
      return state;
  }
};
