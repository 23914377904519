import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../util/Alert";
import Button from "../../extra/Button";
import { createNewBanner } from "../../store/vipBanner/banner.action";

import { baseURL } from "../../util/config";
import VipLevel from "./VipLevel";
import { createVipLevel, getVipLevel, updateVipLevel } from "../../store/VipLevel/level.action";
import { toast } from "react-toastify";
import Male from "../../../assets/images/male.jpg";
import Cancel from "../../../assets/images/cancel.png";

const VipLevelDialogue = (props) => {
    const { dialogueData } = useSelector((state) => state.dialogue);

    const { vipLevel } = useSelector((state) => state.vipLevel);
    const [level, setLevel] = useState(vipLevel?.length);
    const [levelImage, setLevelImage] = useState([]);
    const [imageLevelPath, setImageLevelPath] = useState("");
    const [error, setError] = useState({
        levelImage: "",
        icon: "",
        topUpAmount: ''
    });
    const [topUpAmount, setTopUpAmount] = useState("");
    const [icon, setIcon] = useState([])
    const [iconImagePath, setIconImagePath] = useState("")
    const dispatch = useDispatch();

    useEffect(() => {
        // setMongoId(dialogueData?._id);
        setLevel(dialogueData?.level);
        setTopUpAmount(dialogueData?.topUpAmount);
        setImageLevelPath(dialogueData?.levelImage);
        setIconImagePath(dialogueData?.icon);
        setIcon(dialogueData?.icon);
        setLevelImage(dialogueData?.levelImage);
    }, [dialogueData]);

    const handleImage = (e) => {
        // if (e.target.files[0]) {
        //     setLevelImage(e.target.files[0]);
        //     const reader = new FileReader();
        //     reader.addEventListener("load", () => {
        //         setImageLevelPath(reader.result);
        //     });
        //     reader.readAsDataURL(e.target.files[0]);
        // }
        setError((prevErrors) => ({
            ...prevErrors,
            levelImage: "",
        }));
        setLevelImage(e.target.files[0]);
        setImageLevelPath(URL.createObjectURL(e.target.files[0]));
    };
    const handleIconImage = (e) => {
        // if (e.target.files[0]) {
        //     setIcon(e.target.files[0]);
        //     const reader = new FileReader();
        //     reader.addEventListener("load", () => {
        //         setIconImagePath(reader.result);
        //     });
        //     reader.readAsDataURL(e.target.files[0]);
        // }
        setError((prevErrors) => ({
            ...prevErrors,
            icon: "",
        }));
        setIcon(e.target.files[0]);
        setIconImagePath(URL.createObjectURL(e.target.files[0]));
    };


    const handleSubmit = (e) => {
        let error = {};

        if (!levelImage) error.levelImage = "Level Image is required!";
        if (!icon) error.icon = "Icon is required!";
        if (!topUpAmount) error.topUpAmount = "Top Up Amount is required!";

        setError(error);

        // if (Object.keys(error).length === 0) {
        const formData = new FormData();
        formData.append("icon", icon);  // Binary file data
        formData.append("topUpAmount", topUpAmount);
        if (!dialogueData) {

            formData.append("level", vipLevel?.length);
        }
        formData.append("vipLevelId", dialogueData?._id);
        formData.append("levelImage", levelImage);  // Binary file data

        if (dialogueData) {
            props.updateVipLevel(formData)
                .then((res) => {
                    if (res?.status) {
                        dispatch({ type: CLOSE_DIALOGUE });
                        dispatch(getVipLevel());
                        toast.success("Vip Level updated successfully");
                    } else {
                        toast.error(res?.message);
                    }
                })
                .catch((error) => {
                    console.error("Error in updating VIP level:", error);
                });
        } else {
            props.createVipLevel(formData)
                .then((res) => {
                    if (res?.status) {
                        dispatch({ type: CLOSE_DIALOGUE });
                        dispatch(getVipLevel());
                        toast.success("Vip Level added successfully");
                    } else {
                        toast.error(res?.message);
                    }
                })
                .catch((error) => {
                    console.error("Error in creating VIP level:", error);
                });
        }
        // }
    };


    return (
        <div className="mainDialogue fade-in">
            <div
                className="Dialogue"
                style={{ width: "500px", overflow: "auto", maxHeight: "100vh" }}
            >
                <div className="dialogueHeader">
                    <div className="headerTitle fw-bold">Vip Level Dialog</div>
                    <div
                        className="closeBtn closeDialogue text-center"
                        onClick={() => {
                            dispatch({ type: CLOSE_DIALOGUE });
                        }}
                    >
                        <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        {/* <img src={cancel} height={30} width={30} alt="Edit" /> */}

                    </div>
                </div>
                <div className="dialogueMain">
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label={`Level`}
                                id={`level`}
                                type={`text`}
                                name={`level`}
                                disabled
                                value={level}
                                onChange={(e) => {
                                    setLevel(e.target.value)
                                }}
                                errorMessage={error.level && error.level}
                            />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label={`Top Amount`}
                                id={`topAmount`}
                                type={`text`}
                                name={`topAmount`}
                                value={topUpAmount}
                                onChange={(e) => {
                                    setTopUpAmount(e.target.value)
                                }}
                                errorMessage={error.topUpAmount && error.topUpAmount}
                            />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label={`Level Image`}
                                id={`image`}
                                type={`file`}
                                onChange={(e) => handleImage(e)}
                                errorMessage={error.levelImage && error.levelImage}
                            />
                            {imageLevelPath && (
                                <div className="image-start">
                                    <img
                                        src={imageLevelPath || Male}
                                        alt="level"
                                        draggable="false"
                                        width={100}
                                        height={100}
                                        className="m-0 cursor rounded"
                                        onClick={() => showImg(imageLevelPath)}
                                        onError={(e) => e.target.src = Male}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label={`Icon`}
                                id={`icon`}
                                type={`file`}
                                onChange={(e) => handleIconImage(e)}
                                errorMessage={error.icon && error.icon}
                            />
                            {iconImagePath && (
                                <div className="image-start">
                                    <img
                                        src={iconImagePath || Male}
                                        alt="level"
                                        draggable="false"
                                        width={100}
                                        height={100}
                                        className="m-0 cursor rounded"
                                        onClick={() => showImg(iconImagePath)}
                                        onError={(e) => e.target.src = Male}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="dialogueFooter">
                        <div className="dialogueBtn">
                            <Button
                                btnName={`Submit`}
                                btnColor={`btnBlackPrime text-white`}
                                style={{ borderRadius: "5px", width: "80px" }}
                                newClass={`me-2`}
                                onClick={handleSubmit}
                            />
                            <Button
                                btnName={`Close`}
                                btnColor={`bg-danger text-white`}
                                style={{ borderRadius: "5px", width: "80px" }}
                                onClick={() => {
                                    dispatch({ type: CLOSE_DIALOGUE });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { createVipLevel, updateVipLevel })(
    VipLevelDialogue
);
