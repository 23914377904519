import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./agency.type";
import { apiInstanceFetch } from "../../util/api";
export const getAgency = (search, start, limit, type) => (dispatch) => {
  apiInstanceFetch
    .get(
      `admin/agency?search=${search}&start=${start}&limit=${limit}&type=${type}`
    )
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_AGENCY,
          payload: { data: res.agency, total: res.total },
        });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const createAgency = (data) => (dispatch) => {
  axios
    .post(`admin/agency`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.ADD_AGENCY, payload: res.data.agency });
        setToast("success", "Agency Create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const disableSwitch = (agency, disable) => (dispatch) => {
  axios
    .put(`admin/agency/disable/${agency._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.AGENCY_DISABLE,
          payload: { data: res.data.agency, id: agency._id },
        });
        setToast(
          "success",
          `${agency.name} Is ${
            disable === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

export const updateAgency = (formData, id) => (dispatch) => {
  axios
    .patch(`admin/agency/update/${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_AGENCY,
          payload: { data: res.data.agency, id },
        });
        setToast("success", "Agency Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const getAgencyHitory =
  (id, startDate, endDate, search) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/history/agencyHistory?agencyId=${id}&startDate=${startDate}&endDate=${endDate}&search=${search}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.AGENCY_HISTORY,
          payload: {
            history: res.history,
            total: res.total,
            totalCoin: res.totalCoin,
          },
        });
      })
      .catch((error) => console.log(error));
  };
export const getAgencyCommision = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/agency/profile/${id}`)
    .then((res) => {
      dispatch({
        type: ActionType.AGENCY_COMMISSION,
        payload: res.agency,
      });
    })
    .catch((error) => console.log(error));
};
export const addAgencyCommission = (formData, id) => (dispatch) => {
  
  axios
    .patch(`admin/agency/addCommission?agencyId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.ADD_AGENCY_COMMISSION,
          payload: res.data.agency.commission,
        });
        setToast("success", "Commission  created Successfully !");
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const updateAgencyCommission = (formData, id) => (dispatch) => {
  
  axios
    .patch(`admin/agency/updateCommission?agencyId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
          dispatch({
          type: ActionType.UPDATE_AGENCY_COMMISSION,
          payload: { data: res.data.agency, id: formData?.commissionId },
        });
        setToast("success", "Agency Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
    });
};

export const deleteAgencyCommission =
  (commissionId, agencyId) => (dispatch) => {
    axios
      .delete(
        `admin/agency/deleteCommission?agencyId=${agencyId}&commissionId=${commissionId}`
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.DELETE_AGENCY_COMMISSION,
            payload:{data: res.data.agency,id:commissionId},
          });
          setToast("success", "Commission deleted successfully!");
        } else {
          setToast("danger", res.data.message);
        }
      })
      .catch((error) => console.log(error));
  };
