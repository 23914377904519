import React, { useState } from "react";
import ReactDom from 'react-dom';
import 'rc-time-picker/assets/index.css';
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import 'rc-time-picker/assets/index.css';
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import {
    createIncome,
    updateIncomePlan,
} from "../../store/income/income.action";
import Cancel from "../../../assets/images/cancel.png";
import { createReward, updateReward } from "../../store/reward/reward.action";
import moment from "moment";
import dayjs from "dayjs";

const RewardLevelDialogue = (props) => {
    const dispatch = useDispatch();
    const { dialogueData } = useSelector((state) => state.dialogue);
    const rewardData = useSelector((state) => state.reward.reward);
    const [mongoId, setMongoId] = useState();
    const [weeklyIncome, setWeeklyIncome] = useState();
    const [totalWorkTime, setTotalWorkTime] = useState();
    const [workTimeForPeakHours, setWorkTimeForPeakHours] = useState();
    const [level, setLevel] = useState(rewardData?.length + 1);
    const [timeZone , setTimeZone] = useState("");
    const [reward, setReward] = useState();
    const [startTime, setStartTime] = useState(dayjs("", "hh:mm A"));
    const [endTime, setEndTime] = useState(dayjs("", "hh:mm A"));
    const [openTime, setOpenTime] = useState("");
    const [closeTime, setCloseTime] = useState("");

    const [dispatchTime, setDispatchTime] = React.useState(moment());

    console.log("dialogueData", dialogueData)
    console.log("startTime" , openTime)
    console.log("closeTime" , closeTime)

    const format = 'h:mm a';
    const now = moment().hour(0).minute(0);

    console.log("openTime" , openTime)
    console.log("closeTime" , closeTime)


    const [levelName, setLevelName] = useState("");
    const [error, setError] = useState({
        workTimeForPeakHours: "",
        totalWorkTime: "",
        levelName: "",
        weeklyIncome: "", 
        startTime: "",
        endTime : "",
        timeZone : "",
        reward : ""
    });



    useEffect(() => {
        setLevel(dialogueData?.level || rewardData?.length + 1 || 0);
        setMongoId(dialogueData?._id);
        setTotalWorkTime(dialogueData?.totalWorkTime);
        setWorkTimeForPeakHours(dialogueData?.workTimeForPeakHours);
        setWeeklyIncome(dialogueData?.weeklyIncome);
        setLevelName(dialogueData?.levelName);
        setTimeZone(dialogueData?.peakHours?.timeZone);
        setReward(dialogueData?.reward)
        if (dialogueData?.peakHours?.startTime) {
            setStartTime(dayjs(dialogueData.peakHours.startTime, "hh:mm A"));
        }
        if (dialogueData?.peakHours?.endTime) {
            setEndTime(dayjs(dialogueData.peakHours.endTime, "hh:mm A"));
        }
    }, [dialogueData]);



    const handleEndTimeChange = (value) => {
        
        if (value && value.isValid()) { // Check if the value is valid
            const formattedTime = value.format("hh:mm A"); // Format the time as required
            setCloseTime(formattedTime)
            console.log("formattedTime", formattedTime);
            setEndTime(value); // Update the state with the full dayjs object
            setError({ ...error, endTime: "" }); // Clear any existing errors
        } else {
            setError({ ...error, endTime: "Invalid End Time" });
        }
    };

    const handleStartTimeChange = (value) => {
        if (value && value.isValid()) { // Check if the value is valid
            const formattedTime = value.format("hh:mm A"); // Format the time as required
            setOpenTime(formattedTime)
            console.log("formattedTime" , formattedTime);
            setStartTime(value); // Update the state with the full dayjs object
            setError({ ...error, startTime: "" }); // Clear any existing errors
        } else {
            setError({ ...error, startTime: "Invalid Start Time" });
        }
    };



    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            !weeklyIncome ||
            !startTime ||
            !workTimeForPeakHours ||
            !totalWorkTime ||
            !levelName || 
            !endTime ||
            !reward
        ) {
            const error = {};

            if ((weeklyIncome < 0 || !weeklyIncome)) {
                error.weeklyIncome = "Weekly Income is required!";
            }
            if ((workTimeForPeakHours < 0 || !workTimeForPeakHours)) {
                error.workTimeForPeakHours = "WorkTime For PeakHours is required!";
            }
            if ((!totalWorkTime || totalWorkTime < 0)) {
                error.totalWorkTime = "Total WorkTime is Required!";
            }
            if (!levelName) error.levelName = "Level Name is required!";
            if (!startTime) error.startTime = "Start Time is required!";
            if (!endTime) error.endTime = "End Time is required!";
            if (!timeZone) error.timeZone = "TimeZone is required!";
            if (reward < 0 || !reward) error.reward = "Reward is required!";

            return setError({ ...error });
        }

        const data = {
            levelName,
            level: parseInt(level),
            reward,
            weeklyIncome: parseInt(weeklyIncome),
            totalWorkTime: parseInt(totalWorkTime),
            workTimeForPeakHours: parseInt(workTimeForPeakHours),
            startTime: openTime,
            endTime : closeTime,
            timeZone ,
        };

        if (mongoId) {
            const updateData = {
                rewardLevelId: dialogueData?._id,
                level: parseInt(level),
                reward : parseInt(reward),
                levelName,
                weeklyIncome: parseInt(weeklyIncome),
                totalWorkTime: parseInt(totalWorkTime),
                workTimeForPeakHours: parseInt(workTimeForPeakHours),
                startTime: openTime,
                endTime : closeTime,
                timeZone
            };
            dispatch(updateReward(updateData, mongoId));
        } else {
            dispatch(createReward(data));
        }
        dispatch({ type: CLOSE_DIALOGUE });
    };

    return (
        <>
            <div className="mainDialogue fade-in">
                <div className="Dialogue">
                    <div className="dialogueHeader">
                        <div className="headerTitle fw-bold">Reward</div>
                        <div
                            className="closeBtn boxCenter"
                            onClick={() => {
                                dispatch({ type: CLOSE_DIALOGUE });
                            }}
                        >
                            <img src={Cancel} height={30} width={30} alt="Cancel" />
                        </div>
                    </div>
                    <div
                        className="dialogueMain"
                        style={{ overflow: "auto", maxHeight: "100vh" }}
                    >
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    label={`Level`}
                                    id={`level`}
                                    type={`text`}
                                    name={`level`}
                                    disabled
                                    value={level}
                                    onChange={(e) => {
                                        setLevel(e.target.value);
                                    }}
                                    errorMessage={error.level && error.level}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    label={`Level Name`}
                                    id={`levelName`}
                                    type={`text`}
                                    value={levelName}
                                    placeholder={`Enter Level Name`}
                                    onChange={(e) => {
                                        setLevelName(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                levelName: `Level Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                levelName: "",
                                            });
                                        }
                                    }}
                                    errorMessage={error.levelName && error.levelName}
                                />
                            </div>

                            <div className="col-6">
                                <Input
                                    label={`Reward`}
                                    id={`reward`}
                                    type={`text`}
                                    value={reward}
                                    placeholder={`Enter Reward`}
                                    onChange={(e) => {
                                        setReward(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                reward: `Reward Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                reward: "",
                                            });
                                        }
                                    }}
                                    errorMessage={error.reward && error.reward}
                                />
                            </div>


                            <div className="col-6">
                                <Input
                                    label={`Weekly Income`}
                                    id={`weekly income`}
                                    type={`number`}
                                    placeholder={`Enter Weekly Income`}
                                    value={weeklyIncome}
                                    errorMessage={error.weeklyIncome && error.weeklyIncome}
                                    onChange={(e) => {
                                        setWeeklyIncome(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                weeklyIncome: `Weekly Income Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                weeklyIncome: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    label={`Total WorkTime (min)`}
                                    id={`totalWorkTime`}
                                    type={`number`}
                                    placeholder={`Enter Total Work Time`}
                                    value={totalWorkTime}
                                    errorMessage={
                                        error.totalWorkTime && error.totalWorkTime
                                    }
                                    onChange={(e) => {
                                        setTotalWorkTime(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                totalWorkTime: `Total Work Time Is Required`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                totalWorkTime: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    label={`WorkTime For PeakHour (min)`}
                                    id={`workTimeForPeakHours`}
                                    type={`number`}
                                    placeholder={`Enter Peak Hour`}
                                    value={workTimeForPeakHours}
                                    errorMessage={error.workTimeForPeakHours && error.workTimeForPeakHours}
                                    onChange={(e) => {
                                        setWorkTimeForPeakHours(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                workTimeForPeakHours: `WorkTimeForPeack Hour is required!`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                workTimeForPeakHours: "",
                                            });
                                        }
                                    }}
                                />
                            </div>
                  
                            <div className="col-6">
                                <label className="prime-time">Start Time</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            value={startTime}
                                            onChange={handleStartTimeChange}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock,
                                            }}
                                            sx={{
                                                '.MuiOutlinedInput-root': {
                                                    '& input': {
                                                        overflow: 'auto', // Prevents scroll
                                                    },
                                                    '& fieldset': {
                                                        borderColor: '#cbd5e1', // Default border color
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#cbd5e1', // Border color on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#4caf50', // Green border color when focused
                                                    },
                                                    '&.Mui-error fieldset': {
                                                        borderColor: error.startTime ? '#cbd5e1' : '#f44336', // Remove red border if no error
                                                    },
                                                },
                                                '.MuiClock-root': {
                                                    position: 'relative', // Adjust position
                                                    top: '500px', // Move clock 50px down
                                                    left: '20px', // Move clock 20px to the right
                                                    transform: 'translate(-50%, -50%)', // Optional: For centering
                                                },
                                            }}
                                        />

                                    </DemoContainer>
                                </LocalizationProvider>
                                {error.startTime && <small style={{ color: "red" }}>{error.startTime}</small>}
                            </div>

                            <div className="col-6">
                                <label className="prime-time">End Time</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker                                      
                                            value={endTime}
                                            onChange={handleEndTimeChange}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock,
                                            }}
                                            sx={{
                                                '.MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#cbd5e1', // Default border color
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#cbd5e1', // Border color on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#cbd5e1', // Border color when focused
                                                    },
                                                },
                                                '.MuiClock-root': {
                                                    position: 'relative', // Adjust position
                                                    top: '500px', // Move clock 50px down
                                                    left: '20px', // Move clock 20px to the right
                                                    transform: 'translate(-50%, -50%)', // Optional: For centering,
                                                },
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {error.endTime && <small style={{ color: "red" }}>{error.endTime}</small>}
                            </div>
                            <div className="col-6 mt-2">
                                <Input
                                    label={`Time Zone`}
                                    id={`Time Zone`}
                                    type={`text`}
                                    value={timeZone}
                                    placeholder={`Enter TimeZone`}
                                    errorMessage={error.timeZone && error.timeZone}
                                    onChange={(e) => {
                                        setTimeZone(e.target.value);
                                        if (!e.target.value) {
                                            return setError({
                                                ...error,
                                                timeZone: `Time Zone is required!`,
                                            });
                                        } else {
                                            return setError({
                                                ...error,
                                                timeZone: "",
                                            });
                                        }
                                    }}
                                />
                        </div>

                     
                        </div>
                        <div className="dialogueFooter">
                            <div className="dialogueBtn">
                                <Button
                                    btnName={`Submit`}
                                    btnColor={`btnBlackPrime`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    newClass={`me-2`}
                                    onClick={handleSubmit}
                                />
                                <Button
                                    btnName={`Close`}
                                    btnColor={`bg-danger text-white`}
                                    style={{ borderRadius: "5px", width: "80px" }}
                                    onClick={() => {
                                        dispatch({ type: CLOSE_DIALOGUE });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { createIncome, updateIncomePlan })(
    RewardLevelDialogue
);
