import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getVIPPlan } from "../../store/vipPlan/action";

import { warning } from "../../util/Alert";
import FlashVipDialogue from "./FlashVipDialogue";
import { getFlasVip,deleteFlashVip,isActiveFlashVip } from "../../store/flashVip/FlashVip.action";

const FlashVip = (props) => {
  const { flashVip } = useSelector((state) => state.flashVip);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getFlasVip());
  }, []);

  useEffect(() => {
    setData(flashVip);
  }, [flashVip]);

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleClick = (userDetails) => {
    props.isActiveFlashVip(userDetails?._id);
  };
  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteFlashVip(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Validity",
      body: "validity",
      Cell: ({ row }) => (
        <span>
          <span className="text-danger">{row?.validity} </span>{" "}
          <span>{row?.validityType} </span>
        </span>
      ),
    },
    // {
    //   Header: "Validity Type",
    //   body: "validityType",
    // },
    {
      Header: "Dollar",
      body: "dollar",
      Cell: ({ row }) => (
        <span>
          <span>{row?.dollar} </span>
        </span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "Rupee",
      body: "rupee",
      Cell: ({ row }) => (
        <span>
          <span>{row?.rupee} </span>
        </span>
      ),
      sorting: { type: "client" },
    },
    {
      Header: "Tag",
      body: "tag",
      Cell: ({ row }) => (
        <span>
          <span>{row?.tag} </span>
        </span>
      ),
    },

    {
      Header: "isActive",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch value={row?.isActive} onClick={() => handleClick(row)} />
      ),
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-primary fs-5`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          // onClick={() => handleEdit(row)}

          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "VipPlanFlash" },
            });
          }}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            // backgroundColor: "#fff",
            // color: "#160d98",
          }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },

    // add more columns as needed
  ];
  return (
    <>
      <div className="mainUserTable">
        <div className="userTable">
          <div className="userHeader primeHeader"></div>
          <div className="primeHeader mt-4 row">
            <div className="row align-items-center">
              <div className="col-12">
                <Title name={`Flash Vip`} />
              </div>
              <div className="col-6"></div>
              <div className="col-6 ms-auto mt-3">
                <Button
                  newClass={`border text-white userBtn`}
                  btnColor={`bg-primary`}
                  btnName={`Add Flash Vip`}
                  btnIcon={`fas fa-plus fa-md`}
                  onClick={() => {
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: { type: "VipPlanFlash" },
                    });
                  }}
                  style={{
                    borderRadius: "5px",
                    marginLeft: "auto",
                    width: "165px",
                    float: "right",
                  }}
                />

                {dialogue && dialogueType === "VipPlanFlash" && (
                  <FlashVipDialogue />
                )}
              </div>
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={flashVip?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={flashVip?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { deleteFlashVip, isActiveFlashVip })(FlashVip);
