import axios from "axios";
import * as ActionType from "./coinPlan.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";

export const getPlan = (search) => (dispatch) => {
  apiInstanceFetch
    .get(`admin/coinPlan?search=${search}`)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: ActionType.GET_COIN_PLAN,
          payload: res.data,
        });
        // setToast("success", "Coin Plan Get Successfully!");
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const createNewCoinPlan = (data) => (dispatch) => {
  axios
    .post(`admin/coinPlan`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_COIN_PLAN,
          payload: res.data.coinplan,
        });
        setToast("success", "Coinplan Create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

// Update Coin PLan
export const updateCoinPlan = (data, id) => (dispatch) => {
  axios
    .patch(`admin/coinPlan/${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.EDIT_COIN_PLAN,
          payload: { data: res.data.coinplan, id },
        });
        setToast("success", "Coinplan update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const deleteCoinPlan = (id) => (dispatch) => {
  axios
    .delete(`admin/coinPlan/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_COIN_PLAN,
          payload: {data: res.data.data},
        });
        setToast("success", "Coinplan Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const isActiveCoinPlan = (id) => (dispatch) => {
  axios
    .put(`admin/coinPlan/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.ACTIVE_SWITCH,
          payload: { data: res.data.coinPlan, id },
        });

        setToast(
          "success",
          `${
            res.data.coinPlan.isActive === true
              ? "Coinplan  Active SuccessFully"
              : "Coinplan Disable SuccessFully"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};
export const isTopCoinPlan = (id) => (dispatch) => {
  axios
    .put(`admin/coinPlan/isTopToggle?planId=${id}`)
    .then((res) => {
      if (res.data.status) {
        
        dispatch({
          type: ActionType.IS_TOP,
          payload: { data: res.data.data, id },
        });

        setToast("success", "Coinplan  Update SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// Purchase History Get

export const getPurchaseHistory =
  (search, start, limit, sort, type) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/coinPlan/purchasedHistory?search=${search}&start=${start}&limit=${limit}&sort=${sort}&sortType=${type}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.PURCHASE_HISTORY,
          payload: {
            data: res.history,
            total: res.total,
            totalDollar: res.totalDollar,
          },
        });
      })
      .catch((error) => console.log("error", error));
  };
